<template>
  <div v-if="contents.status" class="template__lightbox">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Template card-->
      <div class="card">
        <div class="card-body">
          <!--begin::Body-->
          <div class="d-flex flex-column flex-xl-row col gap-5">
            <div class="d-flex flex-column flex-md-row">
              <ul
                class="nav nav-tabs nav-pills border-0 flex-row flex-md-column me-5 mb-3 mb-md-0 fs-6"
                role="tablist"
              >
                <li
                  v-for="(section, index) in tempSections"
                  :key="index"
                  class="nav-item w-md-200px mb-md-2 me-0"
                  role="presentation"
                >
                  <a
                    class="nav-link w-100 btn btn-flex btn-active-light-primary"
                    :class="
                      (index == 'Hero'
                        ? heroError.length > 0
                          ? 'active border border-danger'
                          : 'active'
                        : '') ||
                      ((index == 'Footer' && footerError.length > 0) ||
                      (index != 'Footer' && componentError.length > 0)
                        ? 'border border-danger'
                        : '')
                    "
                    data-bs-toggle="tab"
                    :href="`#kt_tab_pane_${index.replace(/ /g, '_')}`"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                    >{{ index }}</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  v-for="(section, index) in tempSections"
                  :key="index"
                  class="tab-pane fade"
                  :class="index == 'Hero' ? 'active show' : ''"
                  :id="`kt_tab_pane_${index.replace(/ /g, '_')}`"
                  role="tabpanel"
                >
                  {{ index }}
                  <div v-for="(component, i) in section" :key="i">
                    <div
                      v-if="component.sectionLimitted"
                      class="d-flex flex-column gap-5"
                      data-kt-buttons="true"
                    >
                      <div
                        class="d-flex flex-center border border-dashed rounded-3 border-secondary p-6"
                      >
                        <label class="active btn p-6"
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="form-check form-check-lg form-check-custom form-check-solid form-check-primary me-6"
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                :name="index"
                                :value="i"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio-->
                          </div>
                          <!--end::Description--></label
                        ><!--begin::Info--><a
                          class="d-block overlay cursor-pointer"
                          @click="
                            softBox(
                              `${backendHost}/images/themes/theme${$store.state.theme}/${component.sectionImage}`
                            )
                          "
                          ><div
                            class="overlay-wrapper bgi-no-repeat bgi-position-top bgi-size-cover card-rounded h-100px w-200px h-md-125px w-md-250px bg-secondary"
                            :style="`
                              background-image: url(${backendHost}/images/themes/theme${$store.state.theme}/${component.sectionImage});
                            `"
                          ></div>
                          <!--begin::Action-->
                          <div
                            class="overlay-layer card-rounded bg-dark bg-opacity-25"
                          >
                            <i class="bi bi-eye-fill fs-2x text-white"></i>
                          </div>
                          <!--end::Action--></a
                        ><!--end::Info-->
                      </div>
                    </div>
                    <div
                      v-else
                      class="d-flex flex-column gap-5"
                      data-kt-buttons="true"
                    >
                      <div
                        class="d-flex flex-center border border-dashed rounded-3 border-secondary p-6"
                        :class="componentError ? 'border-danger' : ''"
                      >
                        <div
                          class="d-flex flex-column align-items-center me-2 gap-3"
                        >
                          <a
                            @click="selectedComponent('add', component)"
                            class="col btn btn-sm btn-primary btn-shadow w-100"
                            >Add</a
                          ><a
                            @click="softBox(component['previewImage'])"
                            class="col btn btn-sm btn-light-primary btn-shadow w-100"
                            >Preview</a
                          >
                        </div>
                        <a
                          class="d-block overlay cursor-pointer"
                          @click="softBox(`${backendHost}/images/themes/theme${$store.state.theme}/${component.sectionImage}`)"
                          ><div
                            class="overlay-wrapper bgi-no-repeat bgi-position-top bgi-size-cover card-rounded h-100px w-200px h-md-125px w-md-250px bg-secondary"
                            :style="`
                            background-image: url(${backendHost}/images/themes/theme${$store.state.theme}/${component.sectionImage});
                          `"
                          ></div>
                          <!--begin::Action-->
                          <div
                            class="overlay-layer card-rounded bg-dark bg-opacity-25"
                          >
                            <i class="bi bi-eye-fill fs-2x text-white"></i>
                          </div>
                          <!--end::Action--></a
                        ><!--end::Info-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div
                class="h-40px bg-dark rounded-top d-flex align-items-center ps-2 gap-2"
              >
                <div class="w-10px h-10px bg-danger rounded-circle"></div>
                <div class="w-10px h-10px bg-warning rounded-circle"></div>
                <div class="w-10px h-10px bg-success rounded-circle"></div>
              </div>
              <div class="border border rounded-bottom border-top-0 p-2">
                <div
                  v-if="Object.keys(firstComponent).length > 0"
                  class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px w-100"
                  :style="`
                    background-image: url(${backendHost}/images/themes/theme${$store.state.theme}/${firstComponent.sectionImage});
                  `"
                ></div>
                <div
                  v-else
                  class="d-flex flex-center bg-light card-rounded h-150px w-100 mb-5"
                >
                  No Hero selected selected
                </div>
                <div v-if="middleComponents.length > 0">
                  <div
                    v-for="(component, index) in middleComponents"
                    :key="index"
                    class=""
                  >
                    <div class="card overlay overflow-hidden">
                      <div class="card-body p-0">
                        <div class="overlay-wrapper">
                          <div class="">
                            <!--begin::Image-->
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px w-100"
                              :style="`
                    background-image: url(${backendHost}/images/themes/theme${$store.state.theme}/${component.sectionImage});
                  `"
                            ></div>
                            <!--end::Image-->
                          </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-50 p-5">
                          <div class="d-flex flex-center">
                            <a
                              @click="
                                selectedComponent('remove', component, index)
                              "
                              class="col btn btn-sm btn-danger btn-shadow"
                              >Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex flex-center bg-light card-rounded h-150px w-100 mb-5"
                >
                  No Component selected
                </div>
                <div
                  v-if="Object.keys(this.lastComponent).length > 0"
                  class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-150px w-100"
                  :style="`
                    background-image: url(${backendHost}/images/themes/theme${$store.state.theme}/${lastComponent.sectionImage});
                  `"
                ></div>
                <div v-else class="d-flex flex-center bg-light h-150px w-100">
                  No Footer selected
                </div>
              </div>
            </div>
          </div>
          <!--end::Body-->
        </div>
        <div class="card-footer d-flex justify-content-center gap-5">
          <div>
            <a
              @click="selectedComponent('reset')"
              class="col btn btn-light-danger btn-shadow"
              >Reset</a
            >
          </div>
          <div>
            <a
              @click="selectedComponent('validate')"
              class="col btn btn-light-primary btn-shadow"
              >Submit</a
            >
          </div>
        </div>
      </div>
      <!--end::Template card-->
      <!--begin::Modals-->
      <!-- <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="faqInfo"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box> -->
      <!--end::Modals-->
    </div>
    <!--end::Container-->
    <!--begin::SoftBox-->
    <div>
      <FsLightbox :toggler="toggler" :sources="[`${softBoxSource}`]" />
    </div>
    <!--end::SoftBox-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";
import FsLightbox from "fslightbox-vue/v3";

// Modals
// import CreateUpdate from "../../modals/CreateUpdate.vue";
// import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    // CreateUpdate,
    // DialogBox,
    FsLightbox,
  },
  setup() {},
  data() {
    return {
      tempSections: {},
      sections: {
        hero: {
          name: "hero",
          title: "Hero",
          limit: true,
          components: [
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-hero.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-hero.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-hero.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-hero.png",
              templateJson: "",
            },
          ],
        },
        about: {
          name: "about",
          title: "About",
          limit: false,
          components: [
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-about.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-about.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-about.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-about.png",
              templateJson: "",
            },
          ],
        },
        faqs: {
          name: "faqs",
          title: "Faqs",
          limit: false,
          components: [
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-faqs.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-faqs.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-faqs.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-faqs.png",
              templateJson: "",
            },
          ],
        },
        footer: {
          name: "footer",
          title: "Footer",
          limit: true,
          components: [
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-footer.png",
              templateJson: "",
            },
            {
              id: "",
              previewImage:
                "http://localhost:3020/uploads/webplate.info/1616284800000_webplate-footer.png",
              templateJson: "",
            },
          ],
        },
      },
      selectedComponents: [],
      middleComponents: [],
      firstComponent: [],
      lastComponent: [],
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      firstErrorName: "",
      toggler: false,
      softBoxSource: "",
      heroError: "",
      footerError: "",
      componentError: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false, false],
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    loaderDone() {
      if (this.loaderStatus[0]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/templates`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readSections();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      // query: {
      //     category: "Blogs",
      //   },
      // };
      // data.query = JSON.stringify(data.query)
      //   .replace("{", "")
      //   .replace(",", "&")
      //   .replace("}", "")
      //   .replace(/":"/g, "=")
      //   .replace(/"/g, "");
      // dataService

      dataService
        .get(data)
        .then(() => {
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    readSections() {
      let data = {
        endPoint: `sections`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          
          for (let d in res.data.data) {
            if (res.data.data[d].sectionCategory == 6) {
              if (!this.tempSections["Hero"]) {
                this.tempSections["Hero"] = []  
              }
              this.tempSections["Hero"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 8) {
              if (!this.tempSections["About us"]) {
                this.tempSections["About us"] = []  
              }
              this.tempSections["About us"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 9) {
              if (!this.tempSections["Qualification"]) {
                this.tempSections["Qualification"] = []  
              }
              this.tempSections["Qualification"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 10) {
              if (!this.tempSections["Services"]) {
                this.tempSections["Services"] = []  
              }
              this.tempSections["Services"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 11) {
              if (!this.tempSections["Projects"]) {
                this.tempSections["Projects"] = []  
              }
              this.tempSections["Projects"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 12) {
              if (!this.tempSections["Subscribe"]) {
                this.tempSections["Subscribe"] = []  
              }
              this.tempSections["Subscribe"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 13) {
              if (!this.tempSections["Contact us"]) {
                this.tempSections["Contact us"] = []  
              }
              this.tempSections["Contact us"].push(res.data.data[d]);
            } else if (res.data.data[d].sectionCategory == 14) {
              if (!this.tempSections["Footer"]) {
                this.tempSections["Footer"] = []  
              }
              this.tempSections["Footer"].push(res.data.data[d]);
            }
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    selcetedItems(opt) {
      switch (opt.target.name) {
        case "Hero":
          this.firstComponent =
            this.tempSections[opt.target.name][opt.target.value];
          break;
        case "Footer":
          this.lastComponent =
            this.tempSections[opt.target.name][opt.target.value];
          break;
      }
    },
    softBox(src) {
      this.softBoxSource = src;
      this.toggler = !this.toggler;
    },
    selectedComponent(opt, obj, index) {
      switch (opt) {
        case "add":
          this.middleComponents.push(obj);
          break;
        case "remove":
          this.middleComponents.splice(index, 1);
          break;
        case "reset":
          var inputs = document.getElementsByTagName("input");
          for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].type.toLowerCase() == "radio") {
              inputs[i].checked = false;
            }
          }
          this.middleComponents = [];
          this.firstComponent = "";
          this.lastComponent = "";
          break;
        case "validate":
          if (Object.keys(this.firstComponent).length == 0) {
            this.heroError = "Please select Hero";
            this.$notify({
              type: "error",
              title: "error",
              text: this.heroError,
            });
          } else {
            this.heroError = "";
          }
          if (Object.keys(this.lastComponent).length == 0) {
            this.footerError = "Please select Footer";
            this.$notify({
              type: "error",
              title: "error",
              text: this.footerError,
            });
          } else {
            this.footerError = "";
          }
          if (this.middleComponents.length == 0) {
            this.componentError = "Please add at least 1 component";
            this.$notify({
              type: "error",
              title: "error",
              text: this.componentError,
            });
          } else {
            this.componentError = "";
          }
          if (
            Object.keys(this.firstComponent).length > 0 &&
            Object.keys(this.lastComponent).length > 0 &&
            this.middleComponents.length > 0
          ) {
            this.$notify({
              type: "success",
              title: "success",
              text: "Template created successfuly",
            });
            this.submitTemplate();
          }
          break;
      }
    },
    submitTemplate() {
      this.selectedComponents = [];
      this.selectedComponents.push(this.firstComponent);
      for (let i in this.middleComponents) {
        this.selectedComponents.push(this.middleComponents[i]);
      }
      this.selectedComponents.push(this.lastComponent);
      
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
  },
  updated() {
    this.loaderDone();
  },
};
</script>
