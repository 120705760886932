<template>
  <div
    v-if="contents.status"
    class="d-flex justify-content-center align-items-center new__password__content"
  >
    <div
      class="card shadow col-11 col-md-10 col-lg-6 d-flex justify-content-center align-items-center my-4 my-lg-0"
    >
      <h3 class="fw-bold mt-5">{{ contents.data.title }}</h3>
      <p class="text-muted mb-4">{{ contents.data.subTitle }}</p>

      <div
        @keydown.enter="updatePassword"
        class="col-10 col-md-9 col-lg-7 new__password__form mt-4"
      >
        <div class="mb-3">
          <label class="form-label">
            {{ contents.data.fields[0].title
            }}<span class="text-danger">*</span>
          </label>
          <div class="input-group">
            <input
              @keyup="passwordStrenth"
              v-model="password"
              dir="ltr"
              class="form-control form-control-lg border-0 __bg__light"
              aria-describedby="validationErrorFeedback"
              :placeholder="contents.data.fields[0].placeholder"
              :class="passwordErrorClass"
              :type="passwordVisibility ? 'text' : 'password'"
            />
            <span
              class="input-group-text border-0 __bg__light eye__toggle"
              @click="passwordVisibilitySwitch"
            >
              <i
                class="fs-4"
                :class="passwordVisibility ? 'bi bi-eye-slash' : 'bi bi-eye'"
                style="cursor: pointer"
              ></i>
            </span>
            <div id="validationErrorFeedback" class="invalid-feedback">
              {{ contents.data.fields[0].error }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mb-4">
          <div
            class="flex-grow-1 rounded me-2"
            :class="passwordScore >= 25 ? passwordColor : '__bg__light'"
            style="height: 5px"
          ></div>
          <div
            class="flex-grow-1 rounded me-2"
            :class="passwordScore >= 50 ? passwordColor : '__bg__light'"
            style="height: 5px"
          ></div>
          <div
            class="flex-grow-1 rounded me-2"
            :class="passwordScore >= 75 ?  passwordColor : '__bg__light'"
            style="height: 5px"
          ></div>
          <div
            class="flex-grow-1 rounded"
            :class="passwordScore >= 100 ?  passwordColor : '__bg__light'"
            style="height: 5px"
          ></div>
        </div>
        <div class="mb-4">
          <label class="form-label"
            >{{ contents.data.fields[1].title
            }}<span class="text-danger">*</span></label
          >
          <div class="input-group">
            <input
              v-model="confirmPassword"
              dir="ltr"
              class="form-control form-control-lg border-0 __bg__light"
              aria-describedby="validationErrorFeedback"
              :placeholder="contents.data.fields[1].placeholder"
              :class="confirmPasswordErrorClass"
              :type="confirmPasswordVisibility ? 'text' : 'password'"
            />
            <span
              class="input-group-text border-0 __bg__light eye__toggle"
              @click="ConfirmPasswordVisibilitySwitch"
            >
              <i
                class="fs-4"
                :class="
                  confirmPasswordVisibility ? 'bi bi-eye-slash' : 'bi bi-eye'
                "
                style="cursor: pointer"
              ></i>
            </span>
            <div id="validationErrorFeedback" class="invalid-feedback">
              {{ contents.data.fields[1].error }}
            </div>
          </div>
        </div>
        
        <div
          class="pt-1 mb-5 d-flex justify-content-center flex-column flex-lg-row"
        >
          <a
            v-for="button in contents.data.buttons"
            :key="button"
            @click="handleButtonAction(button.function)"
            class="btn btn-lg px-5 m-1"
            :class="button.class"
          >
            {{ button.title }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import isStrongPassword from "validator/lib/isStrongPassword";
import dataService from "../../../services/data";

export default {
  setup() {},
  data() {
    return {
      password: "",
      confirmPassword: "",

      passwordErrorClass: "",
      confirmPasswordErrorClass: "",

      passwordVisibility: false,
      confirmPasswordVisibility: false,

      passwordScore: 0,

      contents: {
        data: {},
        status: false,
      },
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    userName() {
      return this.$route.query.id;
    },
    verificationCode() {
      return this.$route.query.code;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    handleButtonAction(func) {
      switch (func.name) {
        case "redirectToPage":
          window.location = `/${this.routeLocale}/${func.args}`;
          break;
        default:
          
          break;
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    updatePassword() {
      if (this.inputValidation()) {
        let data = {
          endPoint: "users/np",
          locale: `${this.routeLocale}`,
          data: {
            userName: this.userName,
            verificationCode: this.verificationCode,
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        };
        dataService
          .post(data)
          .then((res) => {
            if (res.data.success) {
              window.location = `/${this.routeLocale}/login`;
            }
            this.$notify({
              type: res.data.type,
              title: res.data.type,
              text: res.data.message,
            });
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
    },
    passwordStrenth() {
      this.passwordScore = isStrongPassword(this.password, {
          returnScore: true,
          pointsPerRepeat: 0,
          pointsPerUnique: 0,
          pointsForContainingLower: 25,
          pointsForContainingUpper: 25,
          pointsForContainingNumber: 25,
          pointsForContainingSymbol: 25,
        })
      switch (this.passwordScore) {
        case 25:
          this.passwordColor = "bg-danger"
          break;
        case 50:
          
          this.passwordColor = "bg__orange"
          break;
          case 75:
            this.passwordColor = "bg-warning"
            
          break;
          case 100:
          this.passwordColor = "bg-success"
          break;
      
        default:
          this.passwordColor = "__bg__light"
          break;
      }
    },
    inputValidation() {
      if (this.password.length == 0 && this.confirmPassword.length == 0) {
        this.passwordErrorClass = "wrong-inputs is-invalid";
        this.confirmPasswordErrorClass = "wrong-inputs is-invalid";
        return false;
      } else if (!isStrongPassword(this.password)) {
        this.passwordErrorClass = "wrong-inputs is-invalid";
        this.confirmPasswordErrorClass = "";
        this.contents.data.fields[0].error =
          this.routeLocale == "en"
            ? "Password must be at least 8 characters and a combination of numbers, symbols, upper and lower case letters."
            : "رمز عبور باید حداقل 8 کاراکتر و ترکیبی از اعداد، نماد، حروف بزرگ و کوچک باشد. ";
        return false;
      } else if (this.password !== this.confirmPassword) {
        this.passwordErrorClass = "wrong-inputs is-invalid";
        this.confirmPasswordErrorClass = "wrong-inputs is-invalid";
        return false;
      }
      return true;
    },
    passwordVisibilitySwitch() {
      this.passwordVisibility = !this.passwordVisibility;
    },
    ConfirmPasswordVisibilitySwitch() {
      this.confirmPasswordVisibility = !this.confirmPasswordVisibility;
    },
  },
  mounted() {
    this.getContents();
  },
};
</script>

<style scoped>
.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  background-color: #fff;
}

.bg__orange{
  background-color: #ff8800
}
</style>
