<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Layout-->
      <div class="d-flex flex-column flex-xl-row">
        <!--begin::Sidebar-->
        <div class="flex-column flex-lg-row-auto w-100 w-lg-300px mb-10">
          <!--begin::Card-->
          <div class="card card-flush">
            <!--begin::Card header-->
            <div class="card-header">
              <!--begin::Card title-->
              <div class="card-title">
                <h2 class="mb-0">{{ role[0].roleName }}</h2>
              </div>
              <!--end::Card title-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-1">
              <div class="fw-bolder text-gray-600 mb-5">
                {{ contents.data.page.card.subTitle }} 5 نفر
              </div>
              <!--begin::Permissions-->
              <div class="d-flex flex-column text-gray-600">
                <div class="d-flex align-items-center py-2">
                  <span class="bullet bg-primary me-3"></span>
                  {{ role[0].roleDescription }}
                </div>
                <div
                  v-for="(permission, route) in JSON.parse(
                    role[0].rolePermissions
                  )"
                  :key="permission"
                  class="d-flex align-items-center py-2"
                >
                  <span class="bullet bg-primary me-3"></span>{{ permission }}
                  {{ route }}
                </div>
              </div>
              <!--end::Permissions-->
            </div>
            <!--end::Card body-->
            <!--begin::Card footer-->
            <div class="card-footer flex-wrap pt-0">
              <button
                type="button"
                class="btn btn-light btn-active-primary my-1 me-2"
                @click="setModalConfigs('update', 0)"
              >
                {{ contents.data.page.card.buttons[1].label }}
              </button>
              <button
                type="button"
                class="btn btn-light btn-active-danger my-1"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_update_role"
                @click="deleteRole()"
              >
                {{ contents.data.page.card.buttons[2].label }}
              </button>
            </div>
            <!--end::Card footer-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Sidebar-->
        <!--begin::Content-->
        <div class="flex-lg-row-fluid ms-lg-10">
          <!--begin::Card-->
          <div class="card card-flush mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="card-header pt-5">
              <!--begin::Card title-->
              <div class="card-title">
                <h2 class="d-flex align-items-center">
                  کاربران مربوط
                  <span class="text-gray-600 fs-6 ms-1">{{
                    users.length
                  }}</span>
                </h2>
              </div>
              <!--end::Card title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <!--begin::Search-->
                <div
                  class="d-flex align-items-center position-relative my-1"
                  data-kt-view-roles-table-toolbar="base"
                >
                  <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                  <span
                    class="svg-icon svg-icon-primary svg-icon-1 position-absolute ms-6"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="17.0365"
                        y="15.1223"
                        width="8.15546"
                        height="2"
                        rx="1"
                        transform="rotate(45 17.0365 15.1223)"
                        fill="black"
                      />
                      <path
                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <input
                    type="text"
                    data-kt-roles-table-filter="search"
                    class="form-control form-control-solid w-250px ps-15"
                    placeholder="جستجو کاربران"
                  />
                </div>
                <!--end::Search-->
                <!--begin::Group actions-->
                <div
                  class="d-flex justify-content-end align-items-center d-none"
                  data-kt-view-roles-table-toolbar="selected"
                >
                  <div class="fw-bolder me-5">
                    <span
                      class="me-2"
                      data-kt-view-roles-table-select="selected_count"
                    ></span
                    >مورد انتخاب شده
                  </div>
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-kt-view-roles-table-select="delete_selected"
                  >
                    حذف موارد
                  </button>
                </div>
                <!--end::Group actions-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
              <!--begin::Table-->
              <table
                class="table align-middle table-row-dashed fs-6 gy-5 mb-0"
                id="kt_roles_view_table"
              >
                <!--begin::Table head-->
                <thead>
                  <!--begin::Table row-->
                  <tr
                    class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                  >
                    <th class="w-10px pe-2">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid me-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          data-kt-check="true"
                          data-kt-check-target="#kt_roles_view_table .form-check-input"
                          value="1"
                        />
                      </div>
                    </th>
                    <th class="min-w-50px">#</th>
                    <th class="min-w-150px">کاربر</th>
                    <th class="min-w-125px">تاریخ انتصاب</th>
                    <th class="text-end min-w-100px">عملیات</th>
                  </tr>
                  <!--end::Table row-->
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody class="fw-bold text-gray-600">
                  <tr v-for="(user, index) in users" :key="index">
                    <!--begin::Checkbox-->
                    <td>
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <!--end::Checkbox-->
                    <!--begin::ID-->
                    <td>{{ index }}</td>
                    <!--begin::ID-->
                    <!--begin::User=-->
                    <td class="d-flex align-items-center">
                      <!--begin:: Avatar -->
                      <div
                        class="symbol symbol-circle symbol-50px overflow-hidden me-3"
                      >
                        <a href="/user-profile">
                          <div class="symbol-label">
                            <img
                              src="/assets/images/avatars/150-18.jpg"
                              alt="Hamid Ghafoorian"
                              class="w-100"
                            />
                          </div>
                        </a>
                      </div>
                      <!--end::Avatar-->
                      <!--begin::User details-->
                      <div class="d-flex flex-column">
                        <a
                          href="/user-profile"
                          class="text-gray-800 text-hover-primary mb-1"
                          >{{ user.userName }}</a
                        >
                        <span>{{ user.userEmail }}</span>
                      </div>
                      <!--begin::User details-->
                    </td>
                    <!--end::user=-->
                    <!--begin::Joined date=-->
                    <td>10 خرداد 1400، 14:23</td>
                    <!--end::Joined date=-->
                    <!--begin::Action=-->
                    <td class="text-end">
                      <a
                        href="#"
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                              fill="black"
                            />
                            <path
                              opacity="0.5"
                              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                              fill="black"
                            />
                            <path
                              opacity="0.5"
                              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </a>
                      <a
                        href="#"
                        class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                        <span class="svg-icon svg-icon-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            transform="rotate(180)"
                          >
                            <rect
                              opacity="0.5"
                              x="18"
                              y="13"
                              width="13"
                              height="2"
                              rx="1"
                              transform="rotate(-180 18 13)"
                              fill="black"
                            />
                            <path
                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </a>
                    </td>
                    <!--end::Action=-->
                  </tr>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Layout-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="roleInfo"
        @close-modal="setModalConfigs"
      ></create-update>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  components: {
    CreateUpdate,
  },
  setup() {},
  data() {
    return {
      roleInfo: Object,
      modalDisplay: false,
      role: [],
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false, false],
    };
  },
  computed: {
    roleId() {
      return this.$route.query.id;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    getContents() {
      let data = {
        endPoint: `contents/r/roles`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          this.loaderStatus[0] = true;
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    readRoles() {
      let data = {
        endPoint: `roles/r/${this.roleId}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.loaderStatus[1] = true;
          
          this.role = res.data.data;
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    setModalConfigs(opt, index) {
      switch (opt) {
        case "update":
          this.roleInfo = { ...this.role[index], status: true };
          
          this.roleInfo.rolePermissions = JSON.parse(
            this.roleInfo.rolePermissions
          );
          this.modalDisplay = !this.modalDisplay;
          break;
        case "create":
          this.setupRoleInfo();
          this.modalDisplay = !this.modalDisplay;
          break;
        case "validate":
          if (this.inputValidation(index)) {
            index.id ? this.updateRole(index) : this.createNewRole(index);
          }
          break;
        default:
          this.resetRoleInfo();
          break;
      }
    },
    resetRoleInfo() {
      this.roleInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    updateRole(obj) {
      
      let tempPermissions = {};
      for (let p in obj.rolePermissions) {
        tempPermissions[`${p}`] = obj.rolePermissions[p];
      }
      
      let data = {
        endPoint: `roles/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          name: obj["roleName"],
          description: obj["roleDescription"],
          permissions: tempPermissions,
        },
      };
      
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetRoleInfo();
          this.readRole();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    deleteRole() {
      
      
      let data = {
        endPoint: `roles/d/${this.role[0].id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.$router.push("/roles");
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    readUsers() {
      let data = {
        endPoint: `users`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
        query: {
          role: this.roleId,
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          this.loaderStatus[2] = true;
          this.users = res.data.data;
          
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.readUsers();
    this.readRoles();
    this.getContents();
  },
  updated() {
    this.loaderDone();
  },
};
</script>
