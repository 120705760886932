<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">
                  {{ contents.data.page.locale.subTitle }}
                </span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="bi bi-plus-circle me-2 fs-4"
                  :class="contents.data.page.setting.buttonIcon"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card header-->
        <!--end::Card header-->
        <!--begin::Card body-->
        <div v-if="dataIsEmpty" class="card-body p-20">
          <div class="d-flex flex-center">
            <img
              class="col-md-3"
              :src="`/assets/images/svg/no_data.svg`"
              alt=""
            />
          </div>
        </div>
        <div v-else class="card-body pt-0">
          <!--begin::Table-->
          <div class="d-flex flex-column scroll-y me-n7 pe-7">
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_table_user"
            >
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr
                  class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                >
                  <!-- <th class="w-10px pe-2">
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_table_users .form-check-input"
                      value="1"
                    />
                  </div>
                </th> -->
                  <th
                    v-for="(header, index) in contents.data.page.table.header"
                    :key="index"
                    :class="header.class"
                  >
                    {{ header.title }}
                  </th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="text-gray-600 fw-bold">
                <!--begin::Table row-->
                <tr v-for="(newsletter, index) in newsletters" :key="index">
                  <!--begin::Checkbox-->
                  <!-- <td>
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </td> -->
                  <!--end::Checkbox-->
                  <!--begin::Role=-->
                  <td class="mw-100px">
                    <div class="col-10 fw-bold fs-3 mb-5 turncating">
                      {{ newsletter.newsletterSubject }}
                    </div>
                  </td>
                  <!--end::Role=-->
                  <!--begin::Last login=-->
                  <td>{{ newsletter.newsletterType }}</td>
                  <!--end::Last login=-->
                  <!--begin::Joined-->
                  <td>
                    {{ newsletter.newsletterDate }}
                  </td>
                  <!--begin::Joined-->
                  <!--begin::Action=-->
                  <!-- <td>
                  <a
                    href="#"
                    class="
                      btn btn-light btn-active-light-primary btn-sm
                      dropdown
                    "
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    >{{ contents.data.page.table.activity.title }} -->
                  <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                  <!-- <span class="svg-icon svg-icon-5 m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="black"
                        />
                      </svg>
                    </span> -->
                  <!--end::Svg Icon-->
                  <!-- </a> -->
                  <!--begin::Menu-->
                  <!-- <div
                    class="
                      dropdown-menu
                      menu
                      menu-sub
                      menu-sub-dropdown
                      menu-column
                      menu-rounded
                      menu-gray-600
                      menu-state-bg-light-primary
                      fw-bold
                      fs-7
                      w-125px
                      py-4
                    "
                    aria-labelledby="dropdownMenuButton"
                  > -->
                  <!--begin::Menu item-->
                  <!-- <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        @click="setModalConfigs('update', index)"
                        >{{
                          contents.data.page.table.activity.options[0].title
                        }}</a
                      >
                    </div> -->
                  <!--end::Menu item-->
                  <!--begin::Menu item-->
                  <!-- <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-kt-users-table-filter="delete_row"
                        @click="deleteNewsletters(index)"
                        >{{
                          contents.data.page.table.activity.options[1].title
                        }}</a
                      >
                    </div> -->
                  <!--end::Menu item-->
                  <!-- </div> -->
                  <!--end::Menu-->
                  <!-- </td> -->
                  <!--end::Action=-->
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="newsletterInfo"
      :firstErrorName="firstErrorName"
      @close-modal="setModalConfigs"
    ></create-update>
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      modalDisplay: false,
      dataIsEmpty: true,
      newsletterInfo: {},
      newsletters: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.newsletterInfo = { ...this.newsletters[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupNewsletterInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (this.inputValidation(index)) {
            index.id
              ? this.updateNewsletters(index)
              : this.createNewsletters(index);
          } else {
            this.$store.commit("setLoader", false);
            
          }
          break;
        default:
          this.resetNewsletterInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readNewsletters(page)
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/newsletters`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readSubcribers();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupNewsletterInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.newsletterInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "combobox":
            this.newsletterInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "file":
            this.newsletterInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "imageInput":
            this.newsletterInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          default:
            break;
        }
      }
      this.selectAllSubscribers();
      this.newsletterInfo["status"] = true;
    },
    selectAllSubscribers() {
      let temp = [];
      for (let option in this.contents.data.modal.fields[0].options) {
        
        temp.push(this.contents.data.modal.fields[0].options[option].value);
      }
      this.newsletterInfo[this.contents.data.modal.fields[0].name] = temp;
      
    },
    resetNewsletterInfo() {
      this.newsletterInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName = tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    createNewsletters(obj) {
      let data = {
        endPoint: `newsletters`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          subscribers: obj["newsletterSubscribers"],
          subject: obj["newsletterSubject"],
          description: obj["newsletterDescription"],
          type: obj["newsletterType"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetNewsletterInfo();
          this.readNewsletters();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readNewsletters(page) {
      let data = {
        endPoint: `newsletters`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          for (let d in res.data.data) {
            res.data.data[d].newsletterDate = new Date(
              res.data.data[d].newsletterDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          this.newsletters = res.data.data;
          this.newsletters = this.newsletters.reverse();
          if (page == 1) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    readSubcribers() {
      let data = {
        endPoint: `subscribers`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let subscriberTags = this.contents.data.modal.fields[0];
          for (let subscriber in res.data.data) {
            subscriberTags.options.push({
              title: res.data.data[subscriber].subscriberEmail,
              value: res.data.data[subscriber].subscriberEmail,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readNewsletters(1);
  },
};
</script>
