<template>
  <!--begin::Container-->
  <div id="kt_content_container" class="container-xxl my-5">
    <!--begin::Hero card-->
    <div class="card mb-15 shadow-sm">
      <!--begin::Hero body-->
      <div class="card-body flex-column p-5 mb-5">
        <!--begin::Hero content-->
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
        >
          <!--begin::Wrapper-->
          <div
            class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
          >
            <!--begin::Title-->
            <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
              بانک API گرین‌وب
            </h1>
            <!--end::Title-->
            <!--begin::Input group-->
            <div class="position-relative w-100 mb-10">
              <span class="text-gray-600 fs-6"
                >سادگی، سرعت و اطمینان در کسب و کار خود را با گرین‌وب تجربه
                کنید.</span
              >
            </div>
            <!--end::Input group-->
            <!--begin::Action-->
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_new_ticket"
              class="btn btn-primary fw-bolder fs-5"
              ><i class="bi bi-plus-circle me-2 fs-4"></i>اطلاعات بیشتر</a
            >
            <!--end::Action-->
          </div>
          <!--end::Wrapper-->
          <!--begin::Wrapper-->
          <div
            class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
          >
            <!--begin::Illustration-->
            <img
              src="/assets/images/svg/Store.svg"
              alt=""
              class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
            />
            <!--end::Illustration-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Hero content-->
      </div>
      <!--end::Hero body-->
    </div>
    <!--end::Hero card-->
    <!--begin::Layout-->
    <div class="d-flex flex-column flex-lg-row">
      <!--begin::Filter Sidebar-->
      <div
        class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-275px mb-8 mb-lg-0 me-lg-9 me-50"
      >
        <form action="#">
          <!--begin::Card-->
          <div class="card mb-5 mb-xl-8 shadow-sm rounded-3">
            <!--begin::Card header-->
            <div
              class="card-header collapsible d-flex align-items-center cursor-pointer"
              data-bs-toggle="collapse"
              data-bs-target="#kt_docs_card_collapsible"
            >
              <h3 href="#" class="card-title text-gray-800 fw-bolder">فیلتر</h3>
              <!--end::Name-->
              <!--begin::Position-->
              <div class="card-toolbar rotate-180">
                <span class="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11"
                      y="18"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 18)"
                      fill="black"
                    ></rect>
                    <path
                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <!--end::Card header-->
            <div id="kt_docs_card_collapsible" class="collapse show">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Badge-->
                <div class="mb-5 justify-content-end">
                  <a href="#">
                    <div class="badge badge-sm badge-light-danger">
                      حذف فیلترها
                    </div>
                  </a>
                </div>
                <!--begin::Badge-->
                <div class="mb-2">
                  <input
                    class="form-control form-control-lg border-0 p-0"
                    value="تخفیف دار, یادگیری ماشین"
                    id="kt_tagify_1"
                  />
                </div>

                <div class="separator mb-5"></div>

                <!--begin::Info-->
                <!--begin::Info heading-->
                <div class="fw-bolder mb-3">
                  برچسب‌ها
                  <i
                    class="bi bi-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-html="true"
                    data-bs-content="با کمک برچسب‌ها راحت تر جستجو کنید"
                  ></i>
                </div>
                <!-- begin::labels -->
                <div class="mb-5">
                  <!--begin::Search form-->
                  <div class="mb-5">
                    <form method="post" action="#" class="form">
                      <!--begin::Input wrapper-->
                      <div class="position-relative">
                        <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
                        <span
                          class="svg-icon svg-icon-1 svg-icon-primary position-absolute top-50 translate-middle ms-9"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            />
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                        <input
                          type="text"
                          class="form-control form-control-lg form-control-solid ps-14"
                          name="search"
                          value=""
                          placeholder="جستجو"
                        />
                      </div>
                      <!--end::Input wrapper-->
                    </form>
                  </div>
                  <!--end::Search form-->
                  <div
                    class="d-flex flex-wrap gap-5 justify-content-lg-between"
                  >
                    <div class="d-flex flex-lg-column gap-5 mb-3">
                      <div>
                        <label
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            checked
                          />
                          <span class="form-check-label"> تخفیف‌دار </span>
                        </label>
                      </div>
                      <div>
                        <label
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                          />
                          <span class="form-check-label"> رایگان </span>
                        </label>
                      </div>
                    </div>
                    <div class="d-flex flex-lg-column gap-5 mb-3">
                      <div>
                        <label
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            checked
                          />
                          <span class="form-check-label"> هوش مصنوعی </span>
                        </label>
                      </div>
                      <div>
                        <label
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                          />
                          <span class="form-check-label"> بینایی ماشین </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end::labels -->

                <div class="separator mb-5"></div>
                <!-- begin::category -->
                <div class="mb-5">
                  <div class="fw-bolder mb-5">‌دسته‌بندی‌ها</div>
                  <div
                    class="accordion accordion-icon-toggle"
                    id="kt_accordion_1"
                  >
                    <div class="mb-5">
                      <div class="d-flex align-items-center">
                        <div class="mb-2">
                          <label
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              checked
                            />
                            <span class="form-check-label">
                              یادگیری ماشین
                            </span>
                          </label>
                        </div>
                        <div
                          class="accordion-header d-flex py-3 mb-2"
                          data-bs-toggle="collapse"
                          data-bs-target="#kt_accordion_2_item_1"
                        >
                          <span class="accordion-icon ms-1">
                            <!--begin::Svg Icon | path: icons/duotune/arrows/arr064.svg-->
                            <span class="svg-icon svg-icon-3 me-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                transform="rotate(-180)"
                              >
                                <rect
                                  opacity="0.5"
                                  x="18"
                                  y="13"
                                  width="13"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-180 18 13)"
                                  fill="black"
                                ></rect>
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            <!--end::Svg Icon-->
                          </span>
                        </div>
                      </div>
                      <div
                        id="kt_accordion_2_item_1"
                        class="fs-6 collapse show ps-15"
                        data-bs-parent="#kt_accordion_2"
                      >
                        <div class="mb-3">
                          <label
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-label"> تشخیص چهره </span>
                          </label>
                        </div>
                        <div class="mb-3">
                          <label
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-label"> شمارش جمعیت </span>
                          </label>
                        </div>
                        <div class="mb-3">
                          <label
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-label"> رنگی سازی </span>
                          </label>
                        </div>
                        <div class="mb-3">
                          <label
                            class="form-check form-check-custom form-check-solid"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                            />
                            <span class="form-check-label"> تشخیص اشیا </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-2">
                    <label
                      class="form-check form-check-custom form-check-solid"
                    >
                      <input
                        disabled
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <span class="form-check-label"> اینترنت اشیا </span>
                    </label>
                  </div>
                  <div class="mb-2">
                    <label
                      class="form-check form-check-custom form-check-solid"
                    >
                      <input
                        disabled
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <span class="form-check-label"> بلاک چین </span>
                    </label>
                  </div>
                  <div class="mb-2">
                    <label
                      class="form-check form-check-custom form-check-solid"
                    >
                      <input
                        disabled
                        class="form-check-input"
                        type="checkbox"
                        value=""
                      />
                      <span class="form-check-label"> بکند </span>
                    </label>
                  </div>
                </div>
                <!-- end::category -->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer d-flex justify-content-center">
                <a href="#" class="btn btn-primary fw-bolder fs-5">
                  <i class="bi bi-funnel me-2 fs-4"></i>اعمال فیلترها</a
                >
              </div>
              <!--end::Card footer-->
            </div>
          </div>
          <!--end::Card-->
        </form>
      </div>
      <!--end::Filter Sidebar-->
      <!--begin::Cards-->
      <div class="flex-lg-row-fluid">
        <!--begin::Search Toolbar-->
        <div class="mb-5">
          <form
            data-kt-search-element="form"
            class="w-100 position-relative mb-5"
            autocomplete="off"
          >
            <!--begin::Hidden input(Added to disable form autocomplete)-->
            <input type="hidden" />
            <!--end::Hidden input-->
            <!--begin::Icon-->
            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
            <span
              class="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-primary position-absolute top-50 ms-5 translate-middle-y"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height="2"
                  rx="1"
                  transform="rotate(45 17.0365 15.1223)"
                  fill="black"
                ></rect>
                <path
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"
                ></path>
              </svg>
            </span>
            <!--end::Svg Icon-->
            <!--end::Icon-->
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-lg form-control-solid bg-transparent border-primary border-2 px-15"
              name="search"
              value=""
              placeholder="جستجو ..."
              data-kt-search-element="input"
            />
            <!--end::Input-->
            <!--begin::Spinner-->
            <span
              class="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
              data-kt-search-element="spinner"
            >
              <span
                class="spinner-border h-15px w-15px align-middle text-gray-400"
              ></span>
            </span>
            <!--end::Spinner-->
            <!--begin::Reset-->
            <span
              class="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
              data-kt-search-element="clear"
            >
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="black"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="black"
                  ></rect>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </span>
            <!--end::Reset-->
          </form>
        </div>
        <!--end::Search Toolbar-->
        <!--begin::Toolbar-->
        <div
          class="d-flex flex-stack flex-wrap align-items-center py-3 mb-5 bg-white rounded-3 shadow-xs"
        >
          <!--begin::Title-->
          <div class="d-flex flex-wrap align-items-center my-1">
            <h5 class="fw-bolder mx-5 my-1">مرتب سازی بر اساس:</h5>
            <span
              ><a
                class="text-decoration-none text-dark page-link text-active-primary active fw-bold"
                href="#"
                >جدیدترین</a
              ></span
            >
            <span
              ><a class="text-decoration-none text-dark page-link" href="#"
                >پرمخاطب‌ترین</a
              ></span
            >
            <span
              ><a class="text-decoration-none text-dark page-link" href="#"
                >محبوب‌ترین</a
              ></span
            >
            <span
              ><a class="text-decoration-none text-dark page-link" href="#"
                >پرفروش‌ترین</a
              ></span
            >
          </div>
          <!--end::Title-->
        </div>
        <!--end::Toolbar-->
        <!--begin::Tab Content-->
        <div class="">
          <!--begin::Tab pane-->
          <div class="">
            <!--begin::Row-->
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-6 g-xl-9">
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
              <!--begin::Col-->
              <div class="">
                <!--begin::Card-->
                <div class="card">
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-column p-8">
                    <div class="mb-6">
                      <a
                        href="#"
                        class="text-inverse-light bg-light text-hover-inverse-secondary bg-hover-secondary rounded fw-bold p-2 fs-8"
                        >یادگیری ماشین</a
                      >
                    </div>
                    <div class="d-flex flex-column align-items-center mb-3">
                      <!--begin::Avatar-->
                      <div class="col-4">
                        <img
                          class="mb-3"
                          src="/assets/images/svg/face-detection.svg"
                          alt="image"
                        />
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Title-->
                      <a
                        href="/productdetail"
                        class="fs-4 text-gray-800 text-hover-primary fw-bolder mb-1"
                        >سرویس تشخیص چهره</a
                      >
                      <div class="fw-bold text-gray-600">Face Detection</div>
                      <!--end::Title-->
                    </div>
                    <p class="text-gray-600 mb-8">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون
                    </p>
                    <div class="d-flex flex-lg-column gap-6 gap-lg-3">
                      <div class="col">
                        <a href="#" class="btn btn-sm btn-primary w-100">
                          <i class="bi bi-wallet me-2"></i>خرید
                        </a>
                      </div>
                      <div class="col">
                        <a
                          href="/productdetail"
                          class="btn btn-sm btn-light w-100"
                          ><i class="bi bi-arrow-left me-2"></i>جزئیات
                        </a>
                      </div>
                    </div>
                    <!--end::Position-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
            <!--begin::Pagination-->
            <div class="d-flex flex-center mt-10">
              <!--begin::Pages-->
              <ul class="pagination">
                <li class="page-item previous disabled">
                  <a href="#" class="page-link">
                    <i class="previous"></i>
                  </a>
                </li>
                <li class="page-item active">
                  <a href="#" class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">2</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">3</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">4</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">5</a>
                </li>
                <li class="page-item">
                  <a href="#" class="page-link">6</a>
                </li>
                <li class="page-item next">
                  <a href="#" class="page-link">
                    <i class="next"></i>
                  </a>
                </li>
              </ul>
              <!--end::Pages-->
            </div>
            <!--end::Pagination-->
          </div>
          <!--end::Tab pane-->
        </div>
        <!--end::Tab Content-->
      </div>
      <!--end::Cards-->
    </div>
    <!--end::Layout-->
  </div>
  <!--end::Container-->
</template>
