<template>
  <div
    v-if="contents.status"
    class="d-flex justify-content-center align-items-center h-100 my-5 my-lg-0"
  >
    <form
      @keydown.enter="userLogin"
      class="login__form col-11 col-md-8 col-lg-7"
    >
      <h3 class="fw-boldest fs-2x mb-3 pb-4">{{ contents.data.title }}</h3>

      <div class="form__input mb-4">
        <label class="mb-1" for="">{{ contents.data.fields[0].title }}</label>
        <div
          class="input__container d-flex justify-content-between"
          :class="userNameErrorClass"
        >
          <i class="bi px-5" :class="contents.data.fields[0].icon"></i>
          <input
            v-model="userName"
            dir="ltr"
            class="flex-grow-1 fs-5 me-3"
            :type="contents.data.fields[0].type"
            :placeholder="contents.data.fields[0].placeholder"
          />
        </div>
        <div
          :class="
            userNameErrorClass.length > 0
              ? `${userNameErrorClass} d-block`
              : 'd-none'
          "
        >
          {{ contents.data.fields[0].error }}
        </div>
      </div>

      <div class="form__input mb-4">
        <label class="mb-1" for="">{{ contents.data.fields[1].title }}</label>
        <div
          class="input__container d-flex align-items-center"
          :class="passwordErrorClass"
        >
          <i class="bi px-5" :class="contents.data.fields[1].icon"></i>
          <input
            v-model="password"
            dir="ltr"
            class="flex-grow-1 fs-5 me-3"
            :placeholder="contents.data.fields[1].placeholder"
            :type="passwordVisibility ? 'text' : 'password'"
          />
          <div class="me-3" @click="visibilitySwitch">
            <i
              class="fs-1 me-1"
              :class="passwordVisibility ? 'bi bi-eye-slash' : 'bi bi-eye'"
            ></i>
          </div>
        </div>
        <div
          :class="
            passwordErrorClass.length > 0
              ? `${passwordErrorClass} d-block`
              : 'd-none'
          "
        >
          {{ contents.data.fields[1].error }}
        </div>
      </div>
      <p class="mb-2">
        <a
          class="link-primary text-muted text-decoration-none"
          :href="`/${routeLocale}/forget-password`"
          :type="contents.data.fields[2].title"
          >{{ contents.data.fields[2].title }}</a
        >
      </p>
      <!-- <div v-html="contents.data.fields[3].title"></div> -->

      <div
        class="pt-5 mb-4 d-flex justify-content-center flex-column flex-xl-row"
      >
        <button
          ref="testFocus"
          class="btn btn-primary px-15 m-1 w-100"
          :type="contents.data.buttons[0].type"
          @click="userLogin"
        >
          {{ contents.data.buttons[0].title }}
        </button>
        <!-- <button
          class="btn btn-light px-14 m-1"
          :type="contents.data.buttons[1].type"
        >
          {{ contents.data.buttons[1].title }}
          <span>
            <img
              :src="`/assets/images/${contents.data.buttons[1].image}`"
              alt=""
            />
          </span>
        </button> -->
      </div>
    </form>
  </div>
</template>

<script>
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";
import dataService from "../../../services/data";

export default {
  setup() {},
  data() {
    return {
      testFocus: false,
      userName: "",
      password: "",

      userNameErrorClass: "",
      passwordErrorClass: "",

      passwordVisibility: false,

      contents: {
        data: {},
        status: false,
      },
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
  },
  methods: {
    getContents() {
      let data = {
        endPoint: `contents/r/${this.routePath.substring(4)}`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    userLogin() {
      if (this.inputValidation()) {
        let data = {
          endPoint: "users/l",
          locale: `${this.routeLocale}`,
          data: {
            userName: this.userName,
            password: this.password,
            oauthType: "none",
          },
        };
        dataService
          .post(data)
          .then((res) => {
            this.$store.commit("setToken", res.data.data);
            window.location = `/${this.routeLocale}/profile`;
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
    },
    inputValidation() {
      if (this.userName.length == 0 && this.password.length == 0) {
        this.userNameErrorClass = "wrong-inputs";
        this.passwordErrorClass = "wrong-inputs";
        return false;
      } else if (this.password.length == 0) {
        this.userNameErrorClass = "";
        this.passwordErrorClass = "wrong-inputs";
        return false;
      } else if (!isEmail(this.userName) && !isMobilePhone(this.userName, ["fa-IR"])) {
        this.userNameErrorClass = "wrong-inputs";
        this.passwordErrorClass = "";
        this.contents.data.fields[0].error =
          this.routeLocale == "en"
            ? "Enter your email address or phone number correctly"
            : "ایمیل یا شماره موبایل خود را بصورت صحیح وارد نمایید.";
        return false;
      } 
      return true;
    },
    visibilitySwitch() {
      this.passwordVisibility = !this.passwordVisibility;
    },
  },
  mounted() {
    this.getContents();
  },
  updated() {
    if (this.$refs["testFocus"] && !this.testFocus) {
      this.$refs["testFocus"].focus();
      this.testFocus = true;
    }
  },
};
</script>

<style scoped>
.wrong-inputs {
  color: red;
  border-color: red;
}
</style>
