<template>
  <div v-if="contents.status">
    <!--begin::Main Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">
                  {{ contents.data.page.locale.subTitle }}</span
                >
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt="image"
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Layout-->
      <div v-if="configInfo.status" class="d-flex flex-column flex-xl-row">
        <!--begin::Navbar-->
        <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
          <!--begin::Services-->
          <div class="card mb-5 mb-xl-8">
            <!--begin::Card header-->
            <div
              class="card-header collapsible border-0 cursor-pointer collapsed"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_services"
              aria-expanded="true"
            >
              <div class="card-title">
                <h3 class="fw-bolder m-0">
                  {{ contents.data.page.navbar.title }}
                </h3>
              </div>
              <div class="card-toolbar rotate-180">
                <span class="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11"
                      y="18"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 18)"
                      fill="black"
                    ></rect>
                    <path
                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
            <!--end::Card header-->
            <div id="kt_services" class="collapse">
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Notice-->
                <div
                  class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
                >
                  <!--begin::Icon-->
                  <!--begin::Svg Icon | path: icons/duotune/art/art006.svg-->
                  <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        opacity="0.3"
                        d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
                        fill="black"
                      />
                      <path
                        d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--end::Icon-->
                  <!--begin::Wrapper-->
                  <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-bold">
                      <div class="fs-6 text-gray-700">
                        {{ contents.data.page.navbar.description }}
                      </div>
                    </div>
                    <!--end::Content-->
                  </div>
                  <!--end::Wrapper-->
                </div>
                <!--end::Notice-->
                <!--begin::Items-->
                <div class="py-2">
                  <!--begin::Navbar Item-->
                  <div
                    v-for="(service, index) in configInfo.configRoutes.navbar"
                    :key="index"
                  >
                    <!--begin::Item-->
                    <div v-if="service.showable">
                      <div class="d-flex flex-stack align-items-start">
                        <div class="d-flex flex-column">
                          <a
                            class="fs-5 text-dark text-hover-primary fw-bolder cursor-pointer collapsible rotate collapsed"
                            data-bs-toggle="collapse"
                            aria-expanded="false"
                            :href="`#kt_collapse_block_${service.label[routeLocale]}`"
                          >
                            <span class="me-1">{{
                              service.label[routeLocale]
                            }}</span>
                            <div class="rotate-180">
                              <span class="svg-icon svg-icon-muted svg-icon-5"
                                ><svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </span>
                            </div>
                          </a>
                        </div>

                        <div class="d-flex">
                          <!--begin::Switch-->
                          <label
                            class="form-check form-switch form-switch-sm form-check-custom form-check-solid"
                          >
                            <!--begin::Input-->
                            <input
                              v-model="service.active"
                              class="form-check-input"
                              :name="`${service.name}`"
                              type="checkbox"
                              id="kt_modal_connected_accounts_google"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                              for="kt_modal_connected_accounts_google"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                      </div>
                      <div
                        class="collapse collapsed mt-5"
                        :id="`kt_collapse_block_${service.label[routeLocale]}`"
                      >
                        <div class="d-flex flex-stack">
                          <label class="col-4 col-form-label fw-bold fs-6">{{contents.data.page.navbar.route.path}}</label>
                          <div class="col-8 fv-row">
                            <!--begin::Input-->
                            <input
                              placeholder=""
                              v-model="service.path"
                              type="text"
                              class="form-control form-control-sm form-control-solid"
                              name="subject"
                            />
                            <!--end::Input-->
                          </div>
                        </div>
                        <div class="d-flex flex-stack">
                          <label class="col-4 col-form-label fw-bold fs-6">{{contents.data.page.navbar.route.label}}</label>
                          <div class="col-8 fv-row">
                            <!--begin::Input-->
                            <input
                              placeholder=""
                              v-model="service.label[routeLocale]"
                              type="text"
                              class="form-control form-control-sm form-control-solid"
                              name="subject"
                            />
                            <!--end::Input-->
                          </div>
                        </div>
                      </div>
                      <!--end::Item-->
                      <div class="separator separator-dashed my-5"></div>
                    </div>
                  </div>
                  <!--end::Navbar Item-->
                </div>
                <!--end::Items-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer border-0 d-flex justify-content-end pt-0">
                <button
                  @click="updateNavbar"
                  class="btn btn-sm btn-light-primary"
                >
                  {{ contents.data.page.navbar.buttonLabel }}
                </button>
              </div>
              <!--end::Card footer-->
            </div>
          </div>
          <!--end::Services-->
        </div>
        <!--end::Navbar-->
        <!--begin::Settings-->
        <div class="flex-lg-row-fluid ms-lg-15">
          <!--begin::Site info-->
          <div class="card mb-5 mb-xl-10">
            <!--begin::Card header-->
            <div
              class="card-header collapsible border-0 cursor-pointer collapsed"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              <!--begin::Card title-->
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0">
                  {{ contents.data.page.settings.title }}
                </h3>
              </div>
              <div class="card-toolbar rotate-180">
                <span class="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11"
                      y="18"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 18)"
                      fill="black"
                    ></rect>
                    <path
                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </div>
              <!--end::Card title-->
            </div>
            <!--begin::Card header-->
            <!--begin::Content-->
            <div id="kt_account_profile_details" class="collapse">
              <!--begin::Form-->
              <!-- <form class="form"> -->
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div
                  v-for="(setting, index) in contents.data.page.settings.fields"
                  :key="index"
                  class="row mb-10"
                >
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                    setting.title
                  }}</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div v-if="setting.type == 'text'" class="col-lg-8 fv-row">
                    <!--begin::Input-->
                    <input
                      :placeholder="setting.placeholder"
                      v-model="configInfo[setting.name]"
                      type="text"
                      class="form-control form-control-solid"
                      :class="
                        setting.required && setting.errorCode
                          ? 'wrong-inputs'
                          : ''
                      "
                      name="subject"
                    />
                    <div
                      v-if="setting.required && setting.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ setting.validation[setting.errorCode - 1].error }}
                    </div>
                    <!--end::Input-->
                  </div>
                  <div v-if="setting.type == 'number'" class="col-lg-8 fv-row">
                    <!--begin::Input-->
                    <input
                      :placeholder="setting.placeholder"
                      v-model="configInfo[setting.name]"
                      type="text"
                      class="form-control form-control-solid"
                      :class="
                        setting.required && setting.errorCode
                          ? 'wrong-inputs'
                          : ''
                      "
                      name="subject"
                    />
                    <div
                      v-if="setting.required && setting.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ setting.validation[setting.errorCode - 1].error }}
                    </div>
                    <!--end::Input-->
                  </div>
                  <div
                    v-if="setting.type == 'combobox'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="configInfo[setting.name]"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, i) in setting.options"
                        :key="i"
                        :value="`${option.value}`"
                      >
                        {{ option.title }}
                      </option>
                    </select>
                    <!--end::Input-->
                  </div>
                  <div
                    v-else-if="setting.type == 'logo'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="currentLogo"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, optionKey) in $store.state.logos"
                        :key="option"
                        :value="{
                          index: optionKey,
                          value: option,
                        }"
                      >
                        {{ optionKey }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="mt-5 image-input" data-kt-image-input="true">
                      <div
                        class="w-250px h-100px image-input-wrapper bg-secondary"
                      >
                        <img
                          :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${currentLogo.value}`"
                          alt=""
                          class="mw-100 mh-100px mh-lg-100px"
                        />
                      </div>
                      <!--begin::Edit button-->
                      <!-- <label
                        @click="setModalConfigs('choose', currentLogo)"
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                      >
                        <i class="bi bi-pencil-fill fs-7"></i>
                      </label> -->
                      <!--end::Edit button-->
                    </div>
                  </div>
                  <div
                    v-else-if="setting.type == 'icombobox'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="currentTheme"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, i) in themes"
                        :key="i"
                        :value="option.id"
                      >
                        {{ option.themeTitle }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="my-5 w-75">
                      <img
                        :src="`${backendHost}/images/preview/${themes[currentThemeIndex].themeImage}`"
                        :alt="themes[currentThemeIndex].themeName"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="setting.type == 'sidebar'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="currentRoute"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="option in setting.contentJson.routes.length - 1"
                        :key="option"
                        :value="{
                          index: option,
                          image:
                            setting.contentJson.routes[Number(option)].image,
                        }"
                      >
                        {{ setting.contentJson.routes[option].title }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="mt-5 image-input" data-kt-image-input="true">
                      <div class="w-250px h-250px image-input-wrapper">
                        <img
                          :src="`${backendHost}/images/themes/${contentTheme}/${currentRoute.image}`"
                          alt=""
                          class="mw-100 mh-125px mh-lg-225px"
                        />
                      </div>
                      <!--begin::Edit button-->
                      <label
                        @click="setModalConfigs('choose', currentRoute)"
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                      >
                        <i class="bi bi-pencil-fill fs-7"></i>
                      </label>
                      <!--end::Edit button-->
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  @click="inputValidation"
                  class="btn btn-sm btn-light-primary"
                >
                  {{ contents.data.page.settings.buttonLabel }}
                </button>
              </div>
              <!--end::Actions-->
              <!-- </form> -->
              <!--end::Form-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Site info-->
        </div>
        <!--end::Settings-->
      </div>
      <!--end::Layout-->
      <!--begin::Layout-->
      <div v-if="configInfo.status" class="d-flex flex-column flex-xl-row">
        <!--begin::Style-->
        <div class="flex-lg-row-fluid">
          <!--begin::Site info-->
          <div class="card mb-5 mb-xl-10">
            <!--begin::Card header-->
            <div
              class="card-header collapsible border-0 cursor-pointer collapsed"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_style_details"
              aria-expanded="true"
              aria-controls="kt_account_style_details"
            >
              <!--begin::Card title-->
              <div class="card-title m-0">
                <h3 class="fw-bolder m-0">
                  {{ contents.data.page.styles.title }}
                </h3>
              </div>
              <div class="card-toolbar rotate-180">
                <span class="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="11"
                      y="18"
                      width="13"
                      height="2"
                      rx="1"
                      transform="rotate(-90 11 18)"
                      fill="black"
                    ></rect>
                    <path
                      d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                      fill="black"
                    ></path>
                  </svg>
                </span>
              </div>
              <!--end::Card title-->
            </div>
            <!--begin::Card header-->
            <!--begin::Content-->
            <div id="kt_account_style_details" class="collapse">
              <!--begin::Form-->
              <!-- <form class="form"> -->
              <!--begin::Card body-->
              <div class="card-body border-top p-9">
                <!--begin::Input group-->
                <div
                  v-for="(style, index) in contents.data.page.styles.fields"
                  :key="index"
                  class="row mb-10"
                >
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                    style.title
                  }}</label>
                  <!--end::Label-->
                  <!--begin::Col-->
                  <div v-if="style.type == 'text'" class="col-lg-8 fv-row">
                    <!--begin::Input-->
                    <input
                      :placeholder="setting.placeholder"
                      v-model="configInfo[setting.name]"
                      type="text"
                      class="form-control form-control-solid"
                      :class="
                        setting.required && setting.errorCode
                          ? 'wrong-inputs'
                          : ''
                      "
                      name="subject"
                    />
                    <div
                      v-if="setting.required && setting.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ setting.validation[setting.errorCode - 1].error }}
                    </div>
                    <!--end::Input-->
                  </div>
                  <div v-if="style.type == 'number'" class="col-lg-8 fv-row">
                    <!--begin::Input-->
                    <input
                      :placeholder="setting.placeholder"
                      v-model="configInfo[setting.name]"
                      type="text"
                      class="form-control form-control-solid"
                      :class="
                        setting.required && setting.errorCode
                          ? 'wrong-inputs'
                          : ''
                      "
                      name="subject"
                    />
                    <div
                      v-if="setting.required && setting.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ setting.validation[setting.errorCode - 1].error }}
                    </div>
                    <!--end::Input-->
                  </div>
                  <div
                    v-if="style.type == 'combobox'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="configInfo[style.name.split('->')[0]][style.name.split('->')[1]]"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, i) in $store.state.languages.value"
                        :key="i"
                        :value="`${option.locale}`"
                      >
                        {{ option.title }}
                      </option>
                    </select>
                    <!--end::Input-->
                  </div>
                  <div
                    v-else-if="style.type == 'logo'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="currentLogo"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, optionKey) in configInfo.configLogo"
                        :key="optionKey"
                        :value="optionKey"
                      >
                        {{ optionKey }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="mt-5 image-input" data-kt-image-input="true">
                      <div
                        class="w-250px h-100px image-input-wrapper bg-secondary d-flex flex-center d-flex-"
                      >
                        <img
                          :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${configInfo.configLogo[currentLogo]}`"
                          :alt="`${backendHost}/uploads/${$store.state.uploadsPath}/${configInfo.configLogo[currentLogo]}`"
                          class="mw-100 mh-100px mh-lg-100px"
                        />
                      </div>
                      <!--begin::Edit button-->
                      <label
                        @click="setModalConfigs('choose', currentLogo)"
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                      >
                        <i class="bi bi-pencil-fill fs-7"></i>
                      </label>
                      <!--end::Edit button-->
                    </div>
                  </div>
                  <div
                    v-else-if="style.type == 'icombobox'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="configInfo[style.name]"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="(option, i) in themes"
                        :key="i"
                        :value="option.id"
                      >
                        {{ option.themeTitle }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="my-5 w-75">
                      <img
                        :src="`${backendHost}/images/preview/${themes[Number(configInfo[style.name]) - 1].themeImage}`"
                        :alt="themes[Number(configInfo[style.name]) - 1].themeName"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="style.type == 'sidebar'"
                    class="col-lg-8 fv-row"
                  >
                    <!--begin::Input-->
                    <select
                      v-model="currentRoute"
                      data-control="select2"
                      class="form-select form-select-solid form-select-lg"
                    >
                      <option
                        v-for="option in setting.contentJson.routes.length - 1"
                        :key="option"
                        :value="{
                          index: option,
                          image:
                            setting.contentJson.routes[Number(option)].image,
                        }"
                      >
                        {{ setting.contentJson.routes[option].title }}
                      </option>
                    </select>
                    <!--end::Input-->
                    <div class="mt-5 image-input" data-kt-image-input="true">
                      <div class="w-250px h-250px image-input-wrapper">
                        <img
                          :src="`${backendHost}/images/themes/${contentTheme}/${currentRoute.image}`"
                          alt=""
                          class="mw-100 mh-125px mh-lg-225px"
                        />
                      </div>
                      <!--begin::Edit button-->
                      <label
                        @click="setModalConfigs('choose', currentRoute)"
                        class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                      >
                        <i class="bi bi-pencil-fill fs-7"></i>
                      </label>
                      <!--end::Edit button-->
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
                <!--end::Input group-->
              </div>
              <!--end::Card body-->
              <!--begin::Actions-->
              <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button
                  @click="updateStyles"
                  class="btn btn-sm btn-light-primary"
                >
                  {{ contents.data.page.settings.buttonLabel }}
                </button>
              </div>
              <!--end::Actions-->
              <!-- </form> -->
              <!--end::Form-->
            </div>
            <!--end::Content-->
          </div>
          <!--end::Site info-->
        </div>
        <!--end::Style-->
      </div>
      <!--end::Layout-->
    </div>
    <!--end::Main Container-->
    <!--end::Modal dialog-->
    <upload-assets
      v-if="modalDisplay"
      :contents="null"
      :modalInfo="uploadInfo"
      :mode="'single'"
      :modalType="'uploadselect'"
      @close-modal="setModalConfigs"
    >
    </upload-assets>
    <!--end::Modals-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import UploadAssets from "../../modals/UploadAssets.vue";
import serverConfigs from "../../../configs/server.json";

export default {
  setup() {},
  components: {
    UploadAssets,
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
    currentThemeIndex() {
      for (let t in this.themes) {
        if (this.themes[t].id == this.currentTheme) {
          return t;
        }
      }
      return null;
    },
  },
  data() {
    return {
      configInfo: {},
      uploadInfo: {},
      currentLogo: "",
      modalDisplay: false,
      loaderStatus: [false, false, false, false, false],
      settings: {
        emailAddress: "saeed@test.com",
        password: "***********",
        phoneNumber: "0123456789",
        shopeService: true,
        blogService: true,
        aboutService: true,
        contactService: false,
        ticketService: false,
        siteLanguage: "fa",
        siteThemeColor: "purple",
        previewThemeColor: "purplePreview.svg",
        panelImage: "settings",
        previewPanelImage: "Settings.svg",
      },
      contents: {
        name: "",
        data: {},
        status: false,
      },
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      let tempImage = [];
      let tempIndex = [];
      let tempUpload = [];
      let tempPhotos = [this.configInfo.configLogo[this.currentLogo]];
      // let temp = "";
      switch (opt) {
        case "open":
          this.getUploads();
          break;
        case "choose":
          for (let j in tempPhotos) {
            for (let i in this.uploadInfo.images) {
              if (this.uploadInfo.images[i].uploadName == tempPhotos[j]) {
                tempIndex.push(this.uploadInfo.images[i].id);
                tempImage.push(this.uploadInfo.images[i].uploadName);
                tempUpload.push(Number(i));
                break;
              }
            }
          }
          this.uploadInfo.current = {
            index: tempIndex,
            image: tempImage,
            upload: tempUpload,
          };
          this.modalDisplay = true;
          // if (index.image) {
          //   this.secletedSetting = 3;
          // } else {
          //   this.secletedSetting = 0;
          // }
          // for (let i in this.uploadInfo.images) {
          //   if (
          //     this.uploadInfo.images[i].uploadName == index.image ||
          //     this.uploadInfo.images[i].uploadName == index.value
          //   ) {
          //     this.uploadInfo["current"] = { ...index, upload: i };
          //     break;
          //   }
          // }
          break;
        case "update":
          this.roleInfo = { ...this.roles[index], status: true };
          this.roleInfo.rolePermissions = JSON.parse(
            this.roleInfo.rolePermissions
          );
          this.modalDisplay = !this.modalDisplay;
          break;
        case "create":
          this.setupRoleInfo();
          this.modalDisplay = !this.modalDisplay;
          break;
        case "validate":
          if (this.inputValidation(index)) {
            index.id ? this.updateRoles(index) : this.createRoles(index);
          }
          break;
        case "photo":
          this.configInfo.configLogo[this.currentLogo] = index.image[0]
          this.modalDisplay = !this.modalDisplay;
          break;
        default:
          this.modalDisplay = false;
          break;
      }
      this.bodyNode.style.overflow = "visible";
    },
    setInitialSiteSettingsValue() {
      this.currentLogo = {
        index: Number(this.siteSettings[0].contentJson.value) + 1,
        value:
          this.siteSettings[0].contentJson.options[
            Number(this.siteSettings[0].contentJson.value)
          ].value,
      };
      this.currentTheme = this.siteSettings[2].contentJson.value;
      this.currentRoute = {
        index: 1,
        image: this.siteSettings[3].contentJson.value,
      };
    },
    getContents() {
      let data = {
        endPoint: `contents/r/settings`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    readConfigs() {
      let data = {
        endPoint: `configs`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.configInfo = { ...res.data.data[0], status: true };
          this.currentLogo = "mainLogo"
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    getLogos() {
      let data = {
        endPoint: `contents/r/logos`,
        locale: "en",
        category: "settings",
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let logos = {};
          logos["mainLogo"] = res.data.data[0].contentJson.options[0].value;
          logos["lightLogo"] = res.data.data[0].contentJson.options[1].value;
          logos["mobileLogo"] = res.data.data[0].contentJson.options[2].value;
          logos["mobileLightLogo"] =
            res.data.data[0].contentJson.options[3].value;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    getUploads() {
      let data = {
        endPoint: `uploads`,
        locale: "fa",
        token: this.$store.state.token,
        data: {},
        // query: {
        //   type: "blog",
        // },
      };
      // data.query = JSON.stringify(data.query)
      //   .replace("{", "")
      //   .replace(",", "&")
      //   .replace("}", "")
      //   .replace(/":"/g, "=")
      //   .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          this.uploadInfo = {
            images: res.data.data.reverse(),
            current: {
              upload: [],
            },
          };
        })
        .catch(() => {
        });
    },
    getSettings() {
      let data = {
        endPoint: `settings`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.settings = res.data.data;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[3] = true;
          this.loaderDone();
        });
    },
    getServicesSettings() {
      let temp = this.siteSettings;
      for (let setting in temp) {
        if (
          temp[setting].contentName == "sidebar" ||
          temp[setting].contentName == "navbar"
        ) {
          let t = temp[setting];
          
          this.servicesSettings.push(t);
        }
        if (this.siteSettings[setting].contentName == "navbar") {
          this.siteSettings.splice(setting, 1);
        }
      }
    },
    getSiteSettings() {
      let data = {
        endPoint: `contents`,
        locale: `${this.routeLocale}`,
        data: {},
        query: {
          category: "settings",
          clocale: `${this.routeLocale}`,
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          this.siteSettings = res.data.data;
          // this.setInitialSiteSettingsValue();
          this.getServicesSettings();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[4] = true;
          this.loaderDone();
        });
    },
    updateServicesSettings() {
      for (let setting in this.servicesSettings) {
        this.servicesSettings[setting].contentJson = JSON.stringify(
          this.servicesSettings[setting].contentJson
        );
      }
      let data = {
        endPoint: `contents/u`,
        locale: `${this.routeLocale}`,
        data: this.servicesSettings,
      };
      dataService
        .post(data)
        .then(() => {
          
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
      location.reload();
    },
    inputValidation() {
      this.updateConfigs(this.configInfo);
    },
    updateConfigs(obj) {
      let data = {
        endPoint: `configs/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          pageLimit: obj["configPageLimit"],
          resendCodeTime: obj["configResendCodeTime"],
          minFileSize: obj["configMinFileSize"],
          maxFileSize: obj["configMaxFileSize"],
          verificationCodeLength: obj["configVerificationCodeLength"],
          verificationCodeType: obj["configVerificationCodeType"],
          shopType: obj["configShopType"],
          recentBlogLimit: obj["configRecentBlogLimit"],
          blogPageLimit: obj["configBlogPageLimit"],
          recentShopLimit: obj["configRecentShopLimit"],
          shopPageLimit: obj["configShopPageLimit"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          location.reload();
          // this.readConfigs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    // updateSiteSettings() {
    //   for (let setting in this.siteSettings) {
    //     let tempSetting = this.siteSettings[setting].contentJson;
    //     switch (this.siteSettings[setting].contentName) {
    //       case "languages":
    //         break;
    //       case "theme":
    //         tempSetting.previous = tempSetting.value;
    //         tempSetting.value = this.currentTheme;
    //         
    //         
    //         break;
    //       case "sidebar":
    //         tempSetting.value = tempSetting.routes[Number(1)].image;
    //         break;
    //       default:
    //         break;
    //     }
    //     this.siteSettings[setting].contentJson = JSON.stringify(tempSetting);
    //   }
    //   let data = {
    //     endPoint: `contents/u`,
    //     locale: `${this.routeLocale}`,
    //     data: this.siteSettings,
    //   };
    //   dataService
    //     .post(data)
    //     .then(() => {
    //       location.reload();
    //     })
    //     .catch((error) => {
    //       this.$notify({
    //         type: error.response.data.type,
    //         title: error.response.data.type,
    //         text: error.response.data.message,
    //       });
    //     })
    //     .finally(() => {
    //       this.$store.commit("setLoader", false);
    //     });
    // },
    updateStyles() {
      let data = {
        endPoint: `configs/u/${this.configInfo.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          language: this.configInfo.configLanguage,
          theme: this.configInfo.configTheme,
          logo: this.configInfo.configLogo,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.$store.commit("setRoutes", this.configInfo.configRoutes);
          location.reload();
          // this.readConfigs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    updateNavbar(){
      let data = {
        endPoint: `configs/u/${this.configInfo.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          navbar: this.configInfo.configRoutes,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.$store.commit("setRoutes", this.configInfo.configRoutes);
          location.reload();
          // this.readConfigs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readThemes() {
      let data = {
        endPoint: `themes`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          
          this.themes = res.data.data;
          // this.currentTheme = this.$store.state.theme;
          // this.defaultLocale = this.$store.state.languages.default;
          // this.currentLogo = {
          //   index: "mainLogo",
          //   value: this.$store.state.logos["mainLogo"],
          // };
          // setTimeout(() => {
          //   this.settingStatus = true;
          // }, 150);
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    loaderDone() {
      if (this.loaderStatus[0]) {
        this.$store.commit("setLoader", false);
      }
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    // this.getSiteSettings();
    this.getUploads();
    // this.getLogos();
    this.readConfigs();
    this.readThemes();
    this.getContents();
  },
};
</script>
