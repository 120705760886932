<template>
  <!--begin::Container-->
  <div
    v-if="contents.status"
    id="kt_content_container"
    class="container-xxl my-5"
  >
    <!--begin::Hero card-->
    <div class="card mb-12 shadow-sm">
      <!--begin::Hero body-->
      <div class="card-body flex-column p-5 mb-5">
        <!--begin::Hero content-->
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
        >
          <!--begin::Wrapper-->
          <div
            class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
          >
            <!--begin::Title-->
            <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
              {{ contents.data.page.locale.title }}
            </h1>
            <!--end::Title-->
            <!--begin::Input group-->
            <div class="position-relative w-100 mb-5 mb-lg-10">
              <span class="text-gray-600 fs-6">{{
                contents.data.page.locale.subTitle
              }}</span>
            </div>
            <!--end::Input group-->
            <!--begin::Action-->
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_new_ticket"
              class="btn btn-primary fw-bolder fs-5"
              ><i
                class="bi me-2 fs-4"
                :class="contents.data.page.setting.buttonIcon"
              ></i
              >{{ contents.data.page.locale.buttonLabel }}</a
            >
            <!--end::Action-->
          </div>
          <!--end::Wrapper-->
          <!--begin::Wrapper-->
          <div
            class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
          >
            <!--begin::Illustration-->
            <img
              :src="`${backendHost}/images/themes/${contentTheme}/${
                $store.state.sidebar[contents.name].routeImage
              }`"
              alt=""
              class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
            />
            <!--end::Illustration-->
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Hero content-->
      </div>
      <!--end::Hero body-->
    </div>
    <!--end::Hero card-->
    <!--begin::Tabs-->
    <div v-if="pagesContents.length > 0" class="flex-lg-row-fluid">
      <!--begin:::Tabs-->
      <ul
        class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-8 fw-bold mb-8"
      >
        <!--begin:::Tab item-->
        <li
          v-for="(tab, index) in $store.state.pages"
          :key="index"
          class="nav-item"
        >
          <a
            href="#"
            data-bs-toggle="tab"
            class="nav-link text-active-primary pb-4 fs-4"
            :class="index == currentTab ? 'active' : ''"
            @click="setCurrentTab(index)"
            >{{ tab.pageName }}</a
          >
        </li>
        <!--end:::Tab item-->
        <!--begin:::Tab item-->
        <!-- <li class="nav-item ms-auto"></li> -->
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->
      <!--begin:::Tab content-->
      <div class="tab-content" id="myTabContent">
        <!--begin:::Tab pane 1-->
        <div
          v-for="(tab, index) in $store.state.pages"
          :key="index"
          class="tab-pane fade show active"
          id="kt_user_view_overview_tab"
          role="tabpanel"
        >
          <!--begin::Content card-->
          <div v-if="index == currentTab" class="card shadow-sm">
            <!--begin::Body-->
            <div class="card-body p-lg-15">
              <!--begin::Classic content-->
              <div class="mb-13">
                <!--begin::Intro-->
                <div class="mb-15">
                  <!--begin::Title-->
                  <div
                    v-if="titleEditMode"
                    class="d-flex flex-column flex-md-row"
                  >
                    <input
                      :ref="`pageTitle_Input`"
                      :maxlength="pageTitleConfigs.maxLength"
                      class="form-control form-control-solid p-4"
                      :class="`${pageTitleConfigs.class}
                        ${
                          pageTitleConfigs.required &&
                          pageTitleConfigs.errorCode
                            ? 'wrong-inputs'
                            : ''
                        }`"
                      aria-describedby="validationErrorFeedback"
                      type="text"
                      :placeholder="pageTitleConfigs.placeholder[routeLocale]"
                      v-model="pagesContents[index].contentJson.title"
                    />
                    <button
                      @click="
                        () => {
                          titleEditMode = false;
                        }
                      "
                      class="mt-2 mt-md-0 ms-md-2 btn btn-danger text-nowrap"
                    >
                      Cancel
                    </button>
                    <button
                      @click="
                        updateContents(
                          pagesContents[this.currentTab].contentJson.seo
                        )
                      "
                      class="mt-2 mt-md-0 ms-md-2 btn btn-success text-nowrap"
                    >
                      Save changes
                    </button>
                  </div>
                  <div v-else class="d-flex flex-stack flex-column flex-md-row">
                    <div class="position-relative p-6">
                      <h4 class="fs-2x text-gray-800 w-bolder mb-6">
                        {{ pagesContents[index].contentJson.title }}
                      </h4>
                      <label
                        v-if="!seoEditMode"
                        @click="
                          () => {
                            titleEditMode = true;
                          }
                        "
                        class="position-absolute top-0 end-0 btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                      >
                        <i class="bi bi-pencil-fill fs-7"></i>
                      </label>
                    </div>
                    <div v-if="seoEditMode" class="col-12 w-md-unset">
                      <button
                        class="mb-2 mb-md-0 ms-md-2 btn btn-danger col-12 w-md-unset"
                        @click="
                          () => {
                            seoEditMode = false;
                          }
                        "
                      >
                        Cancel
                      </button>
                      <button
                        @click="
                          updateContents(
                            pagesContents[this.currentTab].contentJson.seo
                          )
                        "
                        class="ms-md-2 btn btn-success col-12 w-md-unset"
                      >
                        Save changes
                      </button>
                    </div>
                    <button
                      v-else
                      class="ms-2 btn btn-primary col-12 w-md-unset"
                      @click="
                        () => {
                          seoEditMode = true;
                        }
                      "
                    >
                      SEO Module
                    </button>
                  </div>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <!-- <p class="fs-4 text-gray-600 mb-2">
                    {{ tab.subTitle }}
                  </p> -->
                  <!--end::Text-->
                </div>
                <div v-if="seoEditMode" class="">
                  <!--begin::Input group-->
                  <div
                    v-for="(field, i) in contents.data.page.seo.fields"
                    :key="i"
                    class="row mb-10"
                  >
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                      field.title
                    }}</label>
                    <!--end::Label-->
                    <!--begin::Col-->
                    <div v-if="field.type == 'text'" class="col-lg-8 fv-row">
                      <span v-if="field.maxLength" class="d-flex justify-content-end me-3">
                        {{
                          pagesContents[index].contentJson.seo[field.name]
                            .length
                        }}
                        /
                        {{ field.maxLength }}
                      </span>
                      <!--begin::Input-->
                      <input
                        :placeholder="field.placeholder"
                        v-model="
                          pagesContents[index].contentJson.seo[field.name]
                        "
                        :maxlength="field.maxLength"
                        type="text"
                        class="form-control form-control-solid"
                        :class="
                          field.required && field.errorCode
                            ? 'wrong-inputs'
                            : ''
                        "
                        name="subject"
                      />
                      <div
                        v-if="field.required && field.errorCode > 0"
                        id="validationErrorFeedback"
                        class="invalid-feedback d-block"
                      >
                        {{ field.validation[field.errorCode - 1].error }}
                      </div>
                      <!--end::Input-->
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <!--end::Intro-->
                <!--begin::Row-->
                <div
                  class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9"
                >
                  <!--begin::Card-->
                  <div
                    v-for="(section, i) in pagesDetails[index]"
                    :key="i"
                    class="p-3"
                  >
                    <div class="card overlay overflow-hidden overlay-show">
                      <div class="card-body p-0">
                        <div class="overlay-wrapper">
                          <div class="blog__template-img">
                            <!--begin::Image-->
                            <div
                              class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-contain card-rounded h-200px"
                              :style="`
                            background-image: url(${backendHost}/uploads/${
                                $store.state.uploadsPath
                              }/${
                                pagesContents[index].contentJson[section.key]
                                  .previewImage
                              });`"
                            ></div>
                            <!--end::Image-->
                          </div>
                        </div>
                        <div
                          class="overlay-layer flex-column justify-content-center bg-dark bg-opacity-50 p-5"
                        >
                          <div
                            class="d-flex flex-column text-center justify-content-center px-10"
                          >
                            <div
                              class="col text-white fw-bold fs-3 mb-5 turncating"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Slider"
                            >
                              {{ section.title }}
                            </div>
                            <div class="col d-flex">
                              <!-- <a
                                :href="`${backendHost}${
                                  pagesContents[index].contentJson[section.key]
                                    .path
                                }`"
                                target="_blank"
                                class="col btn btn-sm btn-primary btn-shadow"
                                >Preview</a
                              > -->
                              <a
                                @click="setModalConfigs('update', i, index)"
                                class="col btn btn-sm btn-light-primary btn-shadow ms-2"
                                >Edit</a
                              >
                              <!-- <a
                              @click="setModalConfigs('delete', index)"
                              class="
                                col
                                btn btn-sm btn-light-danger btn-shadow
                                ms-2
                              "
                              >Delete</a
                            > -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end::Card-->
                </div>
                <!--end::Row-->
              </div>
              <!--end::Classic content-->
            </div>
            <!--end::Body-->
          </div>
          <!--end::Content card-->
        </div>
        <!--end:::Tab pane 1-->
      </div>
      <!--end:::Tab content-->
    </div>
    <!--end::Tabs-->
    <!--begin::Modals-->
    <create-update
      v-if="modalDisplay"
      :contents="contentsModal"
      :modalInfo="itemInfo"
      :mode="'multi'"
      :modalType="'updateselect'"
      :icons="modalIcons"
      @close-modal="setModalConfigs"
    ></create-update>
    <!--end::Modals-->
  </div>
  <!--end::Container-->
</template>

<script>
import serverConfigs from "../../../configs/server.json";
import dataService from "../../../services/data";
import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  setup() {},
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  components: {
    CreateUpdate,
  },
  data() {
    return {
      tempStatus: false,
      modalIcons: [],
      titleEditMode: false,
      seoEditMode: false,
      pageTitleConfigs: {
        maxLength: 60,
        placeholder: {
          en: "Page title",
          fa: "عنوان صفحه",
        },
        required: true,
        errorCode: 0,
        class: "",
      },
      pagesDetails: [],
      pagesContents: [],
      contentsModal: {},
      modalDisplay: false,
      modalItemDisplay: false,
      sliderModal: {},
      sliderPage: {},
      currentTab: 0,
      contents: {
        name: "",
        data: {},
        status: false,
      },
      editButton: true,
      templateInfo: {},
      itemInfo: {},
      loaderStatus: [false, false, false, false, false, false],
      testHBS: "",
    };
  },
  methods: {
    setModalConfigs(opt, index, tab) {
      this.$store.commit("setLoader", true);

      switch (opt) {
        case "update":
          this.contentsModal = {
            ...this.pagesDetails[tab][index],
            size: "modal-lg",
          };
          this.itemInfo = {
            ...this.pagesContents[tab].contentJson[
              this.pagesDetails[tab][index].key
            ],
            status: false,
            key: this.pagesDetails[tab][index].key,
          };
          for (let item in this.pagesDetails[tab][index].fields) {
            if (
              this.pagesDetails[tab][index].fields[item].type == "selectFile"
            ) {
              this.itemInfo[this.pagesDetails[tab][index].fields[item].name] = [
                this.itemInfo[this.pagesDetails[tab][index].fields[item].name],
              ];
            } else if (
              this.pagesDetails[tab][index].fields[item].type == "card"
            ) {
              for (let i in this.pagesDetails[tab][index].fields[item].fields) {
                if (
                  this.pagesDetails[tab][index].fields[item].fields[i].type ==
                  "selectFile"
                ) {
                  console.log(
                    this.pagesDetails[tab][index].fields[item].fields[i].name
                  );
                } else if (
                  this.pagesDetails[tab][index].fields[item].fields[i].type ==
                  "card"
                ) {
                  for (let c in this.pagesDetails[tab][index].fields[item]
                    .fields[i].fields) {
                    if (
                      this.pagesDetails[tab][index].fields[item].fields[i]
                        .fields[c].type == "selectFile"
                    ) {
                      for (let x in this.itemInfo[
                        this.pagesDetails[tab][index].fields[item].name
                      ]) {
                        for (let y in this.itemInfo[
                          this.pagesDetails[tab][index].fields[item].name
                        ][x][
                          this.pagesDetails[tab][index].fields[item].fields[i]
                            .name
                        ]) {
                          this.itemInfo[
                            this.pagesDetails[tab][index].fields[item].name
                          ][x][
                            this.pagesDetails[tab][index].fields[item].fields[
                              i
                            ].name
                          ][y][
                            this.pagesDetails[tab][index].fields[item].fields[
                              i
                            ].fields[c].name
                          ] = [
                            this.itemInfo[
                              this.pagesDetails[tab][index].fields[item].name
                            ][x][
                              this.pagesDetails[tab][index].fields[item].fields[
                                i
                              ].name
                            ][y][
                              this.pagesDetails[tab][index].fields[item].fields[
                                i
                              ].fields[c].name
                            ],
                          ];
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "create":
          this.setupUploadInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "delete":
          this.deleteInfo = {
            id: this.uploads[index].id,
            name: this.uploads[index].uploadName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);

          break;
        case "validate":
          if (index.delete) {
            this.deleteUploads(index);
          } else {
            for (let k in this.pagesContents[this.currentTab].contentJson[index.key]) {
              this.pagesContents[this.currentTab].contentJson[index.key][k] = index[k]
            }

            this.updateContents(index);
            // if (this.inputValidation(index)) {
            //   index.id ? this.updateUploads(index) : this.createUploads(index);
            // } else {
            //   this.$store.commit("setLoader", false);
            //
            // }
          }
          break;
        default:
          this.resetPageInfo();
          this.$store.commit("setLoader", false);

          break;
      }
    },
    softBox(index) {
      this.toggler = !this.toggler;
      this.softBoxSource = `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${this.uploads[index].uploadName}`;
    },
    setCurrentTab(index) {
      this.currentTab = index;
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1] && this.loaderStatus[2]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/contents`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.modalIcons = this.modalIcons.concat(this.contents.data.page.icons.bootstrap, this.contents.data.page.icons.fontawesome)
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    getLandingPages() {
      // let data = {
      //   endPoint: `contents/r/navbar`,
      //   locale: `${this.routeLocale}`,
      //   data: {},
      // };
      // dataService
      //   .get(data)
      //   .then((res) => {
      //     // this.contents["data"]["pages"]["landing"] =
      //     //   res.data.data[0].contentJson;
      //
      //   })
      //   .catch((error) => {
      //     this.$notify({
      //       type: error.response.data.type,
      //       title: error.response.data.type,
      //       text: error.response.data.message,
      //     });
      //   })
      //   .finally(() => {
      //     this.loaderStatus[2] = true;
      //     this.loaderDone();
      //   });
    },
    getPagesDetails() {
      let data = {};
      for (let p in this.$store.state.pages) {
        data = {
          endPoint: `contents/r/${
            this.$store.state.pages[p].pageContent[this.routeLocale]
          }`,
          locale: `${this.routeLocale}`,
          data: {},
        };

        dataService
          .get(data)
          .then((res) => {
            this.pagesContents.push(res.data.data[0]);
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
        setTimeout(()=> {
          console.log("Here")
        }, 3000)
      }
      this.loaderStatus[1] = true;
      this.loaderDone();
    },
    getPagesTemplate() {
      let data = {};
      for (let p in this.$store.state.pages) {
        data = {
          endPoint: `templates/r/${this.$store.state.pages[p].pageTemplate}`,
          locale: `${this.routeLocale}`,
          data: {},
        };

        dataService
          .get(data)
          .then((res) => {
            this.pagesDetails.push(res.data.data[0].templateJson.sections);
            if (p == this.$store.state.pages.length - 1) {
              console.log(p, this.$store.state.pages.length, this.pagesDetails)
              this.tempStatus = true
            }
          })
          .catch((error) => {
            this.$notify({
              type: error.response.data.type,
              title: error.response.data.type,
              text: error.response.data.message,
            });
          });
      }
      this.loaderStatus[2] = true;
      this.loaderDone();
    },
    getUploads() {
      let data = {
        endPoint: `uploads`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);

          this.uploads = res.data.data;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    setupUploadInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "textarea":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "file":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "imageInput":
            this.uploadInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          default:
            break;
        }
      }
      this.uploadInfo["status"] = true;
    },
    resetPageInfo() {
      this.templateInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      return errorCount == 0 ? true : false;
    },
    updateContents() {
      let data = {
        endPoint: `contents/u/${
          this.$store.state.pages[this.currentTab].pageContent[this.routeLocale]
        }`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          json: this.pagesContents[this.currentTab].contentJson,
          description: this.pagesContents[this.currentTab].contentDescription,
          status: this.pagesContents[this.currentTab].contentStatus,
        },
      };
      console.log(data)
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.getPagesDetails();
          this.seoEditMode = false;
          this.titleEditMode = false;
          this.modalDisplay = false;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getPagesDetails();
    this.getPagesTemplate();
    this.getContents();
  },
};
</script>
