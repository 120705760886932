<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">{{
                  contents.data.page.locale.subTitle
                }}</span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="me-2 fs-4"
                  :class="`${contents.data.page.setting.buttonIcon}`"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::FAQ card-->
      <div class="card">
        <div v-if="dataIsEmpty" class="card-body p-20">
          <div class="d-flex flex-center">
            <img
              class="col-md-3"
              :src="`/assets/images/svg/no_data.svg`"
              alt=""
            />
          </div>
        </div>
        <!--begin::Body-->
        <div v-else class="card-body p-lg-15">
          <!--begin::Classic content-->
          <div class="mb-13">
            <!--begin::Intro-->
            <div class="mb-15">
              <!--begin::Title-->
              <h4 class="fs-2x text-gray-800 w-bolder mb-6">
                {{ contents.data.page.accordion.title }}
              </h4>
              <!--end::Title-->
              <!--begin::Text-->
              <p class="fw-bold fs-4 text-gray-600 mb-2">
                {{ contents.data.page.accordion.subTitle }}
              </p>
              <!--end::Text-->
            </div>
            <!--end::Intro-->
            <!--begin::Row-->
            <div class="row mb-12">
              <!--begin::Col-->
              <div class="col">
                <!--begin::Accordion-->
                <div v-for="(faq, index) in faqs" :key="index" class="m-0">
                  <!--begin::Heading-->
                  <div
                    class="d-flex align-items-center collapsible py-3 toggle mb-0 collapsed"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#kt_job_4_${index}`"
                  >
                    <!--begin::Icon-->
                    <div
                      class="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5"
                    >
                      <!--begin::Svg Icon | path: icons/duotune/general/gen036.svg-->
                      <span
                        class="svg-icon toggle-on svg-icon-primary svg-icon-1"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="black"
                          />
                          <rect
                            x="6.0104"
                            y="10.9247"
                            width="12"
                            height="2"
                            rx="1"
                            fill="black"
                          />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                      <!--begin::Svg Icon | path: icons/duotune/general/gen035.svg-->
                      <span class="svg-icon toggle-off svg-icon-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <rect
                            opacity="0.3"
                            x="2"
                            y="2"
                            width="20"
                            height="20"
                            rx="5"
                            fill="black"
                          />
                          <rect
                            x="10.8891"
                            y="17.8033"
                            width="12"
                            height="2"
                            rx="1"
                            transform="rotate(-90 10.8891 17.8033)"
                            fill="black"
                          />
                          <rect
                            x="6.01041"
                            y="10.9247"
                            width="12"
                            height="2"
                            rx="1"
                            fill="black"
                          />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </div>
                    <!--end::Icon-->
                    <!--begin::Title-->
                    <h4
                      class="text-gray-700 fw-bolder cursor-pointer mb-0 me-3"
                    >
                      {{ faq.faqQuestion }}
                    </h4>
                    <a
                      class="text-hover-white bg-hover-primary badge badge-light-primary cursor-pointer d-inline"
                    >
                      {{ faq.categoryName }}
                    </a>
                    <!--end::Title-->
                  </div>
                  <!--end::Heading-->
                  <!--begin::Body-->
                  <div :id="`kt_job_4_${index}`" class="collapse fs-6 ms-1">
                    <div class="ps-10">
                      <!--begin::Text-->
                      <div
                        v-html="faq.faqDescription"
                        class="mb-4 text-gray-600 fw-bold fs-6"
                      ></div>
                      <!--end::Text-->
                      <div class="mb-4">
                        <a
                          @click="setModalConfigs('update', index)"
                          class="btn btn-sm btn-light btn-light-primary p-3 me-2"
                          ><i class="bi bi-pencil fs-3 me-2"></i
                          >{{
                            contents.data.page.accordion.buttons[0].label
                          }}</a
                        >
                        <a
                          @click="setModalConfigs('delete', index)"
                          class="btn btn-sm btn-light btn-light-danger p-3 me-2"
                          ><i class="bi bi-trash fs-3 me-2"></i
                          >{{
                            contents.data.page.accordion.buttons[1].label
                          }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--end::Content-->
                  <!--begin::Separator-->
                  <div class="separator separator-dashed"></div>
                  <!--end::Separator-->
                </div>
                <!--end::Accordion-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
          <!--end::Classic content-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::FAQ card-->
      <!--begin::Modals-->
      <create-update
        v-if="modalDisplay"
        :contents="contents.data.modal"
        :modalInfo="faqInfo"
        :firstErrorName="firstErrorName"
        @close-modal="setModalConfigs"
      ></create-update>
      <dialog-box
        v-if="dialogDisplay"
        :contents="contents.data.modal"
        :modalInfo="deleteInfo"
        @close-modal="setModalConfigs"
      >
      </dialog-box>
      <!--end::Modals-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  setup() {},
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      faqInfo: {},
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,
      faqs: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false, false],
    };
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.faqInfo = { ...this.faqs[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupFAQInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "delete":
          this.deleteInfo = {
            id: this.faqs[index].id,
            name: this.faqs[index].faqQuestion,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (index.delete) {
            this.deleteFAQ(index);
          } else {
            if (this.inputValidation(index)) {
              index.id ? this.updateFAQ(index) : this.createNewFAQ(index);
            } else {
              this.$store.commit("setLoader", false);
              
            }
          }
          break;
        default:
          this.resetFAQInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readFAQs(page)
    },
    loaderDone() {
      if (
        this.loaderStatus[0] &&
        this.loaderStatus[1] &&
        this.loaderStatus[2]
      ) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/faqs`,
        locale: `${this.routeLocale}`,
        data: {},
      };

      dataService
        .get(data)
        .then((res) => {
          
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
          this.readCategories();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupFAQInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.faqInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "textarea":
            this.faqInfo[this.contents.data.modal.fields[counter].name] = "";
            break;
          case "file":
            this.faqInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          case "imageInput":
            this.faqInfo[this.contents.data.modal.fields[counter].name] = [];
            break;
          default:
            break;
        }
      }
      this.faqInfo["status"] = true;
    },
    resetFAQInfo() {
      this.faqInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName = tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    createNewFAQ(obj) {
      let data = {
        endPoint: `faqs`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          category: obj["faqCategory"],
          question: obj["faqQuestion"],
          description: obj["faqDescription"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetFAQInfo();
          this.readFAQs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readFAQs(page) {
      let data = {
        endPoint: `faqs`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);
          this.faqs = res.data.data;
          this.faqs = this.faqs.reverse();
          if (page == 1) {
            
            this.totalPages = res.data.totalRes
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateFAQ(obj) {
      let data = {
        endPoint: `faqs/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          category: obj["faqCategory"],
          question: obj["faqQuestion"],
          description: obj["faqDescription"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetFAQInfo();
          this.readFAQs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    deleteFAQ(obj) {
      this.$store.commit("setLoader", true);
      
      let data = {
        endPoint: `faqs/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetFAQInfo();
          this.readFAQs();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readCategories() {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          let tempCategories = this.contents.data.modal.fields[0];
          tempCategories.options = [];
          for (let category in res.data.data) {
            tempCategories.options.push({
              title: res.data.data[category].categoryName,
              value: res.data.data[category].id,
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[2] = true;
          this.loaderDone();
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.getContents();
    this.readFAQs(1);
  },
  updated() {
    this.loaderDone();
  },
};
</script>
