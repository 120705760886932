<template>
  <div class="mt-2 px-3 ms-xl-4 d-flex justify-content-between">
    <a :href="`${backendHost}?locale=${currentLocale}`">
    <img
      :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${$store.state.logos.mainLogo}`"
      style="width: 175px"
    /></a>
    <div style="width: 100px">
      <multiselect
        class="bg-transparent"
        v-model="locale"
        :options="locales"
        :hideSelected="true"
        :canClear="false"
        label="locale"
        @select="setCurrentLocale"
      >
        <template v-slot:option="{ option }">
          <img
            style="width: 25px"
            class="me-2 rounded-circle"
            :src="`${backendHost}/images/svg/flags/${option.image}`"
          />
          {{ option.locale }}
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
// import dataService from "../../../services/data";
import Multiselect from "@vueform/multiselect";
import serverConfigs from "../../../configs/server.json";

export default {
  setup() {},
  components: {
    multiselect: Multiselect,
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentLocale() {
      return this.$route.params.locale;
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
  },
  data() {
    return {
      contents: {
        status: true,
      },
      locale: null,
      locales: [],
    };
  },
  methods: {
    setCurrentLocale() {
      localStorage.setItem("locale", this.locale);
      window.location = `/${this.locale}/${String(this.currentPath).substring(
        4
      )}`;
    },
    setLanguages() {
      if (this.locales.length == 0) {
        for (let d in this.$store.state.languages.value) {
          this.locales.push(this.$store.state.languages.value[d]);
          this.locales[d]["value"] =
            this.$store.state.languages.value[d].locale;
          if (
            this.$store.state.languages.value[d].locale == this.currentLocale
          ) {
            this.locale = this.$store.state.languages.value[d].locale;
            localStorage.setItem(
              "locale",
              this.$store.state.languages.value[d].locale
            );
          }
        }
      }
      // if (this.locale == null) {
      //   localStorage.setItem("locale", this.$store.state.languages.default);
      //   window.location = "/";
      // }
    },
  },
  mounted() {
    // this.getLanguages();
  },
  updated() {
    this.setLanguages();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>