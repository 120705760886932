<template>
  <div v-if="contents.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Hero card-->
      <div class="card mb-12 shadow-sm">
        <!--begin::Hero body-->
        <div class="card-body flex-column p-5 mb-5">
          <!--begin::Hero content-->
          <div
            class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center h-lg-300px p-5 p-lg-15 overlay-wrapper gap-10"
          >
            <!--begin::Wrapper-->
            <div
              class="d-flex flex-column align-items-start justift-content-center flex-equal me-5 order-1 order-md-0"
            >
              <!--begin::Title-->
              <h1 class="fw-bolder fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-5">
                {{ contents.data.page.locale.title }}
              </h1>
              <!--end::Title-->
              <!--begin::Input group-->
              <div class="position-relative w-100 mb-5 mb-lg-10">
                <span class="text-gray-600 fs-6">
                  {{ contents.data.page.locale.subTitle }}
                </span>
              </div>
              <!--end::Input group-->
              <!--begin::Action-->
              <a
                href="#"
                class="btn btn-primary fw-bolder fs-5"
                @click="setModalConfigs('create')"
                ><i
                  class="bi bi-plus-circle me-2 fs-4"
                  :class="contents.data.page.setting.buttonIcon"
                ></i
                >{{ contents.data.page.locale.buttonLabel }}</a
              >
              <!--end::Action-->
            </div>
            <!--end::Wrapper-->
            <!--begin::Wrapper-->
            <div
              class="flex-equal d-flex align-self-center justify-content-end align-items-center order-0 order-md-1"
            >
              <!--begin::Illustration-->
              <img
                :src="`${backendHost}/images/themes/${contentTheme}/${
                  $store.state.sidebar[contents.name].routeImage
                }`"
                alt=""
                class="mw-100 min-h-100px mh-md-125px mh-lg-225px"
              />
              <!--end::Illustration-->
            </div>
            <!--end::Wrapper-->
          </div>
          <!--end::Hero content-->
        </div>
        <!--end::Hero body-->
      </div>
      <!--end::Hero card-->
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div v-if="dataIsEmpty" class="card-body p-20">
          <div class="d-flex flex-center">
            <img
              class="col-md-3"
              :src="`/assets/images/svg/no_data.svg`"
              alt=""
            />
          </div>
        </div>
        <div v-else class="card-body pt-0">
          <!--begin::Table-->
          <div class="d-flex flex-column scroll-y me-n7 pe-7">
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_table_user"
            >
              <!--begin::Table head-->
              <thead>
                <!--begin::Table row-->
                <tr
                  class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0"
                >
                  <!-- <th class="w-10px pe-2">
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_table_users .form-check-input"
                      value="1"
                    />
                  </div>
                </th> -->
                  <th
                    v-for="(header, index) in contents.data.page.table.header"
                    :key="index"
                    :class="header.class"
                  >
                    {{ header.title }}
                  </th>
                </tr>
                <!--end::Table row-->
              </thead>
              <!--end::Table head-->
              <!--begin::Table body-->
              <tbody class="text-gray-600 fw-bold">
                <!--begin::Table row-->
                <tr v-for="(category, index) in categories" :key="index">
                  <!--begin::Checkbox-->
                  <!-- <td>
                  <div
                    class="form-check form-check-sm form-check-custom form-check-solid"
                  >
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </td> -->
                  <!--end::Checkbox-->
                  <!--begin::Role=-->
                  <td>{{ category.categoryName }}</td>
                  <!--end::Role=-->
                  <!--begin::Last login=-->
                  <td>
                    {{ category.categoryDate }}
                  </td>
                  <!--end::Last login=-->
                  <!--begin::Joined-->
                  <td>{{ category.categoryDescription }}</td>
                  <!--begin::Joined-->
                  <!--begin::Action=-->
                  <td>
                    <div class="d-flex justify-content-end flex-shrink-0">
                      <a
                        href="#"
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        @click="setModalConfigs('update', index)"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </a>
                      <a
                        href="#"
                        class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                        @click="setModalConfigs('delete', index)"
                      >
                        <!--begin::Svg Icon | path: icons/duotune/general/gen027.svg-->
                        <span class="svg-icon svg-icon-3">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z"
                              fill="currentColor"
                            ></path>
                            <path
                              opacity="0.5"
                              d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z"
                              fill="currentColor"
                            ></path>
                            <path
                              opacity="0.5"
                              d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                        <!--end::Svg Icon-->
                      </a>
                    </div>
                  </td>
                  <!--end::Action=-->
                </tr>
                <!--end::Table row-->
              </tbody>
              <!--end::Table body-->
            </table>
          </div>
          <div v-if="totalPages > 1" class="w-100 text-center mt-5">
          <pagination
          :pages="totalPages"
          v-model="currentPage"
          @update:modelValue="setCurrentPage"
          ></pagination>
        </div>
          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="categoryInfo"
      :firstErrorName="firstErrorName"
      :modalType="null"
      @close-modal="setModalConfigs"
    ></create-update>
    <dialog-box
      v-if="dialogDisplay"
      :contents="contents.data.modal"
      :modalInfo="deleteInfo"
      @close-modal="setModalConfigs"
    >
    </dialog-box>
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";

// Tools
import Pagination from "../../tools/Pagination.vue";

import CreateUpdate from "../../modals/CreateUpdate.vue";
import DialogBox from "../../modals/DialogBox.vue";

export default {
  components: {
    Pagination,
    CreateUpdate,
    DialogBox,
  },
  computed: {
    contentTheme() {
      return this.$store.state.themeFile.replace(".css", "");
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  data() {
    return {
      totalPages: 0,
      currentPage: 1,
      userInfo: Object,
      deleteInfo: {},
      modalDisplay: false,
      dialogDisplay: false,
      dataIsEmpty: true,

      categoryInfo: {},
      categories: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  methods: {
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.categoryInfo = { ...this.categories[index], status: false };
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "create":
          this.setupCategoryInfo();
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "delete":
          this.deleteInfo = {
            id: this.categories[index].id,
            name: this.categories[index].categoryName,
            status: false,
            delete: true,
          };
          this.dialogDisplay = !this.dialogDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          if (index.delete) {
            this.deleteCategories(index);
          } else {
            if (this.inputValidation(index)) {
              index.id
                ? this.updateCategories(index)
                : this.createCategories(index);
            } else {
              this.$store.commit("setLoader", false);
              
            }
          }
          break;
        default:
          this.resetCategoryInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    setCurrentPage(page) {
      
      this.$store.commit("setLoader", true);
      this.readCategories(page)
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/categories`,
        locale: `${this.routeLocale}`,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setupCategoryInfo() {
      for (let counter in this.contents.data.modal.fields) {
        switch (this.contents.data.modal.fields[counter].type) {
          case "text":
            this.categoryInfo[this.contents.data.modal.fields[counter].name] =
              "";
            break;
          case "combobox":
            this.categoryInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "file":
            this.categoryInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          case "imageInput":
            this.categoryInfo[this.contents.data.modal.fields[counter].name] =
              [];
            break;
          default:
            break;
        }
      }
      this.userInfo["status"] = true;
    },
    resetCategoryInfo() {
      this.categoryInfo = {};
      this.modalDisplay = false;
      this.dialogDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName = tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    createCategories(obj) {
      let data = {
        endPoint: `categories`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          name: obj["categoryName"],
          description: obj["categoryDescription"],
          parent: null,
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetCategoryInfo();
          this.readCategories();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    readCategories(page) {
      let data = {
        endPoint: `categories`,
        pageNo: page,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          res.data.data.length > 0
            ? (this.dataIsEmpty = false)
            : (this.dataIsEmpty = true);

          this.loaderStatus[1] = true;
          for (let d in res.data.data) {
            res.data.data[d].categoryDate = new Date(
              res.data.data[d].categoryDate
            ).toLocaleDateString(this.routeLocale, {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          }
          if (page == 1) {
            this.totalPages = res.data.totalRes
          }
          this.categories = res.data.data;
          this.categories = this.categories.reverse();
        })
        .catch((error) => {
          this.$store.commit("setLoader", false);
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateCategories(obj) {
      let data = {
        endPoint: `categories/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          name: obj["categoryName"],
          description: obj["categoryDescription"],
          parent: null,
          status: obj.categoryStatus,
        },
      };
      this.$store.commit("setLoader", true);
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetCategoryInfo();
          this.readCategories();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
    deleteCategories(obj) {
      this.$store.commit("setLoader", true);
      
      let data = {
        endPoint: `categories/d/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          this.resetCategoryInfo();
          this.readCategories();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.readCategories(1);
    this.getContents();
  },
};
</script>
