import server from "./server.json";
import axios from "axios";

export default axios.create({
  baseURL: `${server["serverDomain"]["host"]}/api`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Methods": "GET, OPTIONS, POST, PUT",
    "Access-Control-Allow-Headers": "X-Token",
    "Access-Control-Allow-Origin": `*`,
    projectdomain: `${server["projectdomain"]}`,
  },
});