<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" :class="contents.size">
      <div class="modal-content rounded">
        <div class="modal-header border-gray-300 shadow-sm">
          <h2 class="fw-bolder">
            {{ modalInfo.status ? contents.createTitle : contents.updateTitle }}
            {{ contents.title }}
          </h2>
          <!--begin::Description-->
          <!-- <div class="text-gray-400 fw-bold fs-5">
            {{ contents.subTitle.length ? contents.subTitle : "" }}
            <a href="#" class="fw-bolder link-primary">{{
              contents.subTitleLink.length ? contents.subTitleLink : ""
            }}</a>
          </div> -->
          <!--end::Description-->
          <div
            class="btn btn-icon btn-sm btn-active-icon-primary"
            @click="closeModal"
          >
            <span class="svg-icon svg-icon-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="black"
                />
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="black"
                />
              </svg>
            </span>
          </div>
        </div>
        <div
          class="modal-body scroll-y mx-lg-5 my-7"
          :class="
            contents.size != 'modal-fullscreen' || !contents.size
              ? 'mh-50vh'
              : ''
          "
        >
          <form
            v-if="displayStatus"
            id="kt_modal_add_role_form"
            class="form"
            action="#"
          >
            <!--begin::Scroll-->
            <div
              class="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_role_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_role_header"
              data-kt-scroll-wrappers="#kt_modal_add_role_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div
                v-for="(field, index) in contents.fields ||
                contents.elements[0].fields"
                :key="index"
                class="fv-row mb-10"
              >
                <div
                  v-if="
                    (!modalInfo.status && field.editable) ||
                    (field.creatable != undefined
                      ? field.creatable && modalInfo.status
                      : modalInfo.status)
                  "
                >
                  <div
                    :ref="field.name"
                    v-if="
                      field.type !== 'card' &&
                      field.type !== 'email' &&
                      field.type !== 'ticket'
                    "
                    class="d-flex justify-content-between"
                  >
                    <label
                      v-if="field.title.length > 0"
                      class="fs-5 fw-bolder form-label mb-2"
                    >
                      <span :class="field.required ? 'required' : ''">{{
                        field.title
                      }}</span>
                    </label>
                    <div
                      v-if="
                        info[field.name] &&
                        info[field.name].length &&
                        field.maxLength &&
                        field.maxLength !== -1
                      "
                      class="d-flex justify-content-end text-secondary"
                    >
                      {{ info[field.name].length }} / {{ field.maxLength }}
                    </div>
                    <div
                      v-else-if="field.maxTags && field.maxTags !== -1"
                      class="d-flex justify-content-end text-secondary"
                    >
                      0 / {{ field.maxLength }}
                    </div>
                  </div>

                  <div :tabindex="index" v-if="field.type == 'text'">
                    <input
                      :ref="`${field.name}_Input`"
                      :minlength="field.minLength"
                      :maxlength="field.maxLength"
                      class="form-control form-control-solid p-4"
                      :class="`${field.class}
                        ${
                          field.required && field.errorCode
                            ? 'wrong-inputs'
                            : ''
                        }`"
                      :disabled="field.disabled ? true : false"
                      aria-describedby="validationErrorFeedback"
                      :type="field.type"
                      :placeholder="`${field.placeholder}`"
                      v-model="info[field.name]"
                    />
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'combobox'">
                    <multiselect
                      mode="single"
                      :canDeselect="false"
                      :hideSelected="true"
                      @click="setOfset(field)"
                      class="form-control form-control-solid p-5"
                      :class="
                        field.required && field.errorCode ? 'wrong-inputs' : ''
                      "
                      :placeholder="field.placeholder"
                      label="title"
                      v-model="info[field.name]"
                      :searchable="true"
                      :options="field.options"
                    >
                      <template v-slot:option="{ option }">
                        {{ option.title }}
                      </template>
                    </multiselect>
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'template'">
                    <!--begin::Radio group-->
                    <div
                      class="d-flex row-cols-auto gap-5 gap-lg-10 flex-wrap"
                      data-kt-buttons="true"
                    >
                      <!--begin::Radio button-->
                      <label
                        v-for="(option, index) in field.options"
                        :key="index"
                        :class="index == 0 ? 'active' : ''"
                        class="btn btn-outline btn-outline-dashed d-flex text-start p-6"
                      >
                        <!--end::Description-->
                        <div class="d-flex align-items-center me-2">
                          <!--begin::Radio-->
                          <div
                            class="form-check form-check-custom form-check-solid form-check-primary me-6"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="plan"
                              @click="setSelectedUpload(option.value)"
                            />
                          </div>
                          <!--end::Radio-->

                          <!--begin::Info-->
                          <div class="">
                            <img
                              :src="`${backendHost}/images/preview/${option.parse}`"
                              class="h-75px w-100px rounded"
                              style="object-fit: cover"
                              alt=""
                            />
                          </div>
                          <!--end::Info-->
                        </div>
                        <!--end::Description-->
                      </label>
                      <!--end::Radio button-->
                    </div>
                    <!--end::Radio group-->
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div
                    :ref="field.name"
                    :tabindex="index"
                    v-else-if="field.type == 'multiselect'"
                  >
                    <div
                      v-if="
                        info[field.name] &&
                        info[field.name].length &&
                        field.maxTags !== -1
                      "
                      class="d-flex justify-content-end text-secondary"
                    >
                      {{ info[field.name].length }} / {{ field.maxTags }}
                    </div>
                    <div
                      v-else-if="field.maxTags !== -1"
                      class="d-flex justify-content-end text-secondary"
                    >
                      0 / {{ field.maxTags }}
                    </div>
                    <multiselect
                      class="form-control form-control-solid"
                      :class="
                        field.required && field.errorCode ? 'wrong-inputs' : ''
                      "
                      v-model="info[field.name]"
                      :placeholder="field.placeholder"
                      mode="tags"
                      :max="field.maxTags"
                      label="title"
                      :close-on-select="false"
                      :searchable="true"
                      :options="field.options"
                    />
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'file'">
                    <div
                      v-if="!modalInfo.status"
                      class="symbol symbol-100px symbol-circle mb-7"
                    >
                      <img
                        :src="
                          info[field.name]
                            ? `${backendHost}/uploads/${
                                $store.state.uploadsPath
                              }/${info[field.name]}`
                            : `${backendHost}/uploads/${$store.state.uploadsPath}/1616284800000_Avatar.png`
                        "
                        alt="image"
                        id="photoPreview"
                      />
                    </div>
                    <div v-else id="photoPreview" class="d-none"></div>
                    <!--begin::Notice-->
                    <div
                      class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6"
                    >
                      <!--begin::Icon-->
                      <!--begin::Svg Icon | -->
                      <i
                        class="bi bi-file-earmark-arrow-up text-primary fs-3x me-2"
                      ></i>
                      <!--end::Svg Icon-->
                      <!--end::Icon-->
                      <!--begin::Wrapper-->
                      <div class="d-flex flex-stack flex-grow-1">
                        <!--begin::Content-->
                        <form
                          class="form"
                          :class="
                            field.required && field.errorCode
                              ? 'wrong-inputs'
                              : ''
                          "
                          action="#"
                          method="post"
                        >
                          <!--begin::Input group-->
                          <div class="form-group row">
                            <!--begin::Col-->
                            <div class="col-12">
                              <!--begin::Dropzone-->
                              <div
                                class="dropzone dropzone-queue mb-2"
                                id="kt_dropzonejs_example_2"
                              >
                                <!--begin::Controls-->
                                <div class="d-flex">
                                  <div class="dropzone-panel mb-lg-0 mb-2">
                                    <a
                                      class="dropzone-select btn btn-sm btn-primary me-2"
                                      >الصاق فایل</a
                                    >
                                    <a
                                      class="dropzone-upload btn btn-sm btn-light-primary me-2"
                                      >بارگذاری همه</a
                                    >
                                    <a
                                      class="dropzone-remove-all btn btn-sm btn-light-primary me-2"
                                      >حذف همه</a
                                    >
                                  </div>
                                  <a
                                    class="dropzone-photo-delete btn btn-sm btn-danger"
                                    :class="modalInfo.status ? 'd-none' : ''"
                                    @click="deleteFilePhoto(field.name)"
                                    >حذف</a
                                  >
                                </div>
                                <!--end::Controls-->

                                <!--begin::Items-->
                                <div
                                  class="dropzone-items d-flex flex-wrap w-100 gap-2"
                                >
                                  <div
                                    class="dropzone-item bg-light border border-primary border-dashed w-150px"
                                    style="display: none"
                                  >
                                    <!--begin::File-->
                                    <div class="dropzone-file w-100 mt-5">
                                      <div
                                        class="image-input"
                                        data-kt-image-input="true"
                                      >
                                        <div class="image-input-wrapper">
                                          <img
                                            data-dz-thumbnail=""
                                            class="dz-image"
                                            alt=""
                                          />
                                        </div>
                                        <!--begin::Edit button-->
                                        <label
                                          class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow dropzone-delete"
                                          data-dz-remove
                                          data-kt-image-input-action="change"
                                          data-bs-toggle="tooltip"
                                          data-bs-dismiss="click"
                                        >
                                          <i class="bi bi-trash-fill fs-7"></i>
                                        </label>
                                        <!--end::Edit button-->
                                      </div>
                                      <div
                                        class="dropzone-filename d-flex"
                                        title="some_image_file_name.jpg"
                                      >
                                        <div class="w-50 turncating">
                                          <span data-dz-name
                                            >some_image_file_name.jpg</span
                                          >
                                        </div>
                                        <strong class="W-50"
                                          >(<span data-dz-size>340kb</span
                                          >)</strong
                                        >
                                      </div>
                                      <div
                                        class="dropzone-error"
                                        data-dz-errormessage
                                      ></div>
                                    </div>
                                    <!--end::File-->

                                    <!--begin::Progress-->
                                    <div class="dropzone-progress">
                                      <div class="progress">
                                        <div
                                          class="progress-bar bg-primary"
                                          role="progressbar"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          aria-valuenow="0"
                                          data-dz-uploadprogress
                                        ></div>
                                      </div>
                                    </div>
                                    <!--end::Progress-->

                                    <!--begin::Toolbar-->
                                    <div class="dropzone-toolbar">
                                      <span class="dropzone-start"
                                        ><i class="bi bi-play-fill fs-3"></i
                                      ></span>
                                      <span
                                        class="dropzone-cancel"
                                        data-dz-remove
                                        style="display: none"
                                        ><i class="bi bi-x fs-3"></i
                                      ></span>
                                    </div>
                                    <!--end::Toolbar-->
                                  </div>
                                </div>
                                <!--end::Items-->
                              </div>
                              <!--end::Dropzone-->

                              <!--begin::Hint-->
                              <span class="form-text text-muted"
                                >Max file size is 1MB and max number of files is
                                5.</span
                              >
                              <!--end::Hint-->
                            </div>
                            <!--end::Col-->
                          </div>
                          <!--end::Input group-->
                        </form>
                        <!--end::Content-->
                      </div>
                      <!--end::Wrapper-->
                    </div>
                    <!--end::Notice-->
                    <!-- <div
                      v-if="!modalInfo.status"
                      class="symbol symbol-100px symbol-circle mb-7"
                    >
                      <img
                        :src="`${backendHost}/uploads/${$store.state.uploadsPath}/1616284800000_Avatar.png`"
                        alt="image"
                      />
                    </div> -->
                    <!--begin::Form-->
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                    <div>
                      <input
                        class="d-none"
                        id="dropzoneFiles"
                        type="text"
                        value=""
                      />
                    </div>
                    <!--end::Form-->
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'imageInput'">
                    <!--begin::Form-->
                    <form
                      class="form"
                      :class="
                        field.required && field.errorCode ? 'wrong-inputs' : ''
                      "
                      action="#"
                      method="post"
                    >
                      <!--begin::Hint-->
                      <div class="form-text">{{ field.hint }}</div>
                      <!--end::Hint-->
                      <!--begin::Input group-->
                      <div class="form-group row">
                        <!--begin::Col-->
                        <div class="col-lg-12">
                          <!--begin::Dropzone-->
                          <div
                            class="dropzone dropzone-queue mb-3"
                            id="kt_dropzonejs_example_2"
                          >
                            <!--begin::Controls-->
                            <div class="dropzone-panel mb-lg-0 mb-2">
                              <!--begin::Image input-->
                              <div class="d-flex justify-content-start ms-5">
                                <div
                                  class="image-input image-input-outline"
                                  data-kt-image-input="true"
                                  style="
                                    background-image: url(assets/images/png/blank-profile.png);
                                  "
                                >
                                  <!--begin::Preview existing avatar-->
                                  <div
                                    class="image-input-wrapper w-125px h-125px"
                                    :style="`background-image: url(${backendHost}/uploads/${
                                      $store.state.uploadsPath
                                    }/${info[field.name]});`"
                                  ></div>
                                  <!--end::Preview existing avatar-->
                                  <!--begin::Label-->
                                  <label
                                    class="dropzone-select btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                    data-kt-image-input-action="change"
                                    data-bs-toggle="tooltip"
                                    title="تغییر آواتار"
                                  >
                                    <i class="bi bi-pencil-fill fs-7"></i>
                                  </label>
                                  <!--end::Label-->
                                </div>
                              </div>
                              <!--end::Image input-->
                              <a
                                class="dropzone-upload btn btn-sm btn-light-primary me-2"
                                >بارگذاری همه</a
                              >
                              <a
                                class="dropzone-remove-all btn btn-sm btn-light-primary"
                                >حذف همه</a
                              >
                            </div>
                            <!--end::Controls-->

                            <!--begin::Items-->
                            <div class="dropzone-items wm-200px">
                              <div class="dropzone-item" style="display: none">
                                <!--begin::File-->
                                <div class="dropzone-file">
                                  <div
                                    class="dropzone-filename"
                                    title="some_image_file_name.jpg"
                                  >
                                    <span data-dz-name
                                      >some_image_file_name.jpg</span
                                    >
                                    <strong
                                      >(<span data-dz-size>340kb</span>)</strong
                                    >
                                  </div>

                                  <div
                                    class="dropzone-error"
                                    data-dz-errormessage
                                  ></div>
                                </div>
                                <!--end::File-->

                                <!--begin::Progress-->
                                <div class="dropzone-progress">
                                  <div class="progress">
                                    <div
                                      class="progress-bar bg-primary"
                                      role="progressbar"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                      aria-valuenow="0"
                                      data-dz-uploadprogress
                                    ></div>
                                  </div>
                                </div>
                                <!--end::Progress-->

                                <!--begin::Toolbar-->
                                <div class="dropzone-toolbar">
                                  <span class="dropzone-start"
                                    ><i class="bi bi-play-fill fs-3"></i
                                  ></span>
                                  <span
                                    class="dropzone-cancel"
                                    data-dz-remove
                                    style="display: none"
                                    ><i class="bi bi-x fs-3"></i
                                  ></span>
                                  <span class="dropzone-delete" data-dz-remove
                                    ><i class="bi bi-x fs-1"></i
                                  ></span>
                                </div>
                                <!--end::Toolbar-->
                              </div>
                            </div>
                            <!--end::Items-->
                          </div>
                          <!--end::Dropzone-->

                          <!--begin::Hint-->
                          <span class="form-text text-muted ms-5"
                            >حداکثر حجم فایل انتخابی 1 مگابایت می‌باشد</span
                          >
                          <!--end::Hint-->
                        </div>
                        <!--end::Col-->
                      </div>
                      <!--end::Input group-->
                    </form>
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                    <div>
                      <input
                        class="d-none"
                        id="dropzoneFiles"
                        type="text"
                        value=""
                      />
                    </div>
                    <!--end::Form-->
                  </div>
                  <div
                    :tabindex="index"
                    v-else-if="field.type == 'textarea'"
                    :class="
                      field.required && field.errorCode ? 'wrong-inputs' : ''
                    "
                  >
                    <editor
                      v-model="info[field.name]"
                      api-key="no-api-key"
                      :init="{
                        menubar: field.menubar,
                        plugins: field.plugins,
                        directionality: field.directionality,
                        toolbar: field.toolbar,
                        height: 200,
                      }"
                    />
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'date'">
                    <!-- input-format="MM/DD/YYYY" -->
                    <date-picker
                      class="form-control form-control-solid form-control-lg border-0"
                      :auto-submit="true"
                      :locale="$route.params.locale"
                      :input-format="
                        $route.params.locale == 'fa'
                          ? 'jYYYY/jMM/jDD'
                          : 'YYYY/MM/DD'
                      "
                      :format="
                        $route.params.locale == 'fa'
                          ? 'jYYYY/jMM/jDD'
                          : 'YYYY/MM/DD'
                      "
                      color="#9277e4"
                      v-model="info[field.name]"
                    ></date-picker>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'selectFile'">
                    <div
                      @click="
                        setModalConfigs('choose', {
                          root: field.name,
                          depth: 1,
                        })
                      "
                      class="fv-row"
                    >
                      <!--begin::Dropzone-->
                      <div
                        class="dropzone bg-light-primary rounded border-primary border border-dashed"
                        id="kt_dropzonejs_example_1"
                      >
                        <!--begin::Message-->
                        <div
                          v-if="
                            (info.photos && info.photos.length > 0) ||
                            (info[field.name] && info[field.name].length > 0)
                          "
                          class="d-flex flex-center flex-wrap scroll-y mh-200px"
                        >
                          <div
                            v-for="photo in info.photos || info[field.name]"
                            :key="photo"
                            class="d-flex flex-column flex-center bg-dark bg-opacity-5 rounded border border-dashed border-primary p-5 m-2 col-6 col-md-3 col-lg-2"
                          >
                            <div class="symbol symbol-100px">
                              <img
                                :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else class="dz-message needsclick">
                          <!--begin::Icon-->
                          <i
                            class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                          ></i>
                          <!--end::Icon-->

                          <!--begin::Info-->
                          <div class="ms-4">
                            <h3 class="fs-5 fw-bolder text-gray-900 mb-1">
                              Click here to upload an image
                            </h3>
                            <span class="fs-7 fw-bold text-gray-400"
                              >Upload up to 10 files</span
                            >
                          </div>
                          <!--end::Info-->
                        </div>
                      </div>
                      <!--end::Dropzone-->
                    </div>
                    <!-- <a
                      class="btn btn-primary btn-shadow ms-2"
                      :class="
                        field.required && field.errorCode ? 'wrong-inputs' : ''
                      "
                      @click="setModalConfigs('choose')"
                    >
                      انتخاب عکس
                    </a> -->
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'component'">
                    <div v-if="templateIndex !== ''">
                      <div
                        v-for="(f, index) in info['blogBody'][
                          `${templateIndex}`
                        ].fields"
                        :key="index"
                        class="fv-row mb-8"
                      >
                        <!--begin::Label-->
                        <label
                          :ref="f.name"
                          class="d-flex align-items-center fs-6 fw-bold mb-2"
                        >
                          <span class="required">{{ f.title }}</span>
                          <i
                            class="bi bi-exclamation-circle ms-2 fs-7"
                            data-bs-toggle="tooltip"
                            title="Select your post image"
                          ></i>
                        </label>
                        <!--end::Label-->

                        <div v-if="f.type == 'selectFile'">
                          <!--begin::Input group-->
                          <div
                            @click="setModalConfigs('choose')"
                            class="fv-row"
                          >
                            <!--begin::Dropzone-->
                            <div
                              class="dropzone dropzone__bg"
                              :class="
                                f.required && f.errorCode ? 'wrong-inputs' : ''
                              "
                              id="kt_dropzonejs_example_1"
                            >
                              <!--begin::Message-->
                              <div
                                v-if="info.photos"
                                class="shadow symbol symbol-100px card mb-7"
                              >
                                <img
                                  v-for="photo in info.photos"
                                  :key="photo"
                                  :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                                />
                              </div>
                              <div v-else class="dz-message needsclick">
                                <!--begin::Icon-->
                                <i
                                  class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                                ></i>
                                <!--end::Icon-->

                                <!--begin::Info-->
                                <div class="ms-4">
                                  <h3 class="fs-5 fw-bolder text-gray-900 mb-1">
                                    Drop files here or click to upload.
                                  </h3>
                                  <span class="fs-7 fw-bold text-gray-400"
                                    >Upload up to 10 files</span
                                  >
                                </div>
                                <!--end::Info-->
                              </div>
                            </div>
                            <!--end::Dropzone-->
                          </div>
                          <!--end::Input group-->
                          <!-- <a
                            class="btn btn-primary btn-shadow ms-2"
                            :class="
                              f.required && f.errorCode ? 'wrong-inputs' : ''
                            "
                            @click="setModalConfigs('choose')"
                          >
                            انتخاب عکس
                          </a> -->
                          <div
                            v-if="f.required && f.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ f.validation[f.errorCode - 1].error }}
                          </div>
                        </div>
                        <div v-else-if="f.type == 'text'">
                          <input
                            :ref="`${f.name}_Input`"
                            :placeholder="f.placeholder"
                            v-model="f.value"
                            id="postTitle"
                            type="text"
                            class="form-control form-control-solid"
                            :class="
                              f.required && f.errorCode ? 'wrong-inputs' : ''
                            "
                            name="subject"
                          />
                          <div
                            v-if="f.required && f.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ f.validation[f.errorCode - 1].error }}
                          </div>
                        </div>
                        <div
                          v-else-if="f.type == 'textarea'"
                          :class="
                            f.required && f.errorCode ? 'wrong-inputs' : ''
                          "
                        >
                          <editor
                            v-model="f.value"
                            api-key="no-api-key"
                            :init="{
                              menubar: true,
                              plugins: 'lists link emoticons directionality',
                              directionality:
                                info['blogLocale'] == 'fa' ? 'rtl' : 'ltr',
                              toolbar:
                                'styleselect | bold italic | alignleft aligncenter alignright alignjustify | ltr rtl | bullist numlist | link emoticons',
                              height: 200,
                            }"
                          />
                          <div
                            v-if="f.required && f.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ f.validation[f.errorCode - 1].error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="min-h-50px d-flex justify-content-center align-items-center text-danger fw-bold fs-2 text-center border-dotted"
                    >
                      Choose a template
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'countdown'">
                    <label
                      v-if="verifyTime"
                      class="form-label text-muted __bottom__label"
                      >{{ field.title.before }}</label
                    >
                    <label v-else class="form-label text-muted __bottom__label">
                      <a
                        href="#"
                        class="__text__primary text-decoration-none"
                        @click="closeModal('timer')"
                        >{{ field.title.after }}</a
                      ></label
                    >

                    <span class="ms-2 __text__primary fs-5">{{
                      countDown
                    }}</span>
                  </div>
                  <div
                    id="chapterAccordion"
                    :tabindex="index"
                    v-if="field.type == 'card'"
                  >
                    <div
                      v-for="(subField, index) in info[field.name].length"
                      :key="subField"
                      class="card border border-gray-300 mb-5"
                    >
                      <div class="card-header">
                        <div
                          class="card-title collapsible cursor-pointer text-hover-primary rotate"
                          :class="index != 0 ? 'collapsed' : ''"
                          data-bs-toggle="collapse"
                          :data-bs-target="`#kt_${field.name}_collapsible_${index}`"
                        >
                          <span
                            v-if="field.title.length > 0"
                            class="fs-2 fw-bolder"
                            >{{ field.title }} {{ index + 1 }}</span
                          >
                        </div>
                        <div class="card-toolbar gap-3">
                          <a
                            href="#"
                            @click="
                              addChild(
                                field.name,
                                subField,
                                null,
                                field.value,
                                field
                              )
                            "
                            class="btn btn-sm btn-icon btn-light-primary"
                            ><i class="bi bi-folder-plus fs-3"></i
                          ></a>
                          <a
                            href="#"
                            @click="delChild(field.name, subField, null)"
                            class="btn btn-sm btn-icon btn-light-danger"
                            ><i class="bi bi-trash fs-3"></i
                          ></a>
                          <div
                            class="btn btn-sm btn-icon btn-light collapsible cursor-pointer rotate"
                            :class="index != 0 ? 'collapsed' : ''"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#kt_${field.name}_collapsible_${index}`"
                          >
                            <div class="rotate-180">
                              <span class="svg-icon svg-icon-1">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        :id="`kt_${field.name}_collapsible_${index}`"
                        data-bs-parent="#chapterAccordion"
                        class="collapse"
                        :class="index == 0 ? 'show' : ''"
                      >
                        <div class="card-body">
                          <div>
                            <div
                              v-for="(item, i) in field.fields"
                              :key="i"
                              class="fv-row mb-10"
                            >
                              <div v-if="item.editable || modalInfo.status">
                                <div
                                  v-if="item.type !== 'card'"
                                  class="d-flex justify-content-between"
                                >
                                  <label
                                    v-if="item.title.length > 0"
                                    class="fs-5 fw-bolder form-label mb-2"
                                  >
                                    <span
                                      :class="item.required ? 'required' : ''"
                                      >{{ item.title }} {{ index + 1 }}</span
                                    >
                                  </label>
                                  <div
                                    v-if="
                                      info[field.name][item.name] &&
                                      info[field.name][item.name].length &&
                                      item.maxLength &&
                                      item.maxLength !== -1
                                    "
                                    class="d-flex justify-content-end text-secondary"
                                  >
                                    {{
                                      info[`${field.name}_${item.name}`].length
                                    }}
                                    /
                                    {{ item.maxLength }}
                                  </div>
                                  <div
                                    v-else-if="
                                      item.maxTags && item.maxTags !== -1
                                    "
                                    class="d-flex justify-content-end text-secondary"
                                  >
                                    0 / {{ item.maxLength }}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    :tabindex="index"
                                    v-if="item.type == 'text'"
                                  >
                                    {{ item.errorCode }}
                                    <input
                                      :ref="`${item.name}_Input`"
                                      :minlength="item.minLength"
                                      :maxlength="item.maxLength"
                                      class="form-control form-control-solid p-4"
                                      :class="
                                        item.required && item.errorCode
                                          ? 'wrong-inputs'
                                          : ''
                                      "
                                      :disabled="item.disabled ? true : false"
                                      aria-describedby="validationErrorFeedback"
                                      :type="item.type"
                                      :placeholder="`${item.placeholder}`"
                                      v-model="
                                        info[field.name][subField - 1][
                                          item.name
                                        ]
                                      "
                                    />
                                    <div
                                      v-if="item.required && item.errorCode > 0"
                                      id="validationErrorFeedback"
                                      class="invalid-feedback d-block"
                                    >
                                      {{
                                        item.validation[item.errorCode - 1]
                                          .error
                                      }}
                                    </div>
                                  </div>
                                  <div
                                    :tabindex="index"
                                    v-else-if="item.type == 'icon'"
                                  >
                                    {{ item.errorCode }}
                                    <multiselect
                                    :close-on-select="true"
                                      :filter-results="false"
                                      :min-chars="0"
                                      :resolve-on-load="true"
                                      :infinite="true"
                                      :limit="10"
                                      :clear-on-search="true"
                                      :delay="0"
                                      mode="single"
                                      :canDeselect="false"
                                      :hideSelected="true"
                                      class="form-control form-control-solid p-5"
                                      :class="
                                        item.required && item.errorCode
                                          ? 'wrong-inputs'
                                          : ''
                                      "
                                      :placeholder="item.placeholder"
                                      label="title"
                                      v-model="info[field.name][subField - 1][
                                          item.name
                                        ]"
                                      :searchable="true"
                                      :options="
                                        async (query, i, r) => {
                                          return await fetchIcons(query);
                                        }
                                      "
                                      @open="
                                        (select$) => {
                                          if (select$.noOptions) {
                                            select$.resolveOptions();
                                          }
                                        }
                                      "
                                    >
                                      <template v-slot:singlelabel="{ value }">
                                        <div class="multiselect-single-label">
                                          <i class="me-2" :class="value.value"></i>
                                          {{ value.title }}
                                        </div>
                                      </template>
                                      <template v-slot:option="{ option }">
                                        <i class="me-2" :class="option.value"></i>
                                        {{ option.title }}
                                      </template>
                                    </multiselect>
                                    <div
                                      v-if="item.required && item.errorCode > 0"
                                      id="validationErrorFeedback"
                                      class="invalid-feedback d-block"
                                    >
                                      {{
                                        item.validation[item.errorCode - 1]
                                          .error
                                      }}
                                    </div>
                                  </div>
                                  <div
                                    :id="`sessionAccordion_${index}`"
                                    :tabindex="index"
                                    v-else-if="item.type == 'card'"
                                  >
                                    <div
                                      v-for="(subItem, iii) in info[field.name][
                                        subField - 1
                                      ][item.name].length"
                                      :key="subItem"
                                      class="card border mb-5"
                                    >
                                      <div class="card-header">
                                        <div
                                          @click="
                                            nestedAccordion(
                                              `sessionAccordion_${index}`,
                                              `#kt_${item.title}_${index}_collapsible_${iii}`
                                            )
                                          "
                                          class="card-title collapsible cursor-pointer text-hover-primary rotate"
                                          :class="iii != 0 ? 'collapsed' : ''"
                                          data-bs-toggle="collapse"
                                          :data-bs-target="`#kt_${item.title}_${index}_collapsible_${iii}`"
                                        >
                                          <span
                                            v-if="item.title.length > 0"
                                            class="fs-2 fw-bolder"
                                            >{{ item.title }} {{ index + 1 }}.{{
                                              iii + 1
                                            }}</span
                                          >
                                        </div>
                                        <div class="card-toolbar gap-3">
                                          <a
                                            href="#"
                                            @click="
                                              addChild(
                                                field.name,
                                                subField - 1,
                                                item.name,
                                                item.value
                                              )
                                            "
                                            class="btn btn-sm btn-icon btn-light-primary"
                                            ><i
                                              class="bi bi-folder-plus fs-3"
                                            ></i
                                          ></a>
                                          <a
                                            href="#"
                                            @click="
                                              delChild(
                                                field.name,
                                                subField - 1,
                                                item.name
                                              )
                                            "
                                            class="btn btn-sm btn-icon btn-light-danger"
                                            ><i class="bi bi-trash fs-3"></i
                                          ></a>
                                          <div
                                            @click="
                                              nestedAccordion(
                                                `sessionAccordion_${index}`,
                                                `#kt_${item.title}_${index}_collapsible_${iii}`
                                              )
                                            "
                                            :id="`kt_${item.title}_${index}_collapsible_${iii}_button`"
                                            class="btn btn-sm btn-icon btn-light collapsible cursor-pointer rotate"
                                            :class="iii != 0 ? 'collapsed' : ''"
                                            data-bs-toggle="collapse"
                                            :data-bs-target="`#kt_${item.title}_${index}_collapsible_${iii}`"
                                          >
                                            <div class="rotate-180">
                                              <span
                                                class="svg-icon svg-icon-white svg-icon-1"
                                              >
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                                    fill="currentColor"
                                                  ></path>
                                                </svg>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        :id="`kt_${item.title}_${index}_collapsible_${iii}`"
                                        :data-bs-parent="`#sessionAccordion_${index}`"
                                        class="collapse"
                                        :class="iii == 0 ? 'show' : ''"
                                      >
                                        <div class="card-body">
                                          <div
                                            v-for="(it, ii) in item.fields"
                                            :key="ii"
                                            class="fv-row mb-10"
                                          >
                                            <div
                                              v-if="
                                                it.editable || modalInfo.status
                                              "
                                            >
                                              <div
                                                class="d-flex justify-content-between"
                                              >
                                                <label
                                                  v-if="it.title.length > 0"
                                                  class="fs-5 fw-bolder form-label mb-2"
                                                >
                                                  <span
                                                    :class="
                                                      item.required
                                                        ? 'required'
                                                        : ''
                                                    "
                                                    >{{ it.title }}
                                                    {{ index + 1 }}.{{
                                                      iii + 1
                                                    }}</span
                                                  >
                                                </label>
                                                <div
                                                  v-if="
                                                    info[field.name][
                                                      subField - 1
                                                    ][item.name][subItem - 1][
                                                      it.name
                                                    ] &&
                                                    info[field.name][
                                                      subField - 1
                                                    ][item.name][subItem - 1][
                                                      it.name
                                                    ].length &&
                                                    it.maxLength &&
                                                    it.maxLength !== -1
                                                  "
                                                  class="d-flex justify-content-end text-secondary"
                                                >
                                                  {{
                                                    info[field.name][
                                                      subField - 1
                                                    ][item.name][subItem - 1][
                                                      it.name
                                                    ].length
                                                  }}
                                                  /
                                                  {{ it.maxLength }}
                                                </div>
                                                <div
                                                  v-else-if="
                                                    it.maxTags &&
                                                    it.maxTags !== -1
                                                  "
                                                  class="d-flex justify-content-end text-secondary"
                                                >
                                                  0 / {{ it.maxLength }}
                                                </div>
                                              </div>
                                              <div
                                                :tabindex="index"
                                                v-if="it.type == 'text'"
                                              >
                                                {{ it.errorCode }}
                                                <input
                                                  :ref="`${it.name}_Input`"
                                                  :minlength="it.minLength"
                                                  :maxlength="it.maxLength"
                                                  class="form-control form-control-solid p-4"
                                                  :class="
                                                    it.required && it.errorCode
                                                      ? 'wrong-inputs'
                                                      : ''
                                                  "
                                                  :disabled="
                                                    it.disabled ? true : false
                                                  "
                                                  aria-describedby="validationErrorFeedback"
                                                  :type="it.type"
                                                  :placeholder="`${it.placeholder}`"
                                                  v-model="
                                                    info[field.name][
                                                      subField - 1
                                                    ][item.name][subItem - 1][
                                                      it.name
                                                    ]
                                                  "
                                                />
                                                <div
                                                  v-if="
                                                    it.required &&
                                                    it.errorCode > 0
                                                  "
                                                  id="validationErrorFeedback"
                                                  class="invalid-feedback d-block"
                                                >
                                                  {{
                                                    it.validation[
                                                      it.errorCode - 1
                                                    ].error
                                                  }}
                                                </div>
                                              </div>
                                              <div
                                                :tabindex="index"
                                                v-else-if="
                                                  it.type == 'selectFile'
                                                "
                                              >
                                                <div
                                                  @click="
                                                    setModalConfigs('choose', {
                                                      root: field.name,
                                                      index: subField - 1,
                                                      child: item.name,
                                                      childIndex: subItem - 1,
                                                      grandchild: it.name,
                                                      depth: 5,
                                                    })
                                                  "
                                                  class="fv-row"
                                                >
                                                  <!--begin::Dropzone-->
                                                  <div
                                                    class="dropzone bg-light-primary rounded border-primary border border-dashed"
                                                    id="kt_dropzonejs_example_1"
                                                  >
                                                    <!--begin::Message-->
                                                    <div
                                                      v-if="
                                                        info[field.name][
                                                          subField - 1
                                                        ][item.name][
                                                          subItem - 1
                                                        ][it.name] &&
                                                        info[field.name][
                                                          subField - 1
                                                        ][item.name][
                                                          subItem - 1
                                                        ][it.name].length > 0
                                                      "
                                                      class="d-flex flex-center flex-wrap scroll-y mh-200px"
                                                    >
                                                      <div
                                                        v-for="photo in info[
                                                          field.name
                                                        ][subField - 1][
                                                          item.name
                                                        ][subItem - 1][it.name]"
                                                        :key="photo"
                                                        class="d-flex flex-column flex-center bg-dark bg-opacity-5 rounded border border-dashed border-primary p-5 m-2 col-6 col-md-3"
                                                      >
                                                        <div
                                                          class="symbol symbol-100px"
                                                        >
                                                          <img
                                                            :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      v-else
                                                      class="dz-message needsclick"
                                                    >
                                                      <!--begin::Icon-->
                                                      <i
                                                        class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                                                      ></i>
                                                      <!--end::Icon-->

                                                      <!--begin::Info-->
                                                      <div class="ms-4">
                                                        <h3
                                                          class="fs-5 fw-bolder text-gray-900 mb-1"
                                                        >
                                                          Drop files here or
                                                          click to upload.
                                                        </h3>
                                                        <span
                                                          class="fs-7 fw-bold text-gray-400"
                                                          >Upload up to 10
                                                          files</span
                                                        >
                                                      </div>
                                                      <!--end::Info-->
                                                    </div>
                                                  </div>
                                                  <!--end::Dropzone-->
                                                </div>
                                                <div
                                                  v-if="
                                                    field.required &&
                                                    field.errorCode > 0
                                                  "
                                                  id="validationErrorFeedback"
                                                  class="invalid-feedback d-block"
                                                >
                                                  {{
                                                    field.validation[
                                                      field.errorCode - 1
                                                    ].error
                                                  }}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="separator separator-content separator-dashed mb-20 my-15 border-gray-300 text-primary text-nowrap"
                                          >
                                            End of Session
                                            {{ index + 1 }}.{{ iii + 1 }}
                                          </div>
                                          <div
                                            v-if="
                                              field.required &&
                                              field.errorCode > 0
                                            "
                                            id="validationErrorFeedback"
                                            class="invalid-feedback d-block"
                                          >
                                            {{
                                              field.validation[
                                                field.errorCode - 1
                                              ].error
                                            }}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div :tabindex="index" v-else-if="item.type == 'selectFile'">
                                    <div
                                      @click="
                                        setModalConfigs('choose', {
                                          root: field.name,
                                          index: index,
                                          child: item.name,
                                          depth: 3,
                                        })
                                      "
                                      class="fv-row"
                                    >
                                      <!--begin::Dropzone-->
                                      <div
                                        class="dropzone bg-light-primary rounded border-primary border border-dashed"
                                        id="kt_dropzonejs_example_1"
                                      >
                                        <!--begin::Message-->
                                        {{ info[field.name][index][item.name] }}
                                        <div
                                          v-if="
                                            (info[field.name] && info[field.name][index][item.name].length > 0)
                                          "
                                          class="d-flex flex-center flex-wrap scroll-y mh-200px"
                                        >
                                          <div
                                            v-for="photo in [info[field.name][index][item.name]]"
                                            :key="photo"
                                            class="d-flex flex-column flex-center bg-dark bg-opacity-5 rounded border border-dashed border-primary p-5 m-2 col-6 col-md-3 col-lg-2"
                                          >
                                            <div class="symbol symbol-100px">
                                              <img
                                                :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${photo}`"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div v-else class="dz-message needsclick">
                                          <!--begin::Icon-->
                                          <i
                                            class="bi bi-file-earmark-arrow-up text-primary fs-3x"
                                          ></i>
                                          <!--end::Icon-->

                                          <!--begin::Info-->
                                          <div class="ms-4">
                                            <h3 class="fs-5 fw-bolder text-gray-900 mb-1">
                                              Click here to upload an image
                                            </h3>
                                            <span class="fs-7 fw-bold text-gray-400"
                                              >Upload up to 10 files</span
                                            >
                                          </div>
                                          <!--end::Info-->
                                        </div>
                                      </div>
                                      <!--end::Dropzone-->
                                    </div>
                                    <!-- <a
                                      class="btn btn-primary btn-shadow ms-2"
                                      :class="
                                        field.required && field.errorCode ? 'wrong-inputs' : ''
                                      "
                                      @click="setModalConfigs('choose')"
                                    >
                                      انتخاب عکس
                                    </a> -->
                                    <div
                                      v-if="field.required && field.errorCode > 0"
                                      id="validationErrorFeedback"
                                      class="invalid-feedback d-block"
                                    >
                                      {{ field.validation[field.errorCode - 1].error }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="field.required && field.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ field.validation[field.errorCode - 1].error }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'array'">
                    <!--begin::Table wrapper-->
                    <div v-if="info.display" class="table-responsive">
                      <!--begin::Table-->
                      <table
                        v-if="field.value[0].type == 'checkbox'"
                        class="table align-middle table-row-dashed fs-6 gy-5"
                      >
                        <!--begin::Table body-->
                        <tbody class="text-gray-600 fw-bold">
                          <!--begin::Table row-->
                          <tr v-for="value in field.value" :key="value.title">
                            <!--begin::Label-->
                            <td class="text-gray-800">
                              {{ value.title }}
                            </td>
                            <!--end::Label-->
                            <!--begin::Options-->
                            <td>
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  @click="chooseRow($event, field.name, value)"
                                  class="form-check form-check-sm form-check-custom form-check-solid me-5"
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                  />
                                  <span class="form-check-label">All</span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <td
                              v-for="(item, index) in value.items"
                              :key="index"
                            >
                              <!--begin::Wrapper-->
                              <div class="d-flex">
                                <!--begin::Checkbox-->
                                <label
                                  class="form-check form-check-sm form-check-custom form-check-solid me-5"
                                >
                                  <input
                                    class="form-check-input"
                                    :type="value.type"
                                    name="user_management_read"
                                    v-model="
                                      info[field.name][value.name][index]
                                    "
                                  />
                                  <span class="form-check-label"
                                    >{{ item.title }}
                                  </span>
                                </label>
                                <!--end::Checkbox-->
                              </div>
                              <!--end::Wrapper-->
                            </td>
                            <!--end::Options-->
                          </tr>
                          <!--end::Table row-->
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                      <div v-if="field.value[0].type == 'text'">
                        <div
                          v-for="(value, index) in field.value"
                          :key="index"
                          class="fv-row"
                        >
                          <label class="fs-5 fw-bolder form-label mb-2">
                            <span :class="value.required ? 'required' : ''">{{
                              value.title
                            }}</span>
                          </label>

                          <input
                            class="form-control form-control-solid"
                            :class="
                              value.required && value.errorCode
                                ? 'wrong-inputs'
                                : ''
                            "
                            :disabled="value.disabled ? true : false"
                            aria-describedby="validationErrorFeedback"
                            :type="value.type"
                            :placeholder="`${value.placeholder}`"
                            v-model="info[field.name][value.name]"
                          />
                          <div
                            v-if="value.required && value.errorCode > 0"
                            id="validationErrorFeedback"
                            class="invalid-feedback d-block"
                          >
                            {{ value.validation[value.errorCode - 1].error }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end::Table wrapper-->
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'email'">
                    <!--begin::Title-->
                    <div
                      class="d-flex flex-wrap gap-2 justify-content-between mb-8"
                    >
                      <div class="d-flex align-items-center flex-wrap gap-2">
                        <!--begin::Heading-->
                        <h2 class="fw-bold me-3 my-1">
                          {{ info["contactSubject"] }}
                        </h2>
                        <!--begin::Heading-->
                      </div>
                    </div>
                    <!--end::Title-->
                    <!--begin::Message accordion-->
                    <div data-kt-inbox-message="message_wrapper">
                      <!--begin::Message header-->
                      <div
                        class="d-flex flex-wrap gap-2 flex-stack cursor-pointer"
                      >
                        <!--begin::Author-->
                        <div class="d-flex align-items-center">
                          <div class="pe-5">
                            <!--begin::Author details-->
                            <div
                              class="d-flex align-items-center flex-wrap gap-1"
                            >
                              <a
                                href="#"
                                class="fw-bolder text-dark text-hover-primary"
                                >{{ info["contactFullName"] }}</a
                              >
                            </div>
                            <!--end::Author details-->
                          </div>
                        </div>
                        <!--end::Author-->
                        <!--begin::Actions-->
                        <div class="d-flex align-items-center flex-wrap gap-2">
                          <!--begin::Date-->
                          <span class="fw-bold text-muted text-end me-3">{{
                            info["contactDate"]
                          }}</span>
                          <!--end::Date-->
                        </div>
                        <!--end::Actions-->
                      </div>
                      <!--end::Message header-->
                      <!--begin::Message content-->
                      <div class="collapse fade show">
                        <div class="py-5 fst-italic fs-5">
                          {{ info["contactDescription"] }}
                        </div>
                      </div>
                      <!--end::Message content-->
                    </div>
                    <!--end::Message accordion-->
                    <div class="separator my-6"></div>
                    <!--begin::Form-->
                    <div class="d-flex justify-content-between">
                      <label
                        v-if="field.title.length > 0"
                        class="fs-5 fw-bolder form-label mb-3"
                      >
                        <span v-if="info['contactMark']" class="text-danger"
                          >{{ field.title }} has been sent</span
                        >
                        <span
                          v-else
                          :class="field.required ? 'required' : ''"
                          >{{ field.title }}</span
                        >
                      </label>
                    </div>
                    <form class="rounded" style="border: 1px solid #ccc">
                      <!--begin::Body-->
                      <div class="d-block">
                        <!--begin::To-->
                        <div style="border-bottom: 1px solid #ccc">
                          <a
                            :href="`mailto: ${info['contactEmail']}`"
                            dir="ltr"
                            class="form-control border-0 px-8 min-h-45px"
                            >{{ info["contactEmail"] }}</a
                          >
                        </div>
                        <!--end::To-->
                        <!--begin::Subject-->
                        <div style="border-bottom: 1px solid #ccc">
                          <div class="form-control border-0 px-8 min-h-45px">
                            {{ info["contactSubject"] }}
                          </div>
                        </div>
                        <!--end::Subject-->
                        <div
                          class="form-control border-0 px-8 min-h-45px"
                          v-if="info['contactMark']"
                        >
                          {{ info["contactResponse"] }}
                        </div>
                        <textarea
                          v-else
                          class="form-control border-0 px-8 min-h-45px"
                          :class="
                            field.required && field.errorCode
                              ? 'wrong-inputs'
                              : ''
                          "
                          rows="5"
                          v-model="info['contactResponse']"
                          :placeholder="field.placeholder"
                        >
                        </textarea>
                      </div>
                      <!--end::Body-->
                    </form>
                    <div
                      v-if="field.required && field.errorCode > 0"
                      id="validationErrorFeedback"
                      class="invalid-feedback d-block"
                    >
                      {{ field.validation[field.errorCode - 1].error }}
                    </div>
                    <!--end::Form-->
                  </div>
                  <div
                    :tabindex="index"
                    v-else-if="
                      field.type == 'ticket' && info.ticketSource != null
                    "
                  >
                    <!--begin::Title-->
                    <div class="d-flex flex-column flex-wrap gap-2">
                      <!--begin::Heading-->
                      <h2 class="fw-bold me-3 my-1">
                        {{ info.ticketSource.ticketSubject }}
                      </h2>
                      <!--begin::User-->
                      <div class="d-flex align-items-center">
                        <!--begin::Details-->
                        <div class="">
                          <a
                            href="#"
                            class="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                            >{{ info.ticketSource.ticketUser }}</a
                          >
                          <span class="text-muted fs-7 mb-1">{{
                            info.ticketSource.ticketDate
                          }}</span>
                        </div>
                        <!--end::Details-->
                      </div>
                      <!--end::User-->
                      <!--begin::Heading-->
                      <div class="separator mb-5"></div>
                      <!--begin::Message-->
                      <div class="d-flex justify-content-start mb-10">
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column align-items-start">
                          <!--begin::Text-->
                          <div
                            class="p-7 bg-light-primary text-dark fw-semibold mw-lg-400px text-start"
                            style="border-radius: 0 1rem 1rem 1rem"
                            data-kt-element="message-text"
                          >
                            <div
                              v-html="info.ticketSource.ticketMessage"
                              class="fs-4"
                            ></div>
                            <!--begin::Attachments-->
                            <div
                              v-if="
                                info.ticketSource.ticketAttachments.length > 0
                              "
                              class="mt-20"
                            >
                              <span class="fs-7 text-primary">attachments</span>
                              <div
                                class="separator mb-5 border-primary opacity-50"
                              ></div>
                              <div class="d-flex flex-wrap gap-3">
                                <a
                                  v-for="(attach, index) in info.ticketSource
                                    .ticketAttachments"
                                  :key="index"
                                  class="d-block overlay cursor-pointer"
                                  @click="softBox(attach)"
                                  ><div
                                    class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-60px w-60px bg-secondary"
                                    :style="`
                            background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${attach});
                          `"
                                  ></div>
                                  <!--begin::Action-->
                                  <div
                                    class="overlay-layer card-rounded bg-dark bg-opacity-25"
                                  >
                                    <i
                                      class="bi bi-eye-fill fs-2x text-white"
                                    ></i>
                                  </div>
                                  <!--end::Action--></a
                                >
                              </div>
                            </div>
                            <!--end::Attachments-->
                          </div>
                          <!--end::Text-->
                        </div>
                        <!--end::Wrapper-->
                      </div>
                      <!--end::Message-->
                    </div>
                    <!--end::Title-->
                    <div
                      v-for="(history, index) in info.ticketHistory"
                      :key="index"
                    >
                      <!--begin::Message(in)-->
                      <div
                        v-if="
                          history['ticketUser'] ==
                          info['ticketSource']['ticketUser']
                        "
                        class="d-flex justify-content-start mb-10"
                      >
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column align-items-start">
                          <!--begin::User-->
                          <div class="d-flex align-items-center mb-2">
                            <!--begin::Details-->
                            <div class="ms-3">
                              <a
                                href="#"
                                class="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                                >{{ history.ticketUser }}</a
                              >
                              <span class="text-muted fs-7 mb-1">{{
                                history.ticketDate
                              }}</span>
                            </div>
                            <!--end::Details-->
                          </div>
                          <!--end::User-->
                          <!--begin::Text-->
                          <div
                            class="p-7 bg-light-primary text-dark fw-semibold mw-lg-400px text-start"
                            style="border-radius: 0 1rem 1rem 1rem"
                            data-kt-element="message-text"
                          >
                            <div
                              v-html="history.ticketMessage"
                              class="fs-4"
                            ></div>
                            <!--begin::Attachments-->
                            <div
                              v-if="history.ticketAttachments.length > 0"
                              class="mt-20"
                            >
                              <span class="fs-7 text-primary">attachments</span>
                              <div
                                class="separator mb-5 border-primary opacity-50"
                              ></div>
                              <div class="d-flex flex-wrap gap-3">
                                <a
                                  v-for="(
                                    attach, index
                                  ) in history.ticketAttachments"
                                  :key="index"
                                  class="d-block overlay cursor-pointer"
                                  @click="softBox(attach)"
                                  ><div
                                    class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-60px w-60px bg-secondary"
                                    :style="`
                            background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${attach});
                          `"
                                  ></div>
                                  <!--begin::Action-->
                                  <div
                                    class="overlay-layer card-rounded bg-dark bg-opacity-25"
                                  >
                                    <i
                                      class="bi bi-eye-fill fs-2x text-white"
                                    ></i>
                                  </div>
                                  <!--end::Action--></a
                                >
                              </div>
                            </div>
                            <!--end::Attachments-->
                          </div>
                          <!--end::Text-->
                        </div>
                        <!--end::Wrapper-->
                      </div>
                      <!--end::Message(in)-->
                      <!--begin::Message(out)-->
                      <div v-else class="d-flex justify-content-end mb-10">
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column align-items-end">
                          <!--begin::User-->
                          <div class="d-flex align-items-center mb-2">
                            <!--begin::Details-->
                            <div class="me-3">
                              <span class="text-muted fs-7 mb-1">{{
                                history.ticketDate
                              }}</span>
                              <a
                                href="#"
                                class="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                                >{{ history.ticketUser }}</a
                              >
                            </div>
                            <!--end::Details-->
                          </div>
                          <!--end::User-->
                          <!--begin::Text-->
                          <div
                            class="p-7 bg-primary text-white fw-semibold mw-lg-400px text-end"
                            style="border-radius: 1rem 0 1rem 1rem"
                            data-kt-element="message-text"
                          >
                            <div
                              v-html="history.ticketMessage"
                              class="fs-4"
                            ></div>
                            <!--begin::Attachments-->
                            <div
                              v-if="history.ticketAttachments.length > 0"
                              class="mt-20"
                            >
                              <span class="fs-7 text-primary">attachments</span>
                              <div
                                class="separator mb-5 border-primary opacity-50"
                              ></div>
                              <div class="d-flex flex-wrap gap-3">
                                <a
                                  v-for="(
                                    attach, index
                                  ) in history.ticketAttachments"
                                  :key="index"
                                  class="d-block overlay cursor-pointer"
                                  @click="softBox(attach)"
                                  ><div
                                    class="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded h-60px w-60px bg-secondary"
                                    :style="`
                            background-image: url(${backendHost}/uploads/${$store.state.uploadsPath}/${attach});
                          `"
                                  ></div>
                                  <!--begin::Action-->
                                  <div
                                    class="overlay-layer card-rounded bg-dark bg-opacity-25"
                                  >
                                    <i
                                      class="bi bi-eye-fill fs-2x text-white"
                                    ></i>
                                  </div>
                                  <!--end::Action--></a
                                >
                              </div>
                            </div>
                            <!--end::Attachments-->
                          </div>
                          <!--end::Text-->
                        </div>
                        <!--end::Wrapper-->
                      </div>
                      <!--end::Message(out)-->
                    </div>
                  </div>
                  <div :tabindex="index" v-else-if="field.type == 'radioGroup'">
                    <div
                      class="rounded border border-dashed p-10"
                      :class="
                        field.required && field.errorCode > 0
                          ? 'border-danger'
                          : ''
                      "
                    >
                      <div
                        v-for="(option, index) in field.options"
                        :key="index"
                        class="mb-5"
                      >
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            :checked="option.value == info[field.name]"
                            type="radio"
                            @click="setRadioGroup(field.name, option.value)"
                            :id="`radiogroup${index}`"
                            :name="field.name"
                          />
                          <label
                            class="form-check-label"
                            :for="`radiogroup${index}`"
                            >{{ option.title }}</label
                          >
                        </div>
                      </div>
                      <div
                        v-if="field.required && field.errorCode > 0"
                        id="validationErrorFeedback"
                        class="invalid-feedback d-block"
                      >
                        {{ field.validation[field.errorCode - 1].error }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </form>
        </div>
        <div
          class="modal-footer d-flex justify-content-center border-gray-300 shadow-lg"
        >
          <div class="text-center">
            <button class="btn btn-light me-3" @click="closeModal">
              {{ contents.buttons ? contents.buttons[0].title : "Cancel" }}
            </button>
            <button
              v-if="!(contents.title == 'Uploads' && modalInfo.status)"
              class="btn btn-primary me-3"
              @click="closeModal('validate', info)"
            >
              <span class="indicator-label">{{
                contents.buttons ? contents.buttons[1].title : "Save"
              }}</span>
            </button>
            <button
              v-if="
                contents.title == 'Ticket' &&
                this.info['ticketHistory'] &&
                this.info['ticketHistory'].length > 0
              "
              class="btn btn-danger me-3"
              @click="closeModal('validate', info)"
            >
              <span class="indicator-label">{{
                contents.buttons ? contents.buttons[2].title : "Save"
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--end::Modal dialog-->
    <upload-assets
      v-if="modalDisplay"
      :contents="null"
      :modalInfo="uploadInfo"
      :mode="mode"
      :modalType="modalType"
      @close-modal="setModalConfigs"
    ></upload-assets>
    <!--end::Modals-->
    <div>
      <FsLightbox :toggler="toggler" :sources="[`${softBoxSource}`]" />
    </div>
  </div>
</template>

<script>
import UploadAssets from "../modals/UploadAssets.vue";
import Multiselect from "@vueform/multiselect";
import Editor from "@tinymce/tinymce-vue";
import Dropzone from "dropzone";
import DatePicker from "vue3-persian-datetime-picker";
import serverConfigs from "../../configs/server.json";
import dataService from "../../services/data";
import FsLightbox from "fslightbox-vue/v3";

export default {
  components: {
    UploadAssets,
    multiselect: Multiselect,
    editor: Editor,
    DatePicker,
    FsLightbox,
  },
  setup() {},
  data() {
    return {
      softBoxSource: "",
      focusStatus: false,
      pac: true,
      infoImage: {},
      displayStatus: false,
      value: null,
      uploadInfo: {
        images: [],
        current: {},
      },
      toggler: false,
      modalDisplay: false,
      dropzoneCounter: 0,
      info: {},
      verifyCode: "",
      verifyCodeErrorClass: "",
      verifyTime: true,
      countDown: 5,
    };
  },
  props: {
    contents: {
      required: true,
    },
    modalInfo: {
      required: false,
    },
    mode: {},
    modalType: {
      required: true,
    },
    enableTimer: {
      required: false,
    },
    firstErrorName: {
      required: false,
    },
    icons: {
      required: false,
    },
  },
  computed: {
    templateIndex() {
      let index = "";
      for (let b in this.contents.fields[0].options) {
        if (
          this.contents.fields[0].options[b].value == this.info["blogTemplate"]
        ) {
          index = b;
        }
      }
      return index;
    },
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    modalOverlay() {
      let elem = document.createElement("div");
      elem.style.cssText = "z-index:109";
      elem.classList.add("drawer-overlay");
      return elem;
    },
    modalNode() {
      return document.getElementsByClassName("modal fade")[0];
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  methods: {
    fetchIcons(query) {
      if (query) {
        return this.icons.filter(icon => icon.title.includes(query))
      }

      return this.icons
    },
    chooseRow(event, root, value) {
      for (let item in this.info[root][value.name]) {
        this.info[root][value.name][item] = event.target.checked;
      }
    },
    addChild(root, index, key, child, rootIndex) {
      if (rootIndex) {
        let temp = {};
        for (let field in rootIndex.fields) {
          if (rootIndex.fields[field].type == "card") {
            let t = {};
            Object.assign(t, rootIndex.fields[field].value);
            temp[rootIndex.fields[field].name] = [t];
          } else {
            temp[rootIndex.fields[field].name] = rootIndex.fields[field].value;
          }
        }

        if (temp) {
          this.info[root].push(temp);
        }
      } else {
        let temp = {};
        if (key) {
          Object.assign(temp, child);
          this.info[root][index][key].push(temp);
        } else {
          let tempRoot = {};
          for (let i in this.contents.fields[6].fields) {
            switch (this.contents.fields[6].fields[i].type) {
              case "text":
                tempRoot[this.contents.fields[6].fields[i].name] = "";
                break;
              case "textarea":
                tempRoot[this.contents.fields[6].fields[i].name] = "";
                break;
              case "card":
                Object.assign(temp, child);

                tempRoot[this.contents.fields[6].fields[i].name] = [temp];
                break;
              default:
                break;
            }
          }
          this.info[root].push(tempRoot);
        }
      }
    },
    delChild(root, index, key) {
      if (this.$route.path.includes("contents")) {
        key
          ? this.info[root][index][key].pop()
          : this.info[root].splice(index - 3, 1);
      } else {
        key ? this.info[root][index][key].pop() : this.info[root].pop();
      }
    },
    setOfset(field) {
      let modalEl = document.getElementById("kt_modal_add_role_scroll");
      modalEl.scroll(0, 20 * field.options.length);
      modalEl.style.overflow = "hidden";
    },
    setModalConfigs(opt, index) {
      switch (opt) {
        case "choose":
          this.infoImage = index;

          this.getUploads();
          break;
        case "select":
          this.info.templateJson.fields[0].value = index;
          this.modalDisplay = !this.modalDisplay;
          break;
        case "update":
          this.roleInfo = { ...this.roles[index], status: true };
          this.roleInfo.rolePermissions = JSON.parse(
            this.roleInfo.rolePermissions
          );
          this.modalDisplay = !this.modalDisplay;
          break;
        case "create":
          this.setupRoleInfo();
          this.modalDisplay = !this.modalDisplay;
          break;
        case "validate":
          if (this.inputValidation(index)) {
            index.id ? this.updateRoles(index) : this.createRoles(index);
          }
          break;
        case "photo":
          if (
            this.infoImage &&
            this.infoImage.depth &&
            this.infoImage.depth == 5
          ) {
            this.info[this.infoImage["root"]][this.infoImage["index"]][
              this.infoImage["child"]
            ][this.infoImage["childIndex"]][this.infoImage["grandchild"]] =
              index.image;
          } else if (
            this.infoImage &&
            this.infoImage.depth &&
            this.infoImage.depth == 3
          ) {
            this.info[this.infoImage["root"]][this.infoImage["index"]][this.infoImage["child"]] = index.image[0];
            console.log(this.info[this.infoImage["root"]][this.infoImage["index"]][this.infoImage["child"]])
          } else if (this.infoImage) {
            this.info[this.infoImage["root"]] = index.image;
            this.info["photos"] = index.image;
          } else {
            this.info["photos"] = index.image;
          }
          this.modalDisplay = !this.modalDisplay;
          if (this.contents.title == "Uploads" && this.modalInfo.status) {
            this.closeModal();
          }
          break;
        default:
          this.modalDisplay = false;
          if (this.contents.title == "Uploads" && this.modalInfo.status) {
            this.closeModal();
          }
          break;
      }
    },
    closeModal(opt, index) {
      this.focusStatus = false;
      this.$emit(
        "close-modal",
        opt && opt.length > 0 ? opt : null,
        index ? index : null,
        document.getElementById("dropzoneFiles") &&
          document.getElementById("dropzoneFiles").value &&
          document.getElementById("dropzoneFiles").value.length > 0
          ? document.getElementById("dropzoneFiles").value
          : null
      );
      if (
        document.getElementById("dropzoneFiles") &&
        document.getElementById("dropzoneFiles").value &&
        document.getElementById("dropzoneFiles").value.length > 0
      ) {
        document.getElementById("dropzoneFiles").value = "";
        const id = "#kt_dropzonejs_example_2";
        const dropzone = document.querySelector(id);
        dropzone.querySelector(".dropzone-remove-all").click();
      }
    },
    dropzoneInput() {
      // set the dropzone container id
      const id = "#kt_dropzonejs_example_2";
      const dropzone = document.querySelector(id);

      if (dropzone) {
        // set the preview element template
        var previewNode = dropzone.querySelector(".dropzone-item");
        previewNode.id = "";
        var previewTemplate = previewNode.parentNode.innerHTML;
        previewNode.parentNode.removeChild(previewNode);

        var myDropzone = new Dropzone(id, {
          // Make the whole body a dropzone
          url: `${serverConfigs["serverDomain"]["host"]}/api/v1/uploads/upload?locale=en`, // Set the url for your upload script location
          parallelUploads: 20,
          previewTemplate: previewTemplate,
          maxFilesize: 1000, // Max filesize in MB
          autoQueue: false, // Make sure the files aren't queued until manually added
          previewsContainer: id + " .dropzone-items", // Define the container to display the previews
          clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
        });

        myDropzone.on("addedfile", function (file) {
          // document
          //   .getElementById("photoPreview")
          //   .setAttribute("src", file.dataURL);
          // Hookup the start button
          file.previewElement.querySelector(
            id + " .dropzone-start"
          ).style.display = "none";

          const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
          dropzoneItems.forEach((dropzoneItem) => {
            dropzoneItem.style.display = "";
          });
          dropzone.querySelector(".dropzone-upload").style.display =
            "inline-block";
          dropzone.querySelector(".dropzone-remove-all").style.display =
            "inline-block";
          dropzone.querySelector(".dropzone-photo-delete").style.display =
            "none";
        });

        myDropzone.on("thumbnail", function (file, dataURL) {
          document.getElementById("photoPreview").setAttribute("src", dataURL);
        });
        // Update the total progress bar
        myDropzone.on("totaluploadprogress", function (progress) {
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.width = progress + "%";
          });
        });

        myDropzone.on("sending", function (file) {
          // Show the total progress bar when upload starts
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.opacity = "1";
          });
          // And disable the start button
          file.previewElement
            .querySelector(id + " .dropzone-start")
            .setAttribute("disabled", "disabled");
        });

        myDropzone.on("success", function (file) {
          let temp =
            document.getElementById("dropzoneFiles").value.length > 0
              ? JSON.parse(document.getElementById("dropzoneFiles").value)
              : [];
          temp.push(JSON.parse(file.xhr.response)[0].filename);
          document.getElementById("dropzoneFiles").value = JSON.stringify(temp);
        });

        // Hide the total progress bar when nothing's uploading anymore
        myDropzone.on("complete", function () {
          const progressBars = dropzone.querySelectorAll(".dz-complete");

          setTimeout(function () {
            progressBars.forEach((progressBar) => {
              progressBar.querySelector(".progress-bar").style.display = "none";
              progressBar.querySelector(".progress").style.display = "none";
              progressBar.querySelector(".dropzone-start").style.display =
                "none";
            });
          }, 300);
        });

        // Setup the buttons for all transfers
        dropzone
          .querySelector(".dropzone-upload")
          .addEventListener("click", function () {
            myDropzone.enqueueFiles(
              myDropzone.getFilesWithStatus(Dropzone.ADDED)
            );
          });

        // Setup the button for remove all files
        dropzone
          .querySelector(".dropzone-remove-all")
          .addEventListener("click", function () {
            dropzone.querySelector(".dropzone-upload").style.display = "none";
            dropzone.querySelector(".dropzone-remove-all").style.display =
              "none";
            dropzone.querySelector(".dropzone-photo-delete").style.display =
              "inline-block";
            myDropzone.removeAllFiles(true);
          });

        // On all files completed upload
        myDropzone.on("queuecomplete", function () {
          const uploadIcons = dropzone.querySelectorAll(".dropzone-upload");
          uploadIcons.forEach((uploadIcon) => {
            uploadIcon.style.display = "none";
          });
          dropzone.querySelector(".dropzone-photo-delete").style.display =
            "inline-block";
        });

        // On all files removed
        myDropzone.on("removedfile", function () {
          if (myDropzone.files.length < 1) {
            dropzone.querySelector(".dropzone-upload").style.display = "none";
            dropzone.querySelector(".dropzone-remove-all").style.display =
              "none";
            dropzone.querySelector(".dropzone-photo-delete").style.display =
              "inline-block";
          }
        });
      }
    },
    getUploads() {
      let data = {
        endPoint: `uploads`,
        locale: "fa",
        token: this.$store.state.token,
        data: {},
        // query: {
        //   type: "blog",
        // },
      };
      // data.query = JSON.stringify(data.query)
      //   .replace("{", "")
      //   .replace(",", "&")
      //   .replace("}", "")
      //   .replace(/":"/g, "=")
      //   .replace(/"/g, "");
      dataService
        .get(data)
        .then((res) => {
          this.uploadInfo = {
            images: res.data.data.reverse(),
            current: {
              upload: [],
            },
          };
          let tempImage = [];
          let tempIndex = [];
          let tempUpload = [];
          let tempPhotos =
            this.infoImage && this.infoImage["root"]
              ? this.info[this.infoImage["root"]]
              : [];
          for (let j in this.info.photos ||
            this.info.sliderSlides ||
            tempPhotos) {
            for (let i in this.uploadInfo.images) {
              if (
                (this.info.photos &&
                  this.uploadInfo.images[i].uploadName ==
                    this.info.photos[j]) ||
                (this.info.sliderSlides &&
                  this.uploadInfo.images[i].uploadName ==
                    this.info.sliderSlides[j]) ||
                (tempPhotos &&
                  this.uploadInfo.images[i].uploadName == tempPhotos[j])
              ) {
                tempIndex.push(this.info.id);
                tempImage.push(this.uploadInfo.images[i].uploadName);
                tempUpload.push(Number(i));
                break;
              }
            }
          }
          this.uploadInfo.current = {
            index: tempIndex,
            image: tempImage,
            upload: tempUpload,
          };
          this.modalDisplay = true;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        });
    },
    detectTabKey() {
      //
      // if (e.keyCode == 9) {
      //   let activeElem = document.activeElement;
      // }
    },
    setSelectedUpload(index) {
      this.info["blogTemplate"] = index;
    },
    setRadioGroup(name, value) {
      this.info[name] = value;
    },
    deleteFilePhoto(index) {
      this.info[index] = "";
      let avatar = `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/1616284800000_Avatar.png`;
      document.getElementById("photoPreview").setAttribute("src", avatar);
    },
    countDownTimer() {
      if (this.countDown == 0) {
        this.verifyTimerSwitch();
      } else {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        }
      }
    },
    softBox(src) {
      this.softBoxSource = `${this.backendHost}/uploads/${this.$store.state.uploadsPath}/${src}`;
      this.toggler = !this.toggler;
    },
    verifyTimerSwitch() {
      this.verifyTime = !this.verifyTime;
    },
    goto() {
      if (this.firstErrorName && this.firstErrorName.length > 0) {
        this.$refs[this.firstErrorName][0].scrollIntoView({
          behavior: "smooth",
        });
        if (
          !this.focusStatus &&
          this.$refs[`${this.firstErrorName}_Input`] &&
          this.$refs[`${this.firstErrorName}_Input`].length > 0
        ) {
          this.$refs[`${this.firstErrorName}_Input`][0].focus();
          this.focusStatus = true;
        }
      }
    },
    nestedAccordion(parentId, accordionId) {
      console.log(parentId);
      console.log(accordionId);
      let children = document.querySelectorAll(
        `[data-bs-parent='#${parentId}']`
      );
      children.forEach((element) => {
        element.classList.remove("show");
        if (`#${element.id}` != accordionId) {
          console.log(accordionId);
          console.log(element.id);

          console.log(document.getElementById(`${element.id}_button`));
          document
            .getElementById(`${element.id}_button`)
            .classList.add("collapsed");
        }
      });
      // for(let child in childs){
      //   console.log(childs[child].id)
      //   console.log(document.querySelector(`[data-bs-target='#${childs[child].id}']`))
      //     childs[child].classList.remove('show')
      //   // if(childs[child].id != accordionId){
      //   //   document.querySelectorAll(`[data-bs-target='#${childs[child].id}']`)[0].classList.add('collapsed')
      //   //   // document.querySelectorAll(`[data-bs-target='#${childs[child].id}']`)[1].classList.add('collapsed')
      //   // }
      // }
    },
  },
  mounted() {
    Object.assign(this.info, this.modalInfo);

    document.body.appendChild(this.modalOverlay);
    this.displayStatus = true;

    if (this.info.status) {
      for (let field in this.contents.fields) {
        if (
          this.contents.fields[field].type == "array" ||
          this.contents.fields[field].type == "component" ||
          this.contents.fields[field].type == "paragraph" ||
          this.contents.fields[field].type == "multiselect" ||
          this.contents.fields[field].type == "card"
        ) {
          continue;
        } else {
          this.info[this.contents.fields[field].name] =
            this.contents.fields[field].default;
        }
      }
    }
    if (
      this.contents.title.includes("Products") &&
      (this.$store.state.shopType == "simple" ||
        this.$store.state.shopType == "custom")
    ) {
      this.pac = false;
    }
    this.info["display"] = true;
    this.modalNode.classList.add("show");
    this.modalNode.classList.add("d-block");
    this.bodyNode.classList.add("modal-open");
    this.bodyNode.style.overflow = "hidden";
  },
  unmounted() {
    document.body.removeChild(this.modalOverlay);
  },
  updated() {
    //
    if (this.enableTimer && !this.verifyTime) {
      this.verifyTime = true;
      this.countDown = 5;
      this.countDownTimer();
    }
    if (this.dropzoneCounter == 0) {
      this.dropzoneInput();
      this.dropzoneCounter += 1;
    }

    this.goto();
  },
  created() {
    this.countDownTimer();
  },
};
</script>

<style scoped>
.mh-50vh {
  max-height: 50vh !important;
}

.wrong-inputs {
  border: 2px solid !important;
  border-color: red !important;
  /* background-color: #fff; */
}

/*========== SCROLL BAR ==========*/
.modal-body::-webkit-scrollbar {
  width: 0.7rem;
  background-color: var(--scroll-bar-color);
  border-radius: 0.5rem;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(var(--bs-primary-rgb), 0.6);
  border-radius: 0.5rem;
}

.modal ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.9);
}

.card:has([data-bs-parent="#chapterAccordion"].collapse.show) {
  border-color: var(--bs-primary) !important;
  box-shadow: 0 0 20px 0 rgba(var(--bs-primary-rgb), 0.5);
}

.card:has([data-bs-parent="#chapterAccordion"].collapse.show)
  > div:first-child {
  background-color: rgba(var(--bs-primary-rgb), 0.5) !important;
}
</style>
