<template>
  <div
    class="col-12 d-flex align-items-center justify-content-center justify-content-lg-end"
  >
    <img
      class="m-3 px-2"
      :src="`/assets/images/svg/${photoName}_illustration.svg`"
      alt=""
    />
  </div>
</template>

<script>
export default {
  setup() {},
  props: {
    photoName: {
      type: String,
      required: true,
    },
  },
};
</script>
