<template>
  <div v-if="contents.status && profile.status">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl my-5">
      <!--begin::Layout-->
      <div class="d-flex flex-column flex-xl-row">
        <!--begin::Sidebar-->
        <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
          <!--begin::Card-->
          <div class="card mb-5 mb-xl-8">
            <div class="card-header border-0">
              <div class="card-title">
                <h3 class="fw-bolder m-0">
                  {{ contents.data.page.card.title }}
                </h3>
              </div>
            </div>
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Summary-->
              <!--begin::User Info-->
              <div class="d-flex flex-center flex-column pb-5">
                <!--begin::Avatar-->
                <div class="symbol symbol-100px symbol-circle mb-7">
                  <img
                    v-if="profile.profilePhoto"
                    :src="`${backendHost}/uploads/${$store.state.uploadsPath}/${profile.profilePhoto}`"
                    alt="image"
                  />
                  <img
                    v-else
                    :src="`${backendHost}/uploads/${$store.state.uploadsPath}/1616284800000_Avatar.png`"
                    alt="image"
                  />
                </div>
                <!--end::Avatar-->
                <!--begin::Name-->
                <div class="fs-3 text-gray-800 fw-bolder mb-3">
                  {{ profile.profileFullName }}
                </div>
                <!--end::Name-->
                <!--begin::Position-->
                <div class="mb-9 text-gray-500">
                  {{ profile.userUserName }}
                </div>
                <!--end::Position-->
                <!--begin::Tickets Info-->
                <!--end::Tickets Info-->
              </div>
              <!--end::User Info-->
              <!--end::Summary-->
              <!--begin::Details toggle-->
              <div class="d-flex flex-stack fs-4 py-3">
                <div
                  class="fw-bolder rotate collapsible collapsed"
                  data-bs-toggle="collapse"
                  href="#kt_user_view_details"
                  role="button"
                  aria-expanded="false"
                  aria-controls="kt_user_view_details"
                >
                  {{ contents.data.page.card.details.title }}
                  <span class="ms-2 rotate-180">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr072.svg-->
                    <span class="svg-icon svg-icon-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                  </span>
                </div>
                <span
                  data-bs-toggle="tooltip"
                  data-bs-trigger="hover"
                  :title="`${contents.data.page.card.details.buttonHover}`"
                >
                  <a
                    class="btn btn-sm btn-light-primary"
                    @click="setModalConfigs('update')"
                    >{{ contents.data.page.card.details.buttonLabel }}</a
                  >
                </span>
              </div>
              <!--end::Details toggle-->
              <div class="separator"></div>
              <!--begin::Details content-->
              <div id="kt_user_view_details" class="collapse">
                <div class="pb-5 fs-6">
                  <!--begin::Details item-->
                  <div
                    v-for="(field, index) in contents.data.page.card.details
                      .fields"
                    :key="index"
                  >
                    <div class="fw-bolder mt-5">{{ field.title }}</div>
                    <div class="text-gray-600">
                      {{ profile[`${field.name}`] }}
                    </div>
                  </div>
                  <!-- <div
                    v-for="(extra, index) in JSON.parse(profile.profileExtra)"
                    :key="index"
                  >
                    <div class="fw-bolder mt-5">
                      {{ extra.title }}
                    </div>
                    <div class="text-gray-600">{{ extra.value }}</div>
                  </div> -->
                  <!--end::Details item-->
                </div>
              </div>
              <!--end::Details content-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>
        <!--end::Sidebar-->
        <!--begin::Content-->
        <div class="flex-lg-row-fluid ms-lg-15">
          <!--begin:::Tabs-->
          <ul
            class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8"
          >
            <!--begin:::Tab item-->
            <li
              v-for="(tab, index) in contents.data.page.tabs"
              :key="index"
              class="nav-item"
            >
              <a
                class="nav-link text-active-primary pb-4"
                :class="index == currentTab ? 'active' : ''"
                @click="setCurrentTab(index)"
                >{{ tab.item }}</a
              >
            </li>
            <!--end:::Tab item-->
            <!--begin:::Tab item ACTION MENU-->
            <!--end:::Tab item ACTION MENU-->
          </ul>
          <!--end:::Tabs-->
          <!--begin:::Tab content-->
          <div class="tab-content" id="myTabContent">
            <!--begin:::Tab pane 1-->
            <!--end:::Tab pane 1-->
            <!--begin:::Tab pane 2-->
            <div
              v-for="(tab, index) in contents.data.page.tabs"
              :key="index"
              class="tab-pane fade show active"
              id="kt_user_view_overview_tab"
              role="tabpanel"
            >
              <div v-if="index == currentTab">
                <!--begin::Card-->
                <div class="card pt-4 mb-6 mb-xl-9">
                  <!--begin::Card header-->
                  <div class="card-header border-0">
                    <!--begin::Card title-->
                    <div class="card-title">
                      <h2>{{ JSON.parse(tab.contentJson).title }}</h2>
                    </div>
                    <!--end::Card title-->
                  </div>
                  <!--end::Card header-->
                  <!--begin::Card body-->
                  <div class="card-body pt-0 pb-5">
                    <!--begin::Table wrapper-->
                    <div class="table-responsive">
                      <!--begin::Table-->
                      <table
                        class="table align-middle table-row-dashed gy-5"
                        id="kt_table_users_login_session"
                      >
                        <!--begin::Table body-->
                        <tbody class="fs-6 fw-bold text-gray-600">
                          <tr
                            v-for="field in JSON.parse(tab.contentJson).fields"
                            :key="field"
                          >
                            <td>{{ field.label }}</td>
                            <td>{{ profile[field.name] }}</td>
                            <td class="text-end">
                              <button
                                type="button"
                                class="btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_update_email"
                              >
                                <!--begin::Svg Icon | path: icons/duotune/art/art005.svg-->
                                <!-- <span class="svg-icon svg-icon-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      opacity="0.3"
                                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                      fill="black"
                                    />
                                    <path
                                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                      fill="black"
                                    />
                                  </svg>
                                </span> -->
                                <!--end::Svg Icon-->
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <!--end::Table body-->
                      </table>
                      <!--end::Table-->
                    </div>
                    <!--end::Table wrapper-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
            </div>
            <!--end:::Tab pane 2-->
            <!--begin:::Tab pane 3-->
            <!--end:::Tab pane 3-->
          </div>
          <!--end:::Tab content-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Layout-->
    </div>
    <!--end::Container-->
    <create-update
      v-if="modalDisplay"
      :contents="contents.data.modal"
      :modalInfo="profileInfo"
      :firstErrorName="firstErrorName"
      :modalType="'uploadselect'"
      @close-modal="setModalConfigs"
    ></create-update>
  </div>
</template>

<script>
import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";
import jmoment from "jalali-moment";

// Modals
import CreateUpdate from "../../modals/CreateUpdate.vue";

export default {
  computed: {
    backendHost() {
      return serverConfigs["serverDomain"]["host"];
    },
    userId() {
      return this.$route.query.id ? this.$route.query.id : null;
    },
    routeLocale() {
      return this.$route.params.locale;
    },
    bodyNode() {
      return document.getElementById("kt_body");
    },
  },
  components: {
    CreateUpdate,
  },
  setup() {},
  data() {
    return {
      testClass: "",
      testValue: "",
      currentTab: 0,
      profileInfo: Object,
      modalDisplay: false,
      profile: {},
      connectedAccounts: [],
      firstErrorName: "",
      contents: {
        name: "",
        data: {},
        status: false,
      },
      loaderStatus: [false, false],
    };
  },
  methods: {
    setBoldStyle() {
      this.testClass += ` fw-bold`
    },
    setCurrentTab(index) {
      this.currentTab = index;
    },
    loaderDone() {
      if (this.loaderStatus[0] && this.loaderStatus[1]) {
        this.$store.commit("setLoader", false);
      }
    },
    getContents() {
      let data = {
        endPoint: `contents/r/profile`,
        locale: `${this.routeLocale}`,
        data: {},
        query: {
          category: "panel",
        },
      };
      data.query = JSON.stringify(data.query)
        .replace("{", "")
        .replace(",", "&")
        .replace("}", "")
        .replace(/":"/g, "=")
        .replace(/"/g, "");

      dataService
        .get(data)
        .then((res) => {
          
          this.contents = {
            name: res.data.data[0].contentName,
            data: res.data.data[0].contentJson,
            status: true,
          };
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[0] = true;
          this.loaderDone();
        });
    },
    setModalConfigs(opt, index) {
      this.$store.commit("setLoader", true);
      
      switch (opt) {
        case "update":
          this.profileInfo = { ...this.profile, status: false };
          this.profileInfo.profilePhoto = [this.profileInfo.profilePhoto];
          this.profileInfo["photos"] = [this.profile.profilePhoto];
          this.profileInfo.profileBirthday = new Date(
            this.profileInfo.profileBirthday
          ).toLocaleDateString("en");
          this.profileInfo.profileBirthday = jmoment(
            this.profileInfo.profileBirthday,
            "M/D/YYYY"
          )
            .locale(this.routeLocale)
            .format("YYYY/MM/DD");
          this.modalDisplay = !this.modalDisplay;
          this.$store.commit("setLoader", false);
          
          break;
        case "validate":
          index["profilePhoto"] =
            index.photos && index.photos ? index.photos[0] : "";
          
          if (this.inputValidation(index)) {
            this.updateProfile(index);
          } else {
            this.$store.commit("setLoader", false);
            
          }
          break;
        default:
          this.resetProfileInfo();
          this.$store.commit("setLoader", false);
          
          break;
      }
    },
    resetProfileInfo() {
      this.profileInfo = {};
      this.modalDisplay = false;
      if (!this.modalDisplay) {
        this.bodyNode.classList.remove("modal-open");
        this.bodyNode.style.overflow = "visible";
      }
      for (let index in this.contents.data.modal.fields) {
        if (this.contents.data.modal.fields[index].required) {
          this.contents.data.modal.fields[index].errorCode = 0;
        }
      }
    },
    inputValidation(obj) {
      let errorCount = 0;
      let tempError = "";
      for (
        let index = 0;
        index < this.contents.data.modal.fields.length;
        index++
      ) {
        if (this.contents.data.modal.fields[index].required) {
          for (let expression in this.contents.data.modal.fields[index]
            .validation) {
            switch (
              this.contents.data.modal.fields[index].validation[expression]
                .expression
            ) {
              case "isEmpty":
                this.contents.data.modal.fields[index].errorCode =
                  !obj[this.contents.data.modal.fields[index].name] ||
                  obj[this.contents.data.modal.fields[index].name].length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              case "isSpace":
                this.contents.data.modal.fields[index].errorCode =
                  this.contents.data.modal.fields[index].validation.length >
                    1 && this.contents.data.modal.fields[index].errorCode > 0
                    ? this.contents.data.modal.fields[index].errorCode
                    : String(
                        obj[this.contents.data.modal.fields[index].name]
                      ).trim().length == 0
                    ? this.contents.data.modal.fields[index].validation[
                        expression
                      ].id
                    : 0;
                if (this.contents.data.modal.fields[index].errorCode > 0) {
                  errorCount += 1;
                  tempError = tempError.length == 0 ? this.contents.data.modal.fields[index].name : tempError;
                }
                break;
              default:
                this.contents.data.modal.fields[index].errorCode = 0;
                break;
            }
          }
        }
      }
      this.firstErrorName = tempError.length > 0 ? tempError : this.firstErrorName;
      return errorCount == 0 ? true : false;
    },
    readProfiles() {
      let data = {
        endPoint: `profiles/r/${this.userId ? this.userId : 'me'}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {},
      };
      dataService
        .get(data)
        .then((res) => {
          
          this.profile = res.data.data[0];

          this.profile["userDateLabel"] = new Date(
            this.profile.userDate
          ).toLocaleDateString(this.routeLocale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          });

          this.profile["profileBirthdayLabel"] = new Date(
            this.profile.profileBirthday
          ).toLocaleDateString(this.routeLocale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          this.profile["status"] = true;
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.loaderStatus[1] = true;
          this.loaderDone();
        });
    },
    updateProfile(obj) {
      let tempExtra = {};
      if (this.routeLocale == "fa") {
        obj.profileBirthday = new Date(jmoment(obj.profileBirthday, 'jYYYY/jMM/jDD').locale("en").format("YYYY/MM/DD")).getTime();
      } else {
        obj.profileBirthday = new Date(obj.profileBirthday).getTime();
      }
      for (let p in obj.profileExtra) {
        tempExtra[`${p}`] = obj.profileExtra[p];
      }
      let data = {
        endPoint: `profiles/u/${obj.id}`,
        locale: `${this.routeLocale}`,
        token: this.$store.state.token,
        data: {
          fullName: obj["profileFullName"],
          // firstName: obj["profileFirstName"],
          // lastName: obj["profileLastName"],
          photo: obj["profilePhoto"],
          birthday: obj["profileBirthday"],
          bio: obj["profileBio"],
          extra: tempExtra,
          status: obj["profileStatus"],
        },
      };
      dataService
        .post(data)
        .then((res) => {
          this.$notify({
            type: res.data.type,
            title: res.data.type,
            text: res.data.message,
          });
          let tempProfile = this.$store.state.profile;
          tempProfile.profileFullName = obj["profileFullName"];
          // tempProfile.profileFirstName = obj["profileFirstName"];
          // tempProfile.profileLastName = obj["profileLastName"];
          tempProfile.profilePhoto = obj["profilePhoto"];
          this.$store.commit("setProfile", tempProfile);
          this.resetProfileInfo();
          this.readProfiles();
        })
        .catch((error) => {
          this.$notify({
            type: error.response.data.type,
            title: error.response.data.type,
            text: error.response.data.message,
          });
        })
        .finally(() => {
          this.$store.commit("setLoader", false);
          
        });
    },
  },
  mounted() {
    this.$store.commit("setLoader", true);
    this.readProfiles();
    this.getContents();
  },
};
</script>
