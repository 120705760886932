<template>
  <!--begin::Container-->
  <div id="kt_content_container" class="container-xxl my-5">
    <div class="card">
      <div class="card-body">
        <div>
          <!-- begin::Nav -->
          <div class="d-flex justify-content-center m-20">
            <div class="d-flex flex-wrap flex-center" style="row-gap: 1rem">
              <!-- begin::Item0 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 0
                        ? 'bg-primary text-white'
                        : currentState > 0
                        ? 'bg-light-success text-success'
                        : ''
                    "
                  >
                    0
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 0
                      ? 'text-primary'
                      : currentState > 0
                      ? 'text-success'
                      : ''
                  "
                >
                  Start
                </div>
              </div>
              <!-- end::Item0 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item1 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 1
                        ? 'bg-primary text-white'
                        : currentState > 1
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    1
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 1
                      ? 'text-primary'
                      : currentState > 1
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Info
                </div>
              </div>
              <!-- end::Item1 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item2 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 2
                        ? 'bg-primary text-white'
                        : currentState > 2
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    2
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 2
                      ? 'text-primary'
                      : currentState > 2
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Domain
                </div>
              </div>
              <!-- end::Item2 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item3 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 3
                        ? 'bg-primary text-white'
                        : currentState > 3
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    3
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 3
                      ? 'text-primary'
                      : currentState > 3
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Logo
                </div>
              </div>
              <!-- end::Item3 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item4 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 4
                        ? 'bg-primary text-white'
                        : currentState > 4
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    4
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 4
                      ? 'text-primary'
                      : currentState > 4
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Language
                </div>
              </div>
              <!-- end::Item4 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item5 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 5
                        ? 'bg-primary text-white'
                        : currentState > 5
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    5
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 5
                      ? 'text-primary'
                      : currentState > 5
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Web template
                </div>
              </div>
              <!-- end::Item5 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item6 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 6
                        ? 'bg-primary text-white'
                        : currentState > 6
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    6
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 6
                      ? 'text-primary'
                      : currentState > 6
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Color theme
                </div>
              </div>
              <!-- end::Item6 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item7 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 7
                        ? 'bg-primary text-white'
                        : currentState > 7
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    7
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 7
                      ? 'text-primary'
                      : currentState > 7
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Email template
                </div>
              </div>
              <!-- end::Item7 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item8 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 8
                        ? 'bg-primary text-white'
                        : currentState > 8
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    8
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 8
                      ? 'text-primary'
                      : currentState > 8
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Terms
                </div>
              </div>
              <!-- end::Item8 -->
              <div class="separator separator-dashed w-30px mx-2 mb-10"></div>
              <!-- begin::Item9 -->
              <div class="d-flex flex-column flex-center min-w-60px">
                <div class="symbol symbol-40px symbol-circle mb-3">
                  <div
                    class="symbol-label fs-2 fw-semibold"
                    :class="
                      currentState == 9
                        ? 'bg-primary text-white'
                        : currentState > 9
                        ? 'bg-light-success text-success'
                        : 'text-muted'
                    "
                  >
                    9
                  </div>
                </div>
                <div
                  class="fw-bold"
                  :class="
                    currentState == 9
                      ? 'text-primary'
                      : currentState > 9
                      ? 'text-success'
                      : 'text-muted'
                  "
                >
                  Invoice
                </div>
              </div>
              <!-- end::Item9 -->
            </div>
          </div>
          <!-- end::Nav -->
          <!-- begin::Content -->
          <div>
            <form class="form w-lg-750px mx-auto">
              <!-- begin::Step 0 -->
              <div v-if="currentState == 0">
                <h3 class="text-center">Enter email or phone number</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  (you can enter both)
                </h6>
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label required">Email</label>
                  <i
                    class="bi bi-question-circle-fill ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="d-flex">
                    <div class="position-relative flex-grow-1 me-3">
                      <input
                        type="email"
                        class="form-control form-control-solid"
                        placeholder="Enter email address"
                        v-model="data.info.customerEmail"
                      />
                      <!--begin::CVV icon-->
                      <div
                        class="
                          position-absolute
                          translate-middle-y
                          top-50
                          end-0
                          me-3
                        "
                      >
                        <i
                          class="bi bi-envelope fs-1 me-3"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                      </div>
                      <!--end::CVV icon-->
                    </div>
                    <!--begin:Radio-->
                    <span
                      v-if="
                        data.info.customerEmail.length &&
                        data.info.customerPhoneNumber.length
                      "
                      class="form-check form-check-custom"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Auth"
                        value="email"
                        @click="selcetedItems($event)"
                      />
                    </span>
                    <!--end:Radio-->
                  </div>
                  <!--end::Input-->
                </div>
                <div class="fv-row mb-20">
                  <!--begin::Label-->
                  <label class="form-label required">Phone number</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="d-flex">
                    <div class="position-relative flex-grow-1 me-3">
                      <input
                        type="tel"
                        class="form-control form-control-solid"
                        placeholder="Enter Phone number"
                        v-model="data.info.customerPhoneNumber"
                      />
                      <!--begin::CVV icon-->
                      <div
                        class="
                          position-absolute
                          translate-middle-y
                          top-50
                          end-0
                          me-3
                        "
                      >
                        <i
                          class="bi bi-phone fs-1 me-3"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                      </div>
                      <!--end::CVV icon-->
                    </div>
                    <!--begin:Radio-->
                    <span
                      v-if="
                        data.info.customerEmail.length &&
                        data.info.customerPhoneNumber.length
                      "
                      class="form-check form-check-custom"
                    >
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Auth"
                        value="phoneNumber"
                        @click="selcetedItems($event)"
                      />
                    </span>
                    <!--end:Radio-->
                  </div>
                  <!--end::Input-->
                </div>
              </div>
              <!-- end::Step 0 -->
              <!-- begin::Step 1 -->
              <div v-if="currentState == 1">
                <h3 class="text-center mb-10">
                  Enter the requested information to complete the registration
                  of your site.
                </h3>
                <!-- begin::row 1 -->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label required">Full name</label>
                  <i
                    class="bi bi-question-circle-fill ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="Enter your full name"
                      v-model="data.info.customerFullname"
                    />
                    <!--begin::CVV icon-->
                    <div
                      class="
                        position-absolute
                        translate-middle-y
                        top-50
                        end-0
                        me-3
                      "
                    >
                      <i
                        class="bi bi-pen fs-1 me-3"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Tooltip on top"
                      ></i>
                    </div>
                    <!--end::CVV icon-->
                  </div>
                  <!--end::Input-->
                </div>
                <!-- end::row 1 -->
                <!-- begin::row 2 -->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label">Username</label>
                  <i
                    class="bi bi-question-circle-fill ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder="Enter your username"
                      v-model="data.info.customerUsername"
                    />
                    <!--begin::CVV icon-->
                    <div
                      class="
                        position-absolute
                        translate-middle-y
                        top-50
                        end-0
                        me-3
                      "
                    >
                      <i
                        class="bi bi-person fs-1 me-3"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Tooltip on top"
                      ></i>
                    </div>
                    <!--end::CVV icon-->
                  </div>
                  <!--end::Input-->
                </div>
                <!-- end::row 2 -->
                <!-- begin::row 3 -->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label required">Password</label>
                  <i
                    class="bi bi-question-circle-fill ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      class="form-control form-control-solid"
                      placeholder="Enter your password"
                      :type="visibility[0] ? 'text' : 'password'"
                      v-model="data.info.customerPassword"
                    />
                    <!--begin::CVV icon-->
                    <div
                      class="
                        position-absolute
                        translate-middle-y
                        top-50
                        end-0
                        me-3
                      "
                    >
                      <i
                        @click="passwordVisibilitySwitch(0)"
                        class="fs-1 me-3"
                        :class="visibility[0] ? 'bi bi-eye-slash' : 'bi bi-eye'"
                        style="cursor: pointer"
                      ></i>
                      <i
                        class="bi bi-key fs-1 me-3"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Tooltip on top"
                      ></i>
                    </div>
                    <!--end::CVV icon-->
                  </div>
                  <!--end::Input-->
                </div>
                <!-- end::row 3 -->
                <!-- begin::row 4 -->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label required">Confirm password</label>
                  <i
                    class="bi bi-question-circle-fill ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <div class="position-relative flex-grow-1 me-3">
                    <input
                      class="form-control form-control-solid"
                      placeholder="Enter your password again"
                      :type="visibility[1] ? 'text' : 'password'"
                      v-model="data.info.customerConfirmPassword"
                    />
                    <!--begin::CVV icon-->
                    <div
                      class="
                        position-absolute
                        translate-middle-y
                        top-50
                        end-0
                        me-3
                      "
                    >
                      <i
                        @click="passwordVisibilitySwitch(1)"
                        class="fs-1 me-3"
                        :class="visibility[1] ? 'bi bi-eye-slash' : 'bi bi-eye'"
                        style="cursor: pointer"
                      ></i>
                      <i
                        class="bi bi-key fs-1 me-3"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Tooltip on top"
                      ></i>
                    </div>
                    <!--end::CVV icon-->
                  </div>
                  <!--end::Input-->
                </div>
                <!-- end::row 4 -->
              </div>
              <!-- end::Step 1 -->
              <!-- begin::Step 2 -->
              <div v-if="currentState == 2">
                <h3 class="text-center mb-10">
                  Specify the address to display your website on the Internet
                </h3>
                <div class="d-flex flex-column flex-md-row p-10">
                  <ul
                    class="
                      nav nav-tabs nav-pills
                      flex-row
                      border-0
                      flex-md-column
                      me-5
                      mb-3 mb-md-0
                      fs-6
                      min-w-lg-200px
                    "
                    role="tablist"
                  >
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_1"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 1</span>
                          <span class="fs-7">have domain</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_2"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 2</span>
                          <span class="fs-7 text-start">need domain</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_3"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 3</span>
                          <span class="fs-7">order domain</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content w-100" id="myTabContent">
                    <div class="tab-pane fade active show" id="kt_tab_pane_1">
                      <h4>I have my own domain.</h4>
                      <p class="text-muted mb-7">
                        If you have domain enter your domian here, but if you
                        don't have it select other methods by the buttons we
                        place aside for you.
                      </p>
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Domain</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Please enter your domain name and your suffixes correctly"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative flex-grow-1 me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="example.com"
                            v-model="data.domain.customerDomain"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-globe2 fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                    </div>
                    <div class="tab-pane fade" id="kt_tab_pane_2">
                      <h4>I don't have any domain.</h4>
                      <p class="text-muted mb-7">
                        If you don't have domain you can use our domain service,
                        by entering your desired domain. In this way, your
                        domain will be registered as our subdomain
                      </p>
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Domain name</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Please enter your domain name "
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="input-group mb-5">
                          <span class="input-group-text"
                            >https://www.l8d.com/</span
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="example"
                            v-model="data.domain.subDomainName"
                          />
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                    </div>
                    <div class="tab-pane fade" id="kt_tab_pane_3">
                      <h4>I want to order a custom domain.</h4>
                      <p class="text-muted mb-7">
                        If you don't have a domain and want to order it, enter
                        your domain and phone number so that our experts will
                        contact you as soon as possible to choose a domain and
                        take the necessary steps.
                      </p>
                      <!-- begin::Row 1 -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Domain</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Please enter your domain name and your suffixes correctly"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative flex-grow-1 me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="example.com"
                            v-model="data.domain.customDomain"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-globe2 fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Row 1 -->
                      <!-- begin::Row 2 -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Phone number</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Please enter your domain name and your suffixes correctly"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative flex-grow-1 me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="09xx xxx xxxx"
                            v-model="data.domain.phoneNumber"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-phone fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Row 2 -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Step 2 -->
              <!-- begin::Step 3 -->
              <div v-if="currentState == 3">
                <h3 class="text-center mb-10">
                  Upload or select your logo by choosing one of the options
                  below.
                </h3>
                <!--begin:Option 1-->
                <label class="d-flex flex-stack mb-5">
                  <!--begin:Input-->
                  <span
                    class="form-check form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="logo"
                      value="upload"
                      @click="selcetedItems($event)"
                    />
                  </span>
                  <!--end:Input-->
                  <!--begin:Label-->
                  <span class="d-flex flex-grow-1 align-items-center">
                    <!--begin:Icon-->
                    <span class="symbol symbol-50px me-6">
                      <span
                        class="
                          symbol-label
                          fs-1
                          fw-semibold
                          bg-light-primary
                          text-primary
                        "
                      >
                        1
                      </span>
                    </span>
                    <!--end:Icon-->

                    <!--begin:Info-->
                    <span class="d-flex flex-column">
                      <span class="fw-bold fs-6">Upload logo</span>
                      <span class="fs-7 text-muted"
                        >upload your own logo with this upload input.</span
                      >
                    </span>
                    <!--end:Info-->
                  </span>
                  <!--end:Label-->
                </label>
                <div v-if="logoState == 'uploadLogo'" class="mb-5 ms-10">
                  <!--begin::Form-->
                  <!--begin::Notice-->
                  <div
                    class="
                      notice
                      d-flex
                      bg-light-primary
                      rounded
                      border-primary border border-dashed
                      mb-9
                      p-6
                    "
                  >
                    <!--begin::Icon-->
                    <!--begin::Svg Icon | -->
                    <i
                      class="
                        bi bi-file-earmark-arrow-up
                        text-primary
                        fs-3x
                        me-2
                      "
                    ></i>
                    <!--end::Svg Icon-->
                    <!--end::Icon-->
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-stack flex-grow-1">
                      <!--begin::Content-->
                      <form class="form" action="#" method="post">
                        <!--begin::Input group-->
                        <div class="form-group row">
                          <!--begin::Col-->
                          <div class="col-12">
                            <!--begin::Dropzone-->
                            <div
                              class="dropzone dropzone-queue mb-2"
                              id="kt_dropzonejs_example_2"
                            >
                              <!--begin::Controls-->
                              <div class="d-flex">
                                <div class="dropzone-panel mb-lg-0 mb-2">
                                  <a
                                    class="
                                      dropzone-select
                                      btn btn-sm btn-primary
                                      me-2
                                    "
                                    >الصاق فایل</a
                                  >
                                  <a
                                    class="
                                      dropzone-upload
                                      btn btn-sm btn-light-primary
                                      me-2
                                    "
                                    >بارگذاری همه</a
                                  >
                                  <a
                                    class="
                                      dropzone-remove-all
                                      btn btn-sm btn-light-primary
                                      me-2
                                    "
                                    >حذف همه</a
                                  >
                                </div>
                              </div>
                              <!--end::Controls-->

                              <!--begin::Items-->
                              <div
                                class="
                                  dropzone-items
                                  d-flex
                                  flex-wrap
                                  w-100
                                  gap-2
                                "
                              >
                                <div
                                  class="
                                    dropzone-item
                                    bg-light
                                    border border-primary border-dashed
                                    w-150px
                                  "
                                  style="display: none"
                                >
                                  <!--begin::File-->
                                  <div class="dropzone-file w-100 mt-5">
                                    <div
                                      class="image-input"
                                      data-kt-image-input="true"
                                    >
                                      <div class="image-input-wrapper">
                                        <img
                                          data-dz-thumbnail=""
                                          class="dz-image"
                                          alt=""
                                        />
                                      </div>
                                      <!--begin::Edit button-->
                                      <label
                                        class="
                                          btn
                                          btn-icon
                                          btn-circle
                                          btn-active-color-primary
                                          w-25px
                                          h-25px
                                          bg-body
                                          shadow
                                          dropzone-delete
                                        "
                                        data-dz-remove
                                        data-kt-image-input-action="change"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                      >
                                        <i class="bi bi-trash-fill fs-7"></i>
                                      </label>
                                      <!--end::Edit button-->
                                    </div>
                                    <div
                                      class="dropzone-filename d-flex"
                                      title="some_image_file_name.jpg"
                                    >
                                      <div class="w-50 turncating">
                                        <span data-dz-name
                                          >some_image_file_name.jpg</span
                                        >
                                      </div>
                                      <strong class="W-50"
                                        >(<span data-dz-size>340kb</span
                                        >)</strong
                                      >
                                    </div>
                                    <div
                                      class="dropzone-error"
                                      data-dz-errormessage
                                    ></div>
                                  </div>
                                  <!--end::File-->

                                  <!--begin::Progress-->
                                  <div class="dropzone-progress">
                                    <div class="progress">
                                      <div
                                        class="progress-bar bg-primary"
                                        role="progressbar"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        aria-valuenow="0"
                                        data-dz-uploadprogress
                                      ></div>
                                    </div>
                                  </div>
                                  <!--end::Progress-->

                                  <!--begin::Toolbar-->
                                  <div class="dropzone-toolbar">
                                    <span class="dropzone-start"
                                      ><i class="bi bi-play-fill fs-3"></i
                                    ></span>
                                    <span
                                      class="dropzone-cancel"
                                      data-dz-remove
                                      style="display: none"
                                      ><i class="bi bi-x fs-3"></i
                                    ></span>
                                  </div>
                                  <!--end::Toolbar-->
                                </div>
                              </div>
                              <!--end::Items-->
                            </div>
                            <!--end::Dropzone-->

                            <!--begin::Hint-->
                            <span class="form-text text-muted"
                              >Max file size is 1MB and max number of files is
                              5.</span
                            >
                            <!--end::Hint-->
                          </div>
                          <!--end::Col-->
                        </div>
                        <!--end::Input group-->
                      </form>
                      <!--end::Content-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Notice-->
                  <!--end::Form-->
                </div>
                <!--end::Option 1-->
                <!--begin:Option 2-->
                <label class="d-flex flex-stack mb-5">
                  <!--begin:Input-->
                  <span
                    class="form-check form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="logo"
                      value="online"
                      @click="selcetedItems($event)"
                    />
                  </span>
                  <!--end:Input-->
                  <!--begin:Label-->
                  <span class="d-flex flex-grow-1 align-items-center">
                    <!--begin:Icon-->
                    <span class="symbol symbol-50px me-6">
                      <span
                        class="
                          symbol-label
                          fs-1
                          fw-semibold
                          bg-light-primary
                          text-primary
                        "
                      >
                        2
                      </span>
                    </span>
                    <!--end:Icon-->

                    <!--begin:Info-->
                    <span class="d-flex flex-column">
                      <span class="fw-bold fs-6">Create logo online</span>
                      <span class="fs-7 text-muted"
                        >You can create your logo with this online tool.</span
                      >
                    </span>
                    <!--end:Info-->
                  </span>
                  <!--end:Label-->
                </label>
                <!--end::Option 2-->
                <!--begin:Option 3-->
                <label class="d-flex flex-stack mb-5">
                  <!--begin:Input-->
                  <span
                    class="form-check form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="logo"
                      value="order"
                      @click="selcetedItems($event)"
                    />
                  </span>
                  <!--end:Input-->
                  <!--begin:Label-->
                  <span class="d-flex flex-grow-1 align-items-center">
                    <!--begin:Icon-->
                    <span class="symbol symbol-50px me-6">
                      <span
                        class="
                          symbol-label
                          fs-1
                          fw-semibold
                          bg-light-primary
                          text-primary
                        "
                      >
                        3
                      </span>
                    </span>
                    <!--end:Icon-->

                    <!--begin:Info-->
                    <span class="d-flex flex-column">
                      <span class="fw-bold fs-6">Order logo</span>
                      <span class="fs-7 text-muted"
                        >To order a logo design for your business.</span
                      >
                    </span>
                    <!--end:Info-->
                  </span>
                  <!--end:Label-->
                </label>
                <div v-if="logoState == 'orderLogo'" class="mb-5 ms-10">
                  <!-- begin::Input -->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label required">Phone number</label>
                    <i
                      class="bi bi-question-circle-fill ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Tooltip on top"
                    ></i>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <div class="position-relative me-3">
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder="09xx xxx xxxx"
                        v-model="data.logo.phoneNumber"
                      />
                      <!--begin::CVV icon-->
                      <div
                        class="
                          position-absolute
                          translate-middle-y
                          top-50
                          end-0
                          me-3
                        "
                      >
                        <i
                          class="bi bi-phone fs-1 me-3"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                      </div>
                      <!--end::CVV icon-->
                    </div>
                    <!--end::Input-->
                  </div>
                  <!-- end::Input -->
                  <!-- begin::Input -->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label required">Description</label>
                    <i
                      class="bi bi-question-circle-fill ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Tooltip on top"
                    ></i>
                    <!--end::Label-->
                    <!--begin::Input-->
                    <div class="form-floating">
                      <textarea
                        class="form-control form-control-solid"
                        placeholder=" "
                        id="floatingTextarea2"
                        style="height: 100px"
                        v-model="data.logo.description"
                      ></textarea>
                      <label for="floatingTextarea2">Write message...</label>
                    </div>
                    <!--end::Input-->
                  </div>
                  <!-- end::Input -->
                </div>
                <!--end::Option 3-->
                <!--begin:Option 4-->
                <label class="d-flex flex-stack mb-5">
                  <!--begin:Input-->
                  <span
                    class="form-check form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="logo"
                      value="defaultLogo"
                      @click="selcetedItems($event)"
                    />
                  </span>
                  <!--end:Input-->
                  <!--begin:Label-->
                  <span class="d-flex flex-grow-1 align-items-center">
                    <!--begin:Icon-->
                    <span class="symbol symbol-50px me-6">
                      <span
                        class="
                          symbol-label
                          fs-1
                          fw-semibold
                          bg-light-primary
                          text-primary
                        "
                      >
                        4
                      </span>
                    </span>
                    <!--end:Icon-->

                    <!--begin:Info-->
                    <span class="d-flex flex-column">
                      <span class="fw-bold fs-6">Default logo</span>
                      <span class="fs-7 text-muted"
                        >By selecting this option, the default site logo will be
                        displayed.</span
                      >
                    </span>
                    <!--end:Info-->
                  </span>
                  <!--end:Label-->
                </label>
                <!--end::Option 4-->
                <!--begin:Option 5-->
                <label class="d-flex flex-stack mb-10">
                  <!--begin:Input-->
                  <span
                    class="form-check form-check-custom form-check-solid me-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="logo"
                      value="noDisplay"
                      @click="selcetedItems($event)"
                    />
                  </span>
                  <!--end:Input-->
                  <!--begin:Label-->
                  <span class="d-flex flex-grow-1 align-items-center">
                    <!--begin:Icon-->
                    <span class="symbol symbol-50px me-6">
                      <span
                        class="
                          symbol-label
                          fs-1
                          fw-semibold
                          bg-light-primary
                          text-primary
                        "
                      >
                        5
                      </span>
                    </span>
                    <!--end:Icon-->

                    <!--begin:Info-->
                    <span class="d-flex flex-column">
                      <span class="fw-bold fs-6">No display logo</span>
                      <span class="fs-7 text-muted"
                        >By selecting this option, the logo will not be
                        displayed on the site.</span
                      >
                    </span>
                    <!--end:Info-->
                  </span>
                  <!--end:Label-->
                </label>
                <!--end::Option 5-->
              </div>
              <!-- end::Step 3 -->
              <!-- begin::Step 4 -->
              <div v-if="currentState == 4">
                <h3 class="text-center">
                  Choose the main language of your site.
                </h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  (This service is multilingual)
                </h6>
                <div class="">
                  <p class="text-muted mb-7">
                    This option will give you to have any language do you want.
                  </p>
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label required">Language</label>
                    <i
                      class="bi bi-question-circle-fill ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Tooltip on top"
                    ></i>
                    <!--end::Label-->
                    <multiselect
                      class="
                        form-control form-control-solid form-control-lg
                        p-4
                      "
                      label="title"
                      :close-on-select="false"
                      :searchable="true"
                      :options="items"
                      placeholder="Select your language"
                      v-model="data.language.value"
                    />
                  </div>
                </div>
              </div>
              <!-- end::Step 4 -->
              <!-- begin::Step 5 -->
              <div v-if="currentState == 5">
                <h3 class="text-center">Choose your website template.</h3>
                <div class="d-flex flex-column flex-md-row p-10">
                  <ul
                    class="
                      nav nav-tabs nav-pills
                      flex-row
                      border-0
                      flex-md-column
                      me-5
                      mb-3 mb-md-0
                      fs-6
                      min-w-lg-200px
                    "
                    role="tablist"
                  >
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_1"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 1</span>
                          <span class="fs-7">Free template</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_2"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 2</span>
                          <span class="fs-7 text-start">Order template</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content w-100" id="myTabContent">
                    <div
                      class="tab-pane fade active show"
                      id="kt_tab_pane_template_1"
                    >
                      <h4>Choose from our templates.</h4>
                      <p class="text-muted mb-7">
                        You can choose from our available template for free.
                      </p>
                      <div
                        class="d-flex row-cols-auto gap-5 gap-lg-10 flex-wrap"
                        data-kt-buttons="true"
                      >
                        <!--begin::Radio button--><label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="webTemplate"
                                value="template1"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="https://l8d.com/uploads/images/1616284800000_b-template-1.svg"
                                class="h-75px w-100px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description--></label
                        ><label
                          class="
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="webTemplate"
                                value="template2"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="https://l8d.com/uploads/images/1616284800000_b-template-2.svg"
                                class="h-75px w-100px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description--></label
                        ><label
                          class="
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="webTemplate"
                                value="template3"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="https://l8d.com/uploads/images/1616284800000_b-template-3.svg"
                                class="h-75px w-100px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description--></label
                        ><label
                          class="
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="webTemplate"
                                value="template4"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="https://l8d.com/uploads/images/1616284800000_b-template-4.svg"
                                class="h-75px w-100px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description--></label
                        ><label
                          class="
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="webTemplate"
                                value="template5"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="https://l8d.com/uploads/images/1616284800000_b-template-5.svg"
                                class="h-75px w-100px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description--></label
                        ><!--end::Radio button-->
                      </div>
                    </div>
                    <div class="tab-pane fade" id="kt_tab_pane_template_2">
                      <h4>I want to order my custom website.</h4>
                      <p class="text-muted mb-7">
                        You can fill the below form to order website designing.
                      </p>
                      <div class="d-flex mb-10">
                        <div
                          class="
                            form-check form-check-custom form-check-solid
                            me-5
                          "
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            value="single"
                            id="singlePageTemplate"
                            name="templatePages"
                            @click="selcetedItems($event)"
                          />
                          <label
                            class="form-check-label"
                            for="singlePageTemplate"
                          >
                            Single page
                          </label>
                        </div>
                        <div
                          class="form-check form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            value="multiple"
                            id="multiPageTemplate"
                            name="templatePages"
                            @click="selcetedItems($event)"
                          />
                          <label
                            class="form-check-label"
                            for="multiPageTemplate"
                          >
                            Multiple page
                          </label>
                        </div>
                      </div>
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Phone number</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="09xx xxx xxxx"
                            v-model="data.webTemplate.phoneNumber"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-phone fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Description</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="form-floating">
                          <textarea
                            class="form-control form-control-solid"
                            placeholder=" "
                            id="floatingTextarea2"
                            style="height: 100px"
                            v-model="data.webTemplate.description"
                          ></textarea>
                          <label for="floatingTextarea2"
                            >Write message...</label
                          >
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                      <!-- begin::Pages -->
                      <div
                        v-if="multiplePage"
                        class="border border-dashed border-primary rounded p-10"
                      >
                        <h4 class="text-center mb-7">
                          Select the pages do you want
                        </h4>
                        <!--begin::Item-->
                        <div class="d-flex flex-stack">
                          <div class="d-flex flex-column">
                            <a
                              href="#"
                              class="
                                fs-5
                                text-dark text-hover-primary
                                fw-bolder
                              "
                              >About us</a
                            >
                          </div>
                          <!--begin::Switch-->
                          <label
                            class="
                              form-check
                              form-switch
                              form-switch-sm
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <!--begin::Input-->
                            <input
                              class="form-check-input"
                              name="test"
                              type="checkbox"
                              v-model="data.webTemplate.services.aboutUs"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                        <!--end::Item-->
                        <div class="separator separator-dashed my-5"></div>
                        <!--begin::Item-->
                        <div class="d-flex flex-stack">
                          <div class="d-flex flex-column">
                            <a
                              href="#"
                              class="
                                fs-5
                                text-dark text-hover-primary
                                fw-bolder
                              "
                              >Blog</a
                            >
                          </div>
                          <!--begin::Switch-->
                          <label
                            class="
                              form-check
                              form-switch
                              form-switch-sm
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <!--begin::Input-->
                            <input
                              class="form-check-input"
                              name="test"
                              type="checkbox"
                              v-model="data.webTemplate.services.blog"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                        <!--end::Item-->
                        <div class="separator separator-dashed my-5"></div>
                        <!--begin::Item-->
                        <div class="d-flex flex-stack">
                          <div class="d-flex flex-column">
                            <a
                              href="#"
                              class="
                                fs-5
                                text-dark text-hover-primary
                                fw-bolder
                              "
                              >FAQ</a
                            >
                          </div>
                          <!--begin::Switch-->
                          <label
                            class="
                              form-check
                              form-switch
                              form-switch-sm
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <!--begin::Input-->
                            <input
                              class="form-check-input"
                              name="test"
                              type="checkbox"
                              v-model="data.webTemplate.services.FAQ"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                        <!--end::Item-->
                        <div class="separator separator-dashed my-5"></div>
                        <!--begin::Item-->
                        <div class="d-flex flex-stack">
                          <div class="d-flex flex-column">
                            <a
                              href="#"
                              class="
                                fs-5
                                text-dark text-hover-primary
                                fw-bolder
                              "
                              >Contact us</a
                            >
                          </div>
                          <!--begin::Switch-->
                          <label
                            class="
                              form-check
                              form-switch
                              form-switch-sm
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <!--begin::Input-->
                            <input
                              class="form-check-input"
                              name="test"
                              type="checkbox"
                              v-model="data.webTemplate.services.contactUs"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                        <!--end::Item-->
                        <div class="separator separator-dashed my-5"></div>
                        <!--begin::Item-->
                        <div class="d-flex flex-stack">
                          <div class="d-flex flex-column">
                            <a
                              href="#"
                              class="
                                fs-5
                                text-dark text-hover-primary
                                fw-bolder
                              "
                              >Services</a
                            >
                          </div>
                          <!--begin::Switch-->
                          <label
                            class="
                              form-check
                              form-switch
                              form-switch-sm
                              form-check-custom
                              form-check-solid
                            "
                          >
                            <!--begin::Input-->
                            <input
                              class="form-check-input"
                              name="test"
                              type="checkbox"
                              v-model="data.webTemplate.services.services"
                            />
                            <!--end::Input-->
                            <!--begin::Label-->
                            <span
                              class="form-check-label fw-bold text-muted"
                            ></span>
                            <!--end::Label-->
                          </label>
                          <!--end::Switch-->
                        </div>
                        <!--end::Item-->
                      </div>
                      <!-- end::Pages -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Step 5 -->
              <!-- begin::Step 6 -->
              <div v-if="currentState == 6">
                <h3 class="text-center">Choose your website color theme.</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  This color theme is used to display the website and panel
                </h6>
                <div class="d-flex flex-column flex-md-row p-10">
                  <ul
                    class="
                      nav nav-tabs nav-pills
                      flex-row
                      border-0
                      flex-md-column
                      me-5
                      mb-3 mb-md-0
                      fs-6
                      min-w-lg-200px
                    "
                    role="tablist"
                  >
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_1"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 1</span>
                          <span class="fs-7">Color themes</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_2"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 2</span>
                          <span class="fs-7 text-start">Order theme</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content w-100" id="myTabContent">
                    <div
                      class="tab-pane fade active show"
                      id="kt_tab_pane_template_1"
                    >
                      <h4>Choose from our color themes.</h4>
                      <p class="text-muted mb-7">
                        You can choose from our available color themes.
                      </p>
                      <div
                        class="d-flex row-cols-auto gap-5 gap-lg-10 flex-wrap"
                        data-kt-buttons="true"
                      >
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="colorTheme"
                                value="purple"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/purplePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="colorTheme"
                                value="green"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/greenPreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="colorTheme"
                                value="blue"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/bluePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="colorTheme"
                                value="orange"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/orangePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                      </div>
                    </div>
                    <div class="tab-pane fade" id="kt_tab_pane_template_2">
                      <h4>I want to order my custom color theme.</h4>
                      <p class="text-muted mb-7">
                        You can fill the below form to order custom color theme.
                      </p>
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Phone number</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="09xx xxx xxxx"
                            v-model="data.colorTheme.phoneNumber"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-phone fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Primary color</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <color-picker
                          v-model:pureColor="data.colorTheme.primaryColor"
                          :isWidget="true"
                          :format="hex"
                          :useType="pure"
                          :roundHistory="true"
                          :disableAlpha="true"
                          :zIndex="0"
                        />
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Description</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="form-floating">
                          <textarea
                            class="form-control form-control-solid"
                            placeholder=" "
                            id="floatingTextarea2"
                            style="height: 100px"
                            v-model="data.colorTheme.description"
                          ></textarea>
                          <label for="floatingTextarea2"
                            >Write message...</label
                          >
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Step 6 -->
              <!-- begin::Step 7 -->
              <div v-if="currentState == 7">
                <h3 class="text-center">Choose your email template.</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  This email template is used to display the email you received
                  and send.
                </h6>
                <div class="d-flex flex-column flex-md-row p-10">
                  <ul
                    class="
                      nav nav-tabs nav-pills
                      flex-row
                      border-0
                      flex-md-column
                      me-5
                      mb-3 mb-md-0
                      fs-6
                      min-w-lg-200px
                    "
                    role="tablist"
                  >
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_1"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 1</span>
                          <span class="fs-7">Free templates</span>
                        </span>
                      </a>
                    </li>
                    <li class="nav-item w-100 me-0 mb-md-2">
                      <a
                        class="
                          nav-link
                          w-100
                          btn btn-flex btn-active-light-primary
                        "
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_template_2"
                        aria-selected="false"
                        role="tab"
                        tabindex="-1"
                      >
                        <span
                          class="
                            d-flex
                            flex-column
                            align-items-start
                            min-w-100px
                          "
                        >
                          <span class="fs-4 fw-bold">Method 2</span>
                          <span class="fs-7 text-start">Order template</span>
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div class="tab-content w-100" id="myTabContent">
                    <div
                      class="tab-pane fade active show"
                      id="kt_tab_pane_template_1"
                    >
                      <h4>Choose from our email templates.</h4>
                      <p class="text-muted mb-7">
                        You can choose from our available email templates for
                        free.
                      </p>
                      <div
                        class="d-flex row-cols-auto gap-5 gap-lg-10 flex-wrap"
                        data-kt-buttons="true"
                      >
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="emailTemplate"
                                value="emailTemplate1"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/purplePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="emailTemplate"
                                value="emailTemplate2"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/greenPreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="emailTemplate"
                                value="emailTemplate3"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/bluePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                        <!--begin::Radio button-->
                        <label
                          class="
                            active
                            btn btn-outline btn-outline-dashed
                            d-flex
                            text-start
                            p-6
                          "
                          ><!--end::Description-->
                          <div class="d-flex align-items-center me-2">
                            <!--begin::Radio-->
                            <div
                              class="
                                form-check
                                form-check-custom
                                form-check-solid
                                form-check-primary
                                me-6
                              "
                            >
                              <input
                                class="form-check-input"
                                type="radio"
                                name="emailTemplate"
                                value="emailTemplate4"
                                @click="selcetedItems($event)"
                              />
                            </div>
                            <!--end::Radio--><!--begin::Info-->
                            <div class="">
                              <img
                                src="/assets/images/svg/orangePreview.svg"
                                class="h-75px w-125px rounded"
                                alt=""
                                style="object-fit: cover"
                              />
                            </div>
                            <!--end::Info-->
                          </div>
                          <!--end::Description-->
                        </label>
                        <!--end::Radio button-->
                      </div>
                    </div>
                    <div class="tab-pane fade" id="kt_tab_pane_template_2">
                      <h4>I want to order my custom email template.</h4>
                      <p class="text-muted mb-7">
                        You can fill the below form to order custom email
                        template.
                      </p>
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Phone number</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="position-relative me-3">
                          <input
                            type="text"
                            class="form-control form-control-solid"
                            placeholder="09xx xxx xxxx"
                            v-model="data.eamilTemplate.phoneNumber"
                          />
                          <!--begin::CVV icon-->
                          <div
                            class="
                              position-absolute
                              translate-middle-y
                              top-50
                              end-0
                              me-3
                            "
                          >
                            <i
                              class="bi bi-phone fs-1 me-3"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Tooltip on top"
                            ></i>
                          </div>
                          <!--end::CVV icon-->
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                      <!-- begin::Input -->
                      <div class="fv-row mb-10">
                        <!--begin::Label-->
                        <label class="form-label required">Description</label>
                        <i
                          class="bi bi-question-circle-fill ms-2 fs-7"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Tooltip on top"
                        ></i>
                        <!--end::Label-->
                        <!--begin::Input-->
                        <div class="form-floating">
                          <textarea
                            class="form-control form-control-solid"
                            placeholder=" "
                            id="floatingTextarea2"
                            style="height: 100px"
                            v-model="data.eamilTemplate.description"
                          ></textarea>
                          <label for="floatingTextarea2"
                            >Write message...</label
                          >
                        </div>
                        <!--end::Input-->
                      </div>
                      <!-- end::Input -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- end::Step 7 -->
              <!-- begin::Step 8 -->
              <div v-if="currentState == 8">
                <h3 class="text-center">Terms & conditions.</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  In this section, the rules and regulations and brief
                  descriptions of the services are placed. Please read them
                  carefully.
                </h6>
                <h3 class="mb-5">Services</h3>
                <!-- begin::Service 1 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >24/7 Technical support</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >We support you everytime in 24/7.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 1 -->
                <!-- begin::Service 2 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free DevOps</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >This service has no peyments.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 2 -->
                <!-- begin::Service 3 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Blog Template</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >We gave you free blog template.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1">+1</span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 3 -->
                <!-- begin::Service 4 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Email Template</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >We gave you free email template.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1">+1</span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 4 -->
                <!-- begin::Service 5 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Landing</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >we gave you free landing.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1">Every 6 Month</span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 5 -->
                <!-- begin::Service 6 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free File Uploads Storage</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >We gave you file uploads service completely free.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1">15 GB</span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 6 -->
                <!-- begin::Service 7 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free core Update/Debug</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >This service has no peyments.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 7 -->
                <!-- begin::Service 8 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free CloudFlare CDN</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >You can use freely cloudflare cdn.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 8 -->
                <!-- begin::Service 9 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Support</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >You have free support at beginning.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1">3 Month</span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 9 -->
                <!-- begin::Service 10 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Basic SEO</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >We provide free seo for your site.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 10 -->
                <!-- begin::Service 11 -->
                <div
                  class="
                    d-flex
                    align-items-center
                    bg-light-primary
                    rounded
                    p-5
                    mb-5
                  "
                >
                  <!--begin::Svg Icon | path: icons/duotune/abstract/abs027.svg-->
                  <span class="svg-icon svg-icon-primary svg-icon-2hx me-5">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="currentStateColor"
                      />
                      <path
                        opacity="0.5"
                        d="M12.4343 12.4343L10.75 10.75C10.3358 10.3358 9.66421 10.3358 9.25 10.75C8.83579 11.1642 8.83579 11.8358 9.25 12.25L12.2929 15.2929C12.6834 15.6834 13.3166 15.6834 13.7071 15.2929L19.25 9.75C19.6642 9.33579 19.6642 8.66421 19.25 8.25C18.8358 7.83579 18.1642 7.83579 17.75 8.25L13.5657 12.4343C13.2533 12.7467 12.7467 12.7467 12.4343 12.4343Z"
                        fill="currentStateColor"
                      />
                      <path
                        d="M8.43431 12.4343L6.75 10.75C6.33579 10.3358 5.66421 10.3358 5.25 10.75C4.83579 11.1642 4.83579 11.8358 5.25 12.25L8.29289 15.2929C8.68342 15.6834 9.31658 15.6834 9.70711 15.2929L15.25 9.75C15.6642 9.33579 15.6642 8.66421 15.25 8.25C14.8358 7.83579 14.1642 7.83579 13.75 8.25L9.56569 12.4343C9.25327 12.7467 8.74673 12.7467 8.43431 12.4343Z"
                        fill="currentStateColor"
                      />
                    </svg>
                  </span>
                  <!--end::Svg Icon-->
                  <!--begin::Title-->
                  <div class="flex-grow-1 me-2">
                    <a
                      href="#"
                      class="fw-bold text-gray-800 text-hover-primary fs-6"
                      >Free Startup</a
                    >
                    <span class="text-muted fw-semibold d-block"
                      >This service have free startup and chargable
                      landing.</span
                    >
                  </div>
                  <!--end::Title-->
                  <!--begin::Lable-->
                  <span class="fw-bold text-primary py-1"></span>
                  <!--end::Lable-->
                </div>
                <!-- end::Service 11 -->
              </div>
              <!-- end::Step 8 -->
              <!-- begin::Step 9 -->
              <div v-if="currentState == 9">
                <h3 class="text-center">Invoice preview</h3>
                <h6 class="text-muted text-center fs-6 mb-10">
                  In this section, the preview of your order invoice can be
                  seen.
                </h6>
                <div
                  class="border border-dashed border-primary rounded p-10 mb-10"
                >
                  <div class="d-flex flex-column gap-7 gap-md-10">
                    <!--begin::Order details-->
                    <div
                      class="
                        d-flex
                        flex-column flex-sm-row
                        gap-7 gap-md-10
                        fw-bold
                      "
                    >
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Order ID</span>
                        <span class="fs-5">#14534</span>
                      </div>
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Date</span>
                        <span class="fs-5">{{ Date() }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Name</span>
                        <span class="fs-5">{{
                          data.info.customerFullname
                        }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Username</span>
                        <span class="fs-5">{{
                          data.info.customerUsername
                        }}</span>
                      </div>
                    </div>
                    <!--end::Order details-->
                    <!--begin::Info-->
                    <div
                      class="
                        d-flex
                        flex-column flex-sm-row
                        gap-7 gap-md-10
                        fw-bold
                      "
                    >
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Email Address</span>
                        <span class="fs-6">{{ data.info.customerEmail }}</span>
                      </div>
                      <div class="flex-root d-flex flex-column">
                        <span class="text-muted">Phone number</span>
                        <span class="fs-6">{{
                          data.info.customerPhoneNumber
                        }}</span>
                      </div>
                    </div>
                    <!--end::Info-->
                    <!--begin:Order summary-->
                    <div class="d-flex justify-content-between flex-column">
                      <!--begin::Table-->
                      <div class="table-responsive border-bottom mb-9">
                        <table
                          class="
                            table
                            align-middle
                            table-row-dashed
                            fs-6
                            gy-5
                            mb-0
                          "
                        >
                          <thead>
                            <tr class="border-bottom fs-6 fw-bold text-muted">
                              <th class="min-w-175px pb-2">Services</th>
                              <th class="min-w-70px text-end pb-2">#</th>
                              <th class="min-w-80px text-end pb-2">QTY</th>
                              <th class="min-w-100px text-end pb-2">Total</th>
                            </tr>
                          </thead>
                          <tbody class="fw-semibold text-gray-600">
                            <!--begin::Services-->
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Basic</div>
                                  <div class="fs-7 text-muted">
                                    Basic services init
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">5,000,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Domain</div>
                                  <div class="fs-7 text-muted">
                                    Custom domain
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">200,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Email Tepmplate</div>
                                  <div class="fs-7 text-muted">
                                    Free email template
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Color theme</div>
                                  <div class="fs-7 text-muted">
                                    Free colot theme
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Tepmplate design</div>
                                  <div class="fs-7 text-muted">
                                    Custom site design
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">5,000,000 T</td>
                              <!--end::Total-->
                            </tr>
                            <tr>
                              <!--begin::Service-->
                              <td>
                                <!--begin::Title-->
                                <div class="">
                                  <div class="fw-bold">Cloudflare</div>
                                  <div class="fs-7 text-muted">
                                    Cloudflare CDN
                                  </div>
                                </div>
                                <!--end::Title-->
                              </td>
                              <!--end::Service-->
                              <!--begin::SKU-->
                              <td class="text-end"></td>
                              <!--end::SKU-->
                              <!--begin::Quantity-->
                              <td class="text-end">1</td>
                              <!--end::Quantity-->
                              <!--begin::Total-->
                              <td class="text-end">0 T</td>
                              <!--end::Total-->
                            </tr>
                            <!--end::Services-->
                            <!--begin::Subtotal-->
                            <tr>
                              <td colspan="3" class="text-end">Subtotal</td>
                              <td class="text-end">10,200,000 T</td>
                            </tr>
                            <!--end::Subtotal-->
                            <!--begin::Grand total-->
                            <tr>
                              <td
                                colspan="3"
                                class="fs-3 text-dark fw-bold text-end"
                              >
                                Grand Total
                              </td>
                              <td class="text-dark fs-3 fw-bolder text-end">
                                10,200,000 T
                              </td>
                            </tr>
                            <!--end::Grand total-->
                          </tbody>
                        </table>
                      </div>
                      <!--end::Table-->
                    </div>
                    <!--end:Order summary-->
                  </div>
                </div>
              </div>
              <!-- end::Step 9 -->
              <!-- begin::Actions -->
              <div class="d-flex flex-stack">
                <!--begin::Wrapper-->
                <div class="me-2">
                  <button
                    v-if="currentState > 0"
                    type="button"
                    class="btn btn-light btn-active-light-primary"
                    data-kt-stepper-action="previous"
                    @click="changeState('previous')"
                  >
                    <i class="bi bi-arrow-left-short fs-2 p-0"></i>
                    Back
                  </button>
                </div>
                <!--end::Wrapper-->
                <!--begin::Wrapper-->
                <div>
                  <button
                    v-if="currentState < 9"
                    type="button"
                    class="btn btn-primary"
                    data-kt-stepper-action="next"
                    @click="changeState('next')"
                  >
                    Continue
                    <i class="bi bi-arrow-right-short fs-2 p-0"></i>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-primary"
                    data-kt-stepper-action="submit"
                    @click="submitForm()"
                  >
                    <span class="indicator-label">Submit</span>
                  </button>
                </div>
                <!--end::Wrapper-->
              </div>
              <!-- end::Actions -->
            </form>
          </div>
          <!-- end::Content -->
        </div>
      </div>
    </div>
  </div>
  <!--end::Container-->
</template>

<script>
// import dataService from "../../../services/data";
import serverConfigs from "../../../configs/server.json";
import Multiselect from "@vueform/multiselect";
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Dropzone from "dropzone";

export default {
  components: {
    multiselect: Multiselect,
    ColorPicker,
  },
  setup() {},
  data() {
    return {
      test: false,
      currentState: 0,
      multiplePage: false,
      logoState: "",
      visibility: [false, false],
      items: ["Persian", "English"],
      dropzoneCounter: 0,
      data: {
        info: {
          customerEmail: "",
          customerPhoneNumber: "",
          customerAuthMethod: "",
          customerFullname: "",
          customerUsername: "",
          customerPassword: "",
          customerConfirmPassword: "",
        },
        domain: {
          customerDomain: "",
          subDomainName: "",
          customDomain: "",
          phoneNumber: "",
        },
        logo: {
          images: "",
          phoneNumber: "",
          description: "",
          default: false,
          noDispaly: false,
        },
        language: {
          value: "",
        },
        webTemplate: {
          templateName: "",
          phoneNumber: "",
          description: "",
          services: {
            aboutUs: false,
            blog: false,
            FAQ: false,
            contactUs: false,
            services: false,
          },
        },
        colorTheme: {
          themeName: "",
          phoneNumber: "",
          primaryColor: "",
          description: "",
        },
        eamilTemplate: {
          templateName: "",
          phoneNumber: "",
          description: "",
        },
      },
    };
  },
  methods: {
    selcetedItems(opt) {
      switch (opt.target.name) {
        case "Auth":
          this.data.info.customerAuthMethod = opt.target.value;
          break;
        case "logo":
          this.data.logo.default = false;
          this.data.logo.noDispaly = false;
          this.logoState = "";
          switch (opt.target.value) {
            case "upload":
              this.logoState = "uploadLogo";
              break;
            case "order":
              this.logoState = "orderLogo";
              break;
            case "defaultLogo":
              this.data.logo.default = true;
              break;
            case "noDisplay":
              this.data.logo.noDispaly = true;
              break;
            default:
              break;
          }
          break;
        case "webTemplate":
          this.data.webTemplate.templateName = opt.target.value;
          break;
        case "templatePages":
          if (opt.target.value == "multiple") {
            this.multiplePage = true;
          } else {
            this.multiplePage = false;
          }
          break;
        case "colorTheme":
          this.data.colorTheme.themeName = opt.target.value;
          break;
        case "emailTemplate":
          this.data.eamilTemplate.templateName = opt.target.value;
          break;
      }
    },
    changeState(f) {
      switch (f) {
        case "next":
          this.currentState < 9 ? (this.currentState += 1) : null;
          break;
        case "previous":
          this.currentState > 0 ? (this.currentState -= 1) : null;
          break;
      }
    },
    passwordVisibilitySwitch(i) {
      this.visibility[i] = !this.visibility[i];
    },
    dropzoneInput() {
      // set the dropzone container id
      const id = "#kt_dropzonejs_example_2";
      const dropzone = document.querySelector(id);

      if (dropzone) {
        // set the preview element template
        var previewNode = dropzone.querySelector(".dropzone-item");
        previewNode.id = "";
        var previewTemplate = previewNode.parentNode.innerHTML;
        previewNode.parentNode.removeChild(previewNode);

        var myDropzone = new Dropzone(id, {
          // Make the whole body a dropzone
          url: `${serverConfigs["serverDomain"]["host"]}/api/v1/uploads/upload?locale=fa`, // Set the url for your upload script location
          parallelUploads: 20,
          previewTemplate: previewTemplate,
          maxFilesize: 1, // Max filesize in MB
          autoQueue: false, // Make sure the files aren't queued until manually added
          previewsContainer: id + " .dropzone-items", // Define the container to display the previews
          clickable: id + " .dropzone-select", // Define the element that should be used as click trigger to select files.
        });

        myDropzone.on("addedfile", function (file) {
          // document
          //   .getElementById("photoPreview")
          //   .setAttribute("src", file.dataURL);
          // Hookup the start button
          file.previewElement.querySelector(
            id + " .dropzone-start"
          ).style.display = "none";

          const dropzoneItems = dropzone.querySelectorAll(".dropzone-item");
          dropzoneItems.forEach((dropzoneItem) => {
            dropzoneItem.style.display = "";
            
          });
          dropzone.querySelector(".dropzone-upload").style.display =
            "inline-block";
          dropzone.querySelector(".dropzone-remove-all").style.display =
            "inline-block";
          dropzone.querySelector(".dropzone-photo-delete").style.display =
            "none";
        });

        myDropzone.on("thumbnail", function (file, dataURL) {
          document.getElementById("photoPreview").setAttribute("src", dataURL);
        });
        // Update the total progress bar
        myDropzone.on("totaluploadprogress", function (progress) {
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.width = progress + "%";
          });
        });

        myDropzone.on("sending", function (file) {
          // Show the total progress bar when upload starts
          const progressBars = dropzone.querySelectorAll(".progress-bar");
          progressBars.forEach((progressBar) => {
            progressBar.style.opacity = "1";
          });
          // And disable the start button
          file.previewElement
            .querySelector(id + " .dropzone-start")
            .setAttribute("disabled", "disabled");
        });

        myDropzone.on("success", function (file) {
          let temp =
            document.getElementById("dropzoneFiles").value.length > 0
              ? JSON.parse(document.getElementById("dropzoneFiles").value)
              : [];
          temp.push(JSON.parse(file.xhr.response)[0].filename);
          document.getElementById("dropzoneFiles").value = JSON.stringify(temp);
        });

        // Hide the total progress bar when nothing's uploading anymore
        myDropzone.on("complete", function () {
          const progressBars = dropzone.querySelectorAll(".dz-complete");

          setTimeout(function () {
            progressBars.forEach((progressBar) => {
              progressBar.querySelector(".progress-bar").style.display = "none";
              progressBar.querySelector(".progress").style.display = "none";
              progressBar.querySelector(".dropzone-start").style.display =
                "none";
            });
          }, 300);
        });

        // Setup the buttons for all transfers
        dropzone
          .querySelector(".dropzone-upload")
          .addEventListener("click", function () {
            myDropzone.enqueueFiles(
              myDropzone.getFilesWithStatus(Dropzone.ADDED)
            );
          });

        // Setup the button for remove all files
        dropzone
          .querySelector(".dropzone-remove-all")
          .addEventListener("click", function () {
            dropzone.querySelector(".dropzone-upload").style.display = "none";
            dropzone.querySelector(".dropzone-remove-all").style.display =
              "none";
            dropzone.querySelector(".dropzone-photo-delete").style.display =
              "inline-block";
            myDropzone.removeAllFiles(true);
          });

        // On all files completed upload
        myDropzone.on("queuecomplete", function () {
          const uploadIcons = dropzone.querySelectorAll(".dropzone-upload");
          uploadIcons.forEach((uploadIcon) => {
            uploadIcon.style.display = "none";
          });
          dropzone.querySelector(".dropzone-photo-delete").style.display =
            "inline-block";
        });

        // On all files removed
        myDropzone.on("removedfile", function () {
          if (myDropzone.files.length < 1) {
            dropzone.querySelector(".dropzone-upload").style.display = "none";
            dropzone.querySelector(".dropzone-remove-all").style.display =
              "none";
            dropzone.querySelector(".dropzone-photo-delete").style.display =
              "inline-block";
          }
        });
      }
    },
    submitForm() {
      
    },
  },
  mounted() {},
  updated() {
    if (this.dropzoneCounter == 0) {
      this.dropzoneInput();
      this.dropzoneCounter += 1;
    }
  },
};
</script>

<style scoped></style>
